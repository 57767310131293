import React, { useState, useContext, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";

import Breadcrumbs from "../../../atoms/Breadcrumbs";
import { LanguageContext } from "../../../containers/Language";
import Search from "../../../molecules/Cleaner/JobsAndRevenue/Search";
import { Line } from "react-chartjs-2";
import "./style.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import { isArray } from "../../../../utils/utils";
import { Grid } from "gridjs-react";
import apiService from "../../../../redux/apiService";
import { microServices } from "../../../../config/global";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const JobsAndRevenue = () => {
  const { localised } = useContext(LanguageContext);
  const loggedInUser = useSelector((state) => state.authentication.profile);
  const currency = loggedInUser?.currency;
  const [isCompare, setISCompare] = useState(false);
  const [countChart, setCountChart] = useState(null);
  const [revenueChart, setRevenueChart] = useState(null);
  const [timeChart, setTimeChart] = useState(null);
  const currencySymbol = loggedInUser?.currency?.symbol || "$";
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [netChartData, setNetChartData] = useState({
    labels: [],
    datasets: [],
  });


  const [filters, setFilters] = useState({
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
    groupBy: "week",
  });

  async function getJobAndRevenueData(filters) {
    let endpoint = `${microServices.base}/v1/reports/jobs-and-revenue`;
    try {
      const jobsAndRevenueData = await apiService.post(endpoint, filters);
      const { tableData } = prepareTableData( jobsAndRevenueData, localised);
      setTableData(tableData);
      const netChart = prepareNetChartData(jobsAndRevenueData);
      setNetChartData(netChart);
    }
    catch (error) {
      toast.error(error.message);
    }
  }



  useEffect(() => {
    getJobAndRevenueData(filters);
  }, []);

  const prepareTableData = ( jobsAndRevenueData, localised) => {
    const headerData = jobsAndRevenueData.data.Header.data;
    const type = jobsAndRevenueData.data.Header.type;
    const weekKeys = Object.keys(headerData);
  
    const rowWeek = [type=="week" ? localised.week: localised.quarter];
    const rowDates = [localised.dates];
    const rowNumberOfServices = [localised.numberOfServices];
    const rowNumberOfReportedIssues = [localised.numberOfReportedIssues];
    const rowNewServicesScheduled = [localised.newServicesScheduled];
    const rowNumberOfWorkorders = [localised.numberOfWorkorders];
    const rowTotalServicesPerformed = [localised.totalServicesPerformed];
    const rowServiceRevenue = [localised.serviceRevenue];
    const rowServiceHousekeeperExpense = [localised.serviceHousekeeperExpense];
    const rowServiceAttendantExpense = [localised.serviceHouseAttendantExpense];
    const rowServiceInspectorExpense = [localised.serviceInspectorExpense];
    const rowTotalServiceExpense = [localised.totalServiceExpense];
    const rowServiceNet = [localised.serviceNet];
    const rowServiceMargin = [localised.serviceMargin];
    const rowWorkordersRevenue = [localised.workordersRevenue];
    const rowWorkorderContractorExpense = [localised.workorderContractorExpense];
    const rowWorkorderNet = [localised.workorderNet];
    const rowWorkorderMargin = [localised.workorderMargin];
    const rowNet = [localised.net];
    const rowTotalMargin = [localised.totalMargin];
  
    weekKeys.forEach((wk) => {
      const sequence = headerData[wk]?.Sequence || "";
      const label = headerData[wk]?.Label || "";
  
      rowWeek.push(sequence);
      rowDates.push(label);
  
      rowNumberOfServices.push(jobsAndRevenueData.data.no_of_service?.[wk] ?? "0");
      rowNumberOfReportedIssues.push(jobsAndRevenueData.data.no_of_reported_issues?.[wk] ?? "0");
      rowNewServicesScheduled.push(jobsAndRevenueData.data.new_service_scheduled?.[wk] ?? "0");
      rowNumberOfWorkorders.push(jobsAndRevenueData.data.no_of_workorders?.[wk] ?? "0");
      rowTotalServicesPerformed.push(jobsAndRevenueData.data.total_service_performed?.[wk] ?? "0");
      rowServiceRevenue.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_revenue?.[wk] ?? "0").toFixed(2)}`);
      rowServiceHousekeeperExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_hk_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowServiceAttendantExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_ha_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowServiceInspectorExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_insp_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowTotalServiceExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.total_service_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowServiceNet.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_net?.[wk] ?? "0").toFixed(2)}`);
      rowServiceMargin.push(`${parseFloat(jobsAndRevenueData.data.service_margin?.[wk] ?? "0").toFixed(2)}%`);
      rowWorkordersRevenue.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.wo_revenue?.[wk] ?? "0").toFixed(2)}`);
      rowWorkorderContractorExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.wo_contract_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowWorkorderNet.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.workorder_net?.[wk] ?? "0").toFixed(2)}`);
      rowWorkorderMargin.push(`${parseFloat(jobsAndRevenueData.data.wo_service_margin?.[wk] ?? "0").toFixed(2)}%`);
      rowNet.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.net?.[wk] ?? "0").toFixed(2)}`);
      rowTotalMargin.push(`${parseFloat(jobsAndRevenueData.data.total_margin?.[wk] ?? "0").toFixed(2)}%`);
    });
  
    const tableData = [
      rowWeek,
      rowDates,
      rowNumberOfServices,
      rowNumberOfReportedIssues,
      rowNewServicesScheduled,
      rowNumberOfWorkorders,
      rowTotalServicesPerformed,
      rowServiceRevenue,
      rowServiceHousekeeperExpense,
      rowServiceAttendantExpense,
      rowServiceInspectorExpense,
      rowTotalServiceExpense,
      rowServiceNet,
      rowServiceMargin,
      rowWorkordersRevenue,
      rowWorkorderContractorExpense,
      rowWorkorderNet,
      rowWorkorderMargin,
      rowNet,
      rowTotalMargin,
    ];
  
    return { tableData };
  };

  const prepareNetChartData = (jobsAndRevenueData) => {
    const headerData = jobsAndRevenueData.data.Header.data || {};
    const netObj = jobsAndRevenueData.data.net || {};
    
    const labels = [];
    const netValues = [];
    
    const weekKeys = Object.keys(headerData);

    weekKeys.forEach((wk) => {
      const sequenceLabel = headerData[wk]?.Label || wk; 
      labels.push(sequenceLabel);
      const numericNet = parseFloat(netObj[wk] ?? 0);
      netValues.push(numericNet);
    });

    return {
      labels,
      datasets: [
        {
          label: `${localised.net} (${currencySymbol})`,
          data: netValues,
          fill: false,
          borderColor: "#2a72b5",
          tension: 0.1,
        },
      ],
    };
  };

  
  const searchJobsAndRevenue = (filters) => {
    getJobAndRevenueData(filters);  
  }

  const resetSearchFilter = () => {
    getJobAndRevenueData(filters);  
  }


  const options1 = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 0,
          callback: function (value) {
            return currency.symbol + `${value}`
          }
        },
        beginAtZero: true,
        min: 0,
      }
    }
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
      },
    }

  };


  const options2 = {
    responsive: true,

    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          min: 0,
          stepSize: 0,
          callback: function (value) {
            return `${value}min`
          }
        },
        beginAtZero: true,
        min: 0,
      }
    }
  };

  let datasets = (isCompare && revenueChart && Array.isArray(revenueChart) && revenueChart.length == 6) ? [
    {
      label: revenueChart[4] + " " + localised.revenue + " (" + currency.symbol + ")",
      data: (isArray(revenueChart)) ? revenueChart[0] : [],
      fill: true,
      borderColor: "#2a72b5 ",
    },
    {
      label: revenueChart[5] + " " + localised.revenue + " (" + currency.symbol + ")",
      data: (isArray(revenueChart)) ? revenueChart[2] : [],
      fill: true,
      borderColor: "rgba(75,192,192,1)",
    },
  ] : [
    {
      label: localised.revenue + " (" + currency.symbol + ")",
      data: (isArray(revenueChart)) ? revenueChart[0] : [],
      fill: true,
      borderColor: "#2a72b5 ",
    },
  ]


  let datasets1 = (isCompare && countChart && Array.isArray(countChart) && countChart.length == 6) ? [
    {
      label: countChart[4] + " " + localised.WoCompleted,
      data: (countChart) ? countChart[0] : [],
      fill: true,
      borderColor: "2a72b5",
    },
    {
      label: countChart[4] + " " + localised.WoOpen,
      data: (countChart) ? countChart[1] : [],
      fill: false,
      borderColor: "#29b6f6",
    },
    {
      label: countChart[5] + " " + localised.WoCompleted,
      data: (countChart) ? countChart[2] : [],
      fill: true,
      borderColor: "rgba(75,192,192,1)",
    },
    {
      label: countChart[5] + " " + localised.WoOpen,
      data: (countChart) ? countChart[3] : [],
      fill: false,
      borderColor: "#f99719",
    }
  ] : [
    {
      label: localised.WoCompleted,
      data: (countChart) ? countChart[0] : [],
      fill: true,
      borderColor: "rgba(75,192,192,1)",
    },
    {
      label: localised.WoOpen,
      data: (countChart) ? countChart[1] : [],
      fill: false,
      borderColor: "#f99719",
    },
  ]


  let datasets2 = (isCompare && countChart && Array.isArray(countChart) && countChart.length == 6) ? [
    {
      label: timeChart[2] + " " + localised.avgOpenTime,
      data: (timeChart) ? timeChart[0] : [],
      fill: false,
      borderColor: "#88300087",
    },
    {
      label: timeChart[3] + " " + localised.avgOpenTime,
      data: (timeChart) ? timeChart[1] : [],
      fill: false,
      borderColor: "#29b6f6",
    },

  ] : [
    {
      label: localised.avgOpenTime,
      data: (timeChart) ? timeChart[0] : [],
      fill: false,
      borderColor: "#88300087",
    },
  ]

  let datasets3 = (isCompare && revenueChart && Array.isArray(revenueChart) && revenueChart.length == 6) ? [
    {
      label: revenueChart[4] + " " + localised.avgWoValue + " (" + currency.symbol + ")",
      data: (isArray(revenueChart)) ? revenueChart[1] : [],
      fill: false,
      borderColor: "#29b6f6",
    },
    {
      label: revenueChart[5] + " " + localised.avgWoValue + " (" + currency.symbol + ")",
      data: (isArray(revenueChart)) ? revenueChart[3] : [],
      fill: false,
      borderColor: "#f99719",
    }
  ] : [
    {
      label: localised.avgWoValue + " (" + currency.symbol + ")",
      data: (isArray(revenueChart)) ? revenueChart[1] : [],
      fill: false,
      borderColor: "#29b6f6",
    }
  ]
  const data = {
    datasets: datasets
  };

  const data2 = {
    datasets: datasets1
  };


  const data3 = {
    datasets: datasets2
  };

  const data4 = {
    datasets: datasets3
  };

  const compareTwoValue = ($value1, $value2) => {
    return (parseFloat($value2) - parseFloat($value1));
  }
  
  return (
    <>
      <div className="container-fluid">
        <div className="d-flex align-items-center flex-wrap">
          <div className="list-header-left">
            <h1 className="page-title">{localised.jobsandRevenueReport}</h1>
            <Breadcrumbs
              mainpage={localised.home}
              currentpage={localised.jobsandRevenueReport}
            />
          </div>
        </div>
        <div className="search-container mb-3">
          <Search tableColumn={isCompare} setTableColumn={setISCompare}
            searchJobsAndRevenue={searchJobsAndRevenue}
            resetSearchFilter={resetSearchFilter} showWarningModal={showWarningModal}
            setShowWarningModal={setShowWarningModal}
          />
        </div>
        <div className="row justify-content-center bg-white m-0 mb-3">
          <div className="col-md-6">
            <div className="graph">
            <Line 
              data={netChartData} 
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: function (value) {
                        return `${currencySymbol}${value}`;
                      },
                    },
                  },
                },
              }} 
            />
            </div>
          </div>
        </div>
        <div className="jobsrevenuetable">
        <Grid
          data={tableData}
          search={false}
          pagination={false}
          fixedHeader={true}
          Style={{}}
        />
        </div>
      </div>

    </>
  );
};

export default JobsAndRevenue;
