import React, { useContext, useState } from 'react';

import { languageOptions } from '../../languages';
import ToggleSwitch from '../atoms/ToggleSwitch';
import { LanguageContext } from '../containers/Language';

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange, setLanguageChanged } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = e => {
    userLanguageChange(e.target.value);
    setLanguageChanged(true);
  };

  let [switchLangToggle, setSwitchLangToggle] = useState(languageOptions === "en");
  const onToggleLangChange = (checked) => {
      setLanguageChanged(true);
      setSwitchLangToggle(checked);
      if (checked) {
        userLanguageChange("es");
      }
      else {
        userLanguageChange("en");
      }
  };


  // const switchLang = () => {
  //   if (languageOptions === "es") {
  //     userLanguageChange("en");
  //   } else {
  //     userLanguageChange("es");
  //   }
  // };

  // const switchLang2 = () => {
  //   if (languageOptions === "en") {
  //     userLanguageChange("es");
  //   } else {
  //     userLanguageChange("en");
  //   }
  // };

  return (
    <>
      <select
        onChange={handleLanguageChange}
        value={userLanguage}
      >
        {Object.entries(languageOptions).map(([id, name]) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </select>

      {/* <button onClick={() => switchLang2()}>Eng</button>
      <br />
      <button onClick={() => switchLang()}>
       Span
      </button> */}
      

      {/* <ToggleSwitch
        id="switchLangToggle"
        checked={switchLangToggle}
        onChange={onToggleLangChange}
        optionLabels={["Spanish", "English"]}
      /> */}
    </>
  );
};
