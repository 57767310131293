import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from "../../molecules/CustomModal";
import { toast } from "react-toastify";
import { LanguageContext } from "../../containers/Language";
import ConfirmModal from "../../atoms/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import AutopayNewPayment from "../AutopayNewPayment";
import {
  getCompanySetting,
  getCompanySubscription,
  getInvoiceSetting,
  updateCompanyHasAutopay,
} from "../../../redux/company/action";
import { helpEmailID } from "../../../utils/constUtil";
import moment from "moment";
import { logout } from "../../../redux/authentication/action";
import InvoiceSettings from "../Cleaner/MyAccount/InvoiceSettings";
import { getCompanyWallet } from "../../../redux/Billing/action";
import { isAnyIdPresent } from "../../../utils/additionalFeatureUtil";

const SystemLocked = (props) => {
  const dispatch = useDispatch();

  // newly added code for prototype

  const { localised } = useContext(LanguageContext);
  const loggedInUser =
    useSelector((state) => state.authentication?.profile) || "";
  const alert = useSelector((state) => state.global.alert);
  const companySetting = useSelector((state) => state.company.companySetting);
  const compSubscription = useSelector(
    (state) => state.company.compSubscrption
  );
  const [setAutopayPayment, setShowAutopayPayment] = useState(false);
  const [addPaymentDetail, setAddPaymentDetail] = useState();

  useEffect(() => {
    if (loggedInUser) {
      const company_id = loggedInUser.company_id;
      dispatch(getCompanySetting({ company_id }));
      dispatch(getCompanySubscription({ company_id }));
      setTimeout(() => {dispatch(getInvoiceSetting({ company_id }));  }, 200);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (companySetting && compSubscription) {
      let additionalFeature = loggedInUser.additional_feature_access;
      let isAdditionalFeatureBillable = isAnyIdPresent(additionalFeature);
      if (companySetting.has_autopay_enable && !companySetting.is_autopay_set && (companySetting?.is_billing_eligible || (!companySetting?.is_billing_eligible && isAdditionalFeatureBillable))) {
        if (!compSubscription.has_dnb) 
        {
          setShowAutopayPayment(true);
          setRequestDeactivate(companySetting.deactive_req_init);
        } else {
          setShowAutopayPayment(false);
        }
      } else {
        setShowAutopayPayment(false);
      }
    }
  }, [companySetting, compSubscription]);

  const showAutopayPaymentModal = () =>{
    setShowAutopayPayment(false);
  }

  const showAddPaymentDetailModal = () => {
    setAddPaymentDetail(!addPaymentDetail);
  };

  const [autopayPaymentStaticModalBtns, setAutopayPaymentStaticModalBtns] =
    useState(true);

  const [setDeactiveAccount, setShowDeactiveAccount] = useState();
  const showDeactiveAccountConfrim = () =>
    setShowDeactiveAccount(!setDeactiveAccount);
  const [requestDeactivate, setRequestDeactivate] = useState(false);

  const notifyAutopayMessage = () => {
    let formData = { company_id: loggedInUser.company_id };
    dispatch(
      updateCompanyHasAutopay({
        formData,
        url: "/v1/company/deactivate-request/" + loggedInUser.company_id,
        successMessage: localised.accountDeactivatedSuccessfully,
        componentName: "companyAccDeactiveRequest",
        isMessageShow : true
      })
    );
    setShowDeactiveAccount(false);
  };

  useEffect(() => {
    if (alert && alert !== null) {
      if (alert.variant === "danger") {
        toast.error(alert.message);
      } else {
        switch (alert.componentName) {
          case "companyAccDeactiveRequest":
            toast.success(alert.message);
            setRequestDeactivate(true);
            break;
          case 'propelrPaymentGateway' :
            dispatch(getCompanyWallet({paymentGateway:"cardpointe"}));
            setAddPaymentDetail(false)
            setShowAutopayPayment(false)
          break;
          default:
            break;
        }
      }
      setTimeout(() => {
        dispatch({ type: "CLEAR_ALERT" });
      }, 1000);
    }
  }, [alert]);

  const mailto = "mailto:" + helpEmailID;

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(()=>{
    dispatch({type:"CLEAR_COMPANY_SETTING"});
  },[])

  return (
    <>
      {setAutopayPayment  && (
        <CustomModal
          size={"sm"}
          title={localised.systemLocked}
          show={setAutopayPayment}
          handleSave={showAddPaymentDetailModal}
          handleClose={showAutopayPaymentModal}
          handleDeactivate={showDeactiveAccountConfrim}
          handleLogout={handleLogout}
          photoPoup={false}
          autopayPaymentStaticModalBtns={autopayPaymentStaticModalBtns}
          setAutopayPaymentStaticModalBtns={setAutopayPaymentStaticModalBtns}
          className="center-modal"
          backdrop="static"
          keyboard={false}
          requestSubmit={requestDeactivate}
          removeCloseBtn={false}
        >
          <p>
            {localised.autopayRestricatedText}{" "}
            <a href={mailto}>{helpEmailID}</a>{" "}
            {localised.autopayRestricatedText2}
          </p>
        </CustomModal>
      )}

     { addPaymentDetail &&
        <AutopayNewPayment
          addPaymentDetail={addPaymentDetail}
          setAddPaymentDetail={setAddPaymentDetail}
          showAddPaymentDetailModal={showAddPaymentDetailModal}
          setAutopayPayment={setAutopayPayment}
          setShowAutopayPayment={setShowAutopayPayment}
        ></AutopayNewPayment>
    }

      <ConfirmModal
        message={localised.deactivateAccount}
        onSubmit={notifyAutopayMessage}
        buttontext={localised.yes}
        showconfirm={setDeactiveAccount}
        handlecloseconfirm={showDeactiveAccountConfrim}
        customClass="shadow-confirm"
      />
    </>
  );
};

export default SystemLocked;
