import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import style from './style.scss';
const CustomEditor = React.forwardRef((props, ref) => {
  return <Editor id={props.id} onChange={props.onChange} {...props} />;
});

CustomEditor.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
};
export default CustomEditor;
