import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { LanguageContext } from "../../../../containers/Language";
import "react-datepicker/dist/react-datepicker.css";
import { getDcpContractPositions } from "../../../../../redux/DailyCloseout/action";

const ContractPositions = ({
    selectedPropertyManagerId,
    payload,
    setPayload,
}) => {

    const { localised } = useContext(LanguageContext);

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.global.loading);

    const dcpContractPositions = useSelector((state) => state?.DailyCloseout?.dcpContractPositions);

    const [contractPositions, setContractPositions] = useState();


    useEffect(() => {
        if (selectedPropertyManagerId) {
            getData();
        } else {
            clearData();
        }
    }, [selectedPropertyManagerId]);

    useEffect(() => {
        let _data = [];
        if (dcpContractPositions?.length) {
            _data = [...dcpContractPositions];
            _data = _data.map((row, index) => {
                return {
                    contract_positions_id: row?.contractPos?.contract_positions_id,
                    title: row?.contractPos?.title,
                    rate: Number(row?.weekly_rate),
                };
            });
            setPayload({
                ...payload,
                contract_positions: _data,
            })    
        }
        setContractPositions(_data);
    }, [dcpContractPositions]);

    function getData() {
        dispatch(getDcpContractPositions({ manager_id: selectedPropertyManagerId }));
    }

    function clearData() {
        dispatch({ type: "CLEAR_DCP_CONTRACT_POSITIONS" });
    }

    return (
        <>
            <Table bordered>
                <thead>
                </thead>
                <tbody>
                    {contractPositions?.length == 0 && (
                        <tr>
                            <td colSpan="1">
                                <div className="d-flex justify-content-center m-1">
                                    {!isLoading ? localised.noRecordsFound : localised.pleaseWait}
                                </div>
                            </td>
                        </tr>
                    )}
                    {contractPositions?.map((row, index) => {
                        return (
                            <tr key={index}>
                                <td data-title={localised.title}>
                                    {row?.title}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );

}

export default ContractPositions;