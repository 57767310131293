import React, { useContext } from 'react';
import { Row, Col, Form } from "react-bootstrap";

import { LanguageContext } from '../../containers/Language';
import Image from '../../../assets/img/active-support-bro.png';
import Buttons from '../../atoms/Buttons';
import InputText from '../../atoms/InputText';

const SearchSupportModal = () => {
    const { localised } = useContext(LanguageContext);
    return (
        <div>
            <div className='search-support'>
                <div className='w-25'>
                    <img src={Image} className="w-100" />
                </div>
                <div className='w-75'>
                    <h2 className='mb-3'>{localised.needHelp}?</h2>
                    <p>{localised.helpText}</p>
                    <Form action="https://resortcleaninghelp.zendesk.com/hc/en-us/search?" target="_blank">
                        <Form.Group className="form-group d-flex">
                                    <InputText
                                        id={'query'}
                                        name={'query'}
                                        type={'text'}
                                        placeholder={localised.helpPlaceholder}
                                        required={'required'}
                                    />
                                      <div className='search-btn-link ms-auto'>
                                        <Buttons type="submit" variant='primary' title={localised.search} />
                                    </div>
                                </Form.Group>
                    </Form>
                    <div className='mt-2'>
                        <a href='https://resortcleaninghelp.zendesk.com/hc/en-us' target="_blank" className="btn-sm btn btn-primary">{localised.visitSupportCenter}</a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SearchSupportModal;