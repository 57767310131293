import React, { useState, useContext, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomSelect from '../../../../atoms/CustomSelect';
import Buttons from '../../../../atoms/Buttons';
import InputText from '../../../../atoms/InputText';
import { LanguageContext } from '../../../../containers/Language';
import ImageUploading from 'react-images-uploading';
import { FiEdit, FiTrash, FiUpload, FiInfo, FiDownload, FiPrinter } from "react-icons/fi";
import CustomEditor from '../../../../atoms/CustomEditor';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "./style.scss";
import { emailValidation, getObjectFromArrayByKeyValue, setValidationError } from '../../../../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import ConfirmModal from '../../../../atoms/ConfirmModal';

const colorOptions = [
    { value: 1, label: 'Light Gray' },
    { value: 2, label: 'Light Red' },
    { value: 3, label: 'Light Green' },
    { value: 4, label: 'Light Blue' }
];

const colorCode = [
    "#E5E5E5", "#E5CCCC", "#AED3AE", "#CAE3F0"
];

const invoiceSortingOptions = [
    { value: 1, label: 'By Property Name' },
    { value: 2, label: 'By Cleaning Date' },
    { value: 3, label: 'By Price' },
]

const InvoiceSettings = React.forwardRef((props, ref) => {
    
    const dispatch = useDispatch();

    const refs = useRef(null);

    const { localised } = useContext(LanguageContext);
    const maxNumber = 1;

    const onChange = (imageList) => {
        props.setImages(imageList);
        props.setErrorMessages(false);
    };

    const toHtml = (es) => {
        return draftToHtml(convertToRaw(es.getCurrentContent()))
    }

    const [status, setStatus] = React.useState([]) // 0: no show, 1: show yes, 2: show no.

    const radioHandler = (status) => {
        setStatus(status);
        props.setInvoiceSettingInformation({
            ...props.invoiceSettingInformation,
            invoice_format: status
        });
    };

    const [selectedColor, setSelectedColor] = useState([]);
    const [selectedSort, setSelectedSort] = useState([]);
    const error = useSelector((state) => state.form.error);
    const [noticeState, setNoticeState] = useState(EditorState.createEmpty());

    const [taxMsg, setTaxMsg] = useState({status: false, msg: "", label: ""});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const toggleConfirmModal = () => setShowConfirmModal(!showConfirmModal);

    const loggedInUser = useSelector((state) => state.authentication?.profile) || '';
    let workorderAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 2);
    let houseAttendantAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 3);
        

    const onDropDownChange = (selectedValue, fieldName) => {
        props.setErrorMessages(false);
        let selectedValues = (selectedValue) ? selectedValue : [];
        switch (fieldName) {
            case "invoice_color":
                setSelectedColor(selectedValues);
                break;
            case "invoice_sorting":
                setSelectedSort(selectedValues)
                break;
            default:
                break;
        }
        props.setInvoiceSettingInformation({
            ...props.invoiceSettingInformation,
            [fieldName]: (selectedValues?.value) ? selectedValues.value : null,
        });
    };

    const inputChangeHandler = (event) => {
        props.setErrorMessages(false);
        const { name, value } = event.target;
        switch (name) {
            case "invoice_email_subject":
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    [name]: value
                });
                break;
            case "email_for_invoice_copy":
            case "invoice_email_from":
                props.setErrorMessages(emailValidation(event));
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    [name]: value
                });
                break;
                case "sales_tax_in_percent":
                    props.setInvoiceSettingInformation({
                        ...props.invoiceSettingInformation,
                        [name]: value ?? 0
                    });
                    break;
                    case "custom_tax_label":
                    props.setInvoiceSettingInformation({
                        ...props.invoiceSettingInformation,
                        [name]: value
                    });
                    break;
                    case "custom_tax_in_percent":
                    props.setInvoiceSettingInformation({
                        ...props.invoiceSettingInformation,
                        [name]: value ?? 0
                    });
                    break;
            default:
                break;
        }
    };

    const onNoticeStateChange = editorState => {
        setNoticeState(editorState);
        if(editorState.getCurrentContent().hasText() && editorState.getCurrentContent().getPlainText().trim().length > 0) {
            props.setInvoiceSettingInformation({...props.invoiceSettingInformation, notice: toHtml(editorState)});
        } else {
            props.setInvoiceSettingInformation({...props.invoiceSettingInformation, notice: ""});
        }
    }

    const setInvoiceColor = () => {
        let viewInvoiceColorObj = getObjectFromArrayByKeyValue('value', props?.invoiceSettingInformation?.invoice_color, colorOptions);
        viewInvoiceColorObj = (viewInvoiceColorObj.value) ? viewInvoiceColorObj : [];
        setSelectedColor(viewInvoiceColorObj);
    };

    const setInvoiceSorting = () => {
        let viewInvoiceSortingObj = getObjectFromArrayByKeyValue('value', props?.invoiceSettingInformation?.invoice_sorting, invoiceSortingOptions);
        viewInvoiceSortingObj = (viewInvoiceSortingObj.value) ? viewInvoiceSortingObj : [];
        setSelectedSort(viewInvoiceSortingObj);
    };

    const setInvoiceFormat = () => {
        setStatus(props.invoiceSettingInformation.invoice_format)
    };

    const handleChangeTax = (event, label) => {
        setTaxMsg({
            status: event.target.checked,
            msg: event.target.checked ? localised.applyTaxesOnAllMsg : localised.notApplyTaxesOnAllMsg,
            label
        });
        toggleConfirmModal();
    };

    const notify = (e) => {
        e.preventDefault();
        switch(taxMsg.label) {
            case "sales_tax":
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    enable_all_sales_tax_services: taxMsg.status
                });
            break;
            case "custom_tax":
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    enable_all_custom_tax_services: taxMsg.status
                });
            break;
        }
        toggleConfirmModal();
      }

    const onTaxToggleChange = (event, label) => {
        switch(label) {
            case "workorder_service_tax":
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    is_sales_tax_applicable_to_workorder: event.target.checked
                });
            break;
            case "workorder_custom_tax":
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    is_custom_tax_applicable_to_workorder: event.target.checked
                });
            break;
            case "servicerequest_service_tax":
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    is_sales_tax_applicable_to_servicerequest: event.target.checked
                });
            break;
            case "servicerequest_custom_tax":
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    is_custom_tax_applicable_to_servicerequest: event.target.checked
                });
            break;
            case "no_price_subtask" : 
                props.setInvoiceSettingInformation({
                    ...props.invoiceSettingInformation,
                    no_price_subtask: event.target.checked
                });
            break;
        }
    };

    useEffect(() => {
        setInvoiceColor();
    }, [props?.invoiceSettingInformation?.invoice_color]);

    useEffect(() => {
        setInvoiceSorting();
    }, [props?.invoiceSettingInformation?.invoice_sorting]);

    useEffect(() => {
        setInvoiceFormat();
    }, [props?.invoiceSettingInformation?.invoice_format]);

    useEffect(() => {
        setInvoiceColor();
        setInvoiceSorting();
        setInvoiceFormat();
        props.setFormEditReset(false);
    }, [props?.formEditReset]);

    useEffect(() => {
        if (error) {
            if (error.statusCode === 422) {
                props.setErrorMessages(setValidationError(error));
                setTimeout(() => {
                    dispatch({ type: "CLEAR_VALIDATION_ERROR" });
                }, 100);
            }
        }
    }, [error]);

    useEffect(() => {
        const notice = props?.invoiceSettingInformation?.notice;
        if (!notice || toHtml(noticeState) === notice) return
        setNoticeState(
            EditorState.push(noticeState,
                ContentState.createFromBlockArray(
                    htmlToDraft(notice || ''),
                ),
            ),
        )
    }, [props?.invoiceSettingInformation?.notice]);

    return (
        <>
        <div className='search-container'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title'>{localised.invoiceSettings}</h3>
                </div>
                <div className='card-body'>
                    <Row>
                        <Col md="4">
                            <Row>
                                <Col md="12">
                                    <Form.Group className="form-group">
                                        <div className="upload-image-invoice">
                                            <Form.Label>{localised.companyLogo}</Form.Label>
                                            <ImageUploading
                                                multiple={false}
                                                value={props.images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                }) => (
                                                    <div className="upload__image-wrapper"> 
                                                        <button className='btn btn-outline-primary' type="button"
                                                            style={isDragging ? { color: 'red' } : undefined}
                                                            onClick={onImageUpdate}
                                                            {...dragProps}
                                                        >
                                                            {localised.browseFile} <FiUpload />
                                                        </button>
                                                        &nbsp;
                                                        <Form.Group className="form-group">
                                                            <div className="invalid-feedback" style={{ display: (props.errorMessages?.company_logo) ? "block" : "none" }}>
                                                                {(props.errorMessages?.company_logo) ? props.errorMessages.company_logo : 'Please select Valid Profile Picture'}
                                                            </div>
                                                        </Form.Group>
                                                        {imageList.map((image, index) => (
                                                            
                                                            <div key={index} className="image-item">
                                                                <img src={image['data_url']} alt="" />
                                                                <div className="image-item__btn-wrapper">
                                                                    <button className='u-btn edit-image' onClick={() => onImageUpdate(index)} type="button"><FiEdit /></button>
                                                                    <button className='u-btn delete-image' onClick={() => onImageRemove(index)} type="button"><FiTrash /></button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>
                                        {(props.invoiceSettingInformation?.company_logo != null && !props?.images?.length) &&
                                            <div className="upload-image-invoice">
                                                <div className="upload__image-wrapper">
                                                    &nbsp;
                                                    <div className="image-item">
                                                       {props?.invoiceSettingInformation?.company_logo &&
                                                        <img src={props?.invoiceSettingInformation?.company_logo} alt="" />
                                                       } 
                                                        <div className="image-item__btn-wrapper">
                                                            <button type="button"
                                                                className="u-btn delete-image"
                                                                onClick={() => {
                                                                    props.confirmDeleteProfilePictureHandler();
                                                                }}
                                                            >
                                                                <FiTrash />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <p className='text-small mt-2'>({localised.profileImageNote})</p>
                                      
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group className="form-group">
                                        <Form.Label>{localised.invoiceFormat}</Form.Label>
                                        <div>
                                            <div>
                                                <Form.Check type="radio" name='radio' id='inline-radio-1' label={localised.showNoticeOnTop}
                                                    checked={status === 1} onClick={(e) => radioHandler(1)} />
                                            </div>
                                            <div>
                                                <Form.Check type="radio" name='radio' id='inline-radio-2' label={localised.showNoticeInImportantList}
                                                    checked={status === 2} onClick={(e) => radioHandler(2)} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group className="form-group required">
                                        <Form.Label>{localised.invoiceColor} </Form.Label>
                                        <CustomSelect
                                            id={'color'}
                                            name={'color'}
                                            closeMenuOnSelect={true}
                                            isMulti={false}
                                            options={colorOptions}
                                            value={selectedColor}
                                            isClearable={false}
                                            onChange={(event) => onDropDownChange(event, "invoice_color")}
                                        />
                                        <div className="invalid-feedback" style={{ display: (props.errorMessages?.invoice_color) ? "block": "none"}}>
                                        { (props.errorMessages?.invoice_color) ? props.errorMessages.invoice_color : 'Please Select Invoice Color.'}
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group className="form-group required">
                                        <Form.Label>{localised.invoiceSorting}</Form.Label>
                                        <CustomSelect
                                            id={'invoiceSorting'}
                                            name={'invoiceSorting'}
                                            closeMenuOnSelect={true}
                                            isMulti={false}
                                            value={selectedSort}
                                            options={invoiceSortingOptions}
                                            isClearable={false}
                                            onChange={(event) => onDropDownChange(event, "invoice_sorting")}
                                        />
                                        <div className="invalid-feedback" style={{ display: (props.errorMessages?.invoice_sorting) ? "block": "none"}}>
                                        { (props.errorMessages?.invoice_sorting) ? props.errorMessages.invoice_sorting : 'Please Select Invoice Sorting.'}
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col md="12">
                                    <Form.Group className="form-group">
                                        <Form.Label>{localised.notice}</Form.Label>
                                        <CustomEditor 
                                        onEditorStateChange={onNoticeStateChange}
                                        editorState={noticeState}
                                        contenteditable={true}
                                        name={'notice'}
                                    />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errorMessages?.notice ? props.errorMessages.notice : ""}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md="12" ref={refs}>
                                    <Form.Group className="form-group">
                                        <Form.Label>{localised.emailForInvoiceCopy}
                                            <OverlayTrigger container={refs} overlay={<Tooltip id="tooltip-edit">{localised.emailForInvoiceCopyTooltip}</Tooltip>}>
                                                <span className="d-inline-block ms-1">
                                                    <FiInfo />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <InputText
                                            id={'emailForInvoiceCopy'}
                                            name={'email_for_invoice_copy'}
                                            type={'text'}
                                            placeholder={localised.emailForInvoiceCopy}
                                            onChange={inputChangeHandler}
                                            onKeyPress={props.handleEnterPress}
                                            value={props?.invoiceSettingInformation?.email_for_invoice_copy}
                                            isInvalid={
                                                props.errorMessages?.email_for_invoice_copy ? !!props.errorMessages.email_for_invoice_copy : false
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errorMessages?.email_for_invoice_copy ? props.errorMessages.email_for_invoice_copy : "Please Enter valid Email For Invoice Copy"}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group className="form-group">
                                        <Form.Label>{localised.emailFrom}
                                            <OverlayTrigger container={refs} overlay={<Tooltip id="tooltip-edit">{localised.emailFromTooltip}</Tooltip>}>
                                                <span className="d-inline-block ms-1">
                                                    <FiInfo />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <InputText
                                            id={'emailFrom'}
                                            name={'invoice_email_from'}
                                            type={'text'}
                                            placeholder={localised.emailFrom}
                                            onChange={inputChangeHandler}
                                            onKeyPress={props.handleEnterPress}
                                            value={props?.invoiceSettingInformation?.invoice_email_from}
                                            isInvalid={
                                                props.errorMessages?.invoice_email_from ? !!props.errorMessages.invoice_email_from : false
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errorMessages?.invoice_email_from ? props.errorMessages.invoice_email_from : "Please Enter valid Email For Invoice From"}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group className="form-group">
                                        <Form.Label>{localised.invoiceEmailSubject}</Form.Label>
                                        <InputText
                                            id={'invoiceEmailSubject'}
                                            name={'invoice_email_subject'}
                                            type={'text'}
                                            placeholder={localised.invoiceEmailSubject}
                                            required={(props.activeTab=== props.eventkey) ? 'required' : ''}
                                            onChange={inputChangeHandler}
                                            onKeyPress={props.handleEnterPress}
                                            value={props?.invoiceSettingInformation?.invoice_email_subject}
                                            isInvalid={
                                                props.errorMessages?.invoice_email_subject ? !!props.errorMessages.invoice_email_subject : false
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errorMessages?.invoice_email_subject ? props.errorMessages.invoice_email_subject : "Please Enter valid Invoice Email Subject"}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                <h6 className='mt-4'>{localised.configureTax}</h6>
                                    <Form.Group className="form-group">
                                        <Form.Label>{localised.salesTax}</Form.Label>
                                        <span  style = {{display:"flex"}}><InputText
                                            id={'sales_tax_in_percent'}
                                            name={'sales_tax_in_percent'}
                                            type={'text'}
                                            placeholder={localised.percentage}
                                            onChange={inputChangeHandler}
                                            onKeyPress={props.handleEnterPress}
                                            required={(props.activeTab=== props.eventkey) ? 'required' : ''}
                                            value={props?.invoiceSettingInformation?.sales_tax_in_percent ?? 0}
                                        />{" (%)"}</span>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group className="form-group">
                                        <Form.Check 
                                            type="checkbox"
                                            label={localised.applyToAllServices}
                                            checked={props?.invoiceSettingInformation?.enable_all_sales_tax_services}
                                            onChange={(e) => { handleChangeTax(e, 'sales_tax'); }}
                                        />
                                    </Form.Group>
                                </Col>
                                {workorderAdditionalFeature && <Col md="4">
                                    <Form.Group className="form-group">
                                        <label>{localised.Workorder}</label>
                                        <Form.Check
                                            type="switch"
                                            checked={props?.invoiceSettingInformation?.is_sales_tax_applicable_to_workorder}
                                            className="custom-toggle"
                                            onChange={(e) => {onTaxToggleChange(e, 'workorder_service_tax');
                                            }}
                                        />
                                    </Form.Group>
                                </Col>}
                                {houseAttendantAdditionalFeature && <Col md="6" lg="6">
                                    <Form.Group className="form-group">
                                        <label>{localised.serviceRequests}</label>
                                        <Form.Check
                                            type="switch"
                                            checked={props?.invoiceSettingInformation?.is_sales_tax_applicable_to_servicerequest}
                                            className="custom-toggle"
                                            onChange={(e) => {onTaxToggleChange(e, 'servicerequest_service_tax')}}
                                        />
                                    </Form.Group>
                                </Col>}
                                <Col md="9" lg="9">
                                    <Form.Group className="form-group">
                                        <Form.Label>{localised.addtionalTax}</Form.Label>
                                        <InputText
                                            id={'custom_tax_label'}
                                            name={'custom_tax_label'}
                                            type={'text'}
                                            placeholder={localised.taxName}
                                            onChange={inputChangeHandler}
                                            onKeyPress={props.handleEnterPress}
                                            value={props?.invoiceSettingInformation?.custom_tax_label}
                                            isInvalid={
                                                props.errorMessages?.custom_tax_label ? !!props.errorMessages.custom_tax_label : false
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {props.errorMessages?.custom_tax_label ? props.errorMessages.custom_tax_label : "Please Enter Tax Name"}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md="3" lg="3">
                                    <Form.Group className="form-group">
                                    <Form.Label>{''}</Form.Label>
                                    <span  style = {{display:"flex"}}> 
                                    <InputText
                                            id={'custom_tax_in_percent'}
                                            name={'custom_tax_in_percent'}
                                            type={'text'}
                                            placeholder={localised.percentage}
                                            required={(props.activeTab=== props.eventkey) ? 'required' : ''}
                                            onChange={inputChangeHandler}
                                            onKeyPress={props.handleEnterPress}
                                            value={props?.invoiceSettingInformation?.custom_tax_in_percent ?? 0}
                                            isInvalid={
                                                props.errorMessages?.custom_tax_in_percent ? !!props.errorMessages.custom_tax_in_percent : false
                                            }
                                        />{" (%)"}</span>
                                        <Form.Control.Feedback type="invalid">
                                            {props.errorMessages?.custom_tax_in_percent ? props.errorMessages.custom_tax_in_percent : "Please Enter Tax Amount"}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group className="form-group">
                                        <Form.Check 
                                            type="checkbox"
                                            label={localised.applyToAllServices}
                                            checked={props?.invoiceSettingInformation?.enable_all_custom_tax_services}
                                            onChange={(e) => { handleChangeTax(e, 'custom_tax'); }}
                                        />
                                    </Form.Group>
                                </Col>
                                {workorderAdditionalFeature && <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                        <label>{localised.Workorder}</label>
                                        <Form.Check
                                            type="switch"
                                            checked={props?.invoiceSettingInformation?.is_custom_tax_applicable_to_workorder}
                                            className="custom-toggle"
                                            onChange={(e) => {onTaxToggleChange(e, 'workorder_custom_tax');}}
                                        />
                                    </Form.Group>
                                </Col>}
                                {houseAttendantAdditionalFeature && <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                        <label>{localised.serviceRequests}</label>
                                        <Form.Check
                                            type="switch"
                                            checked={props?.invoiceSettingInformation?.is_custom_tax_applicable_to_servicerequest}
                                            className="custom-toggle"
                                            onChange={(e) => {onTaxToggleChange(e, 'servicerequest_custom_tax');}}
                                        />
                                    </Form.Group>
                                </Col>}
                                <Col md="12">
                                    <h6 className='mt-4'>{localised.configureSubtasks}</h6>
                                    <Form.Group className="form-group">
                                        <label>{localised.includeNoPriceSubtasks}</label>
                                        <Form.Check
                                            type="switch"
                                            checked={props?.invoiceSettingInformation?.no_price_subtask}
                                            className="custom-toggle"
                                            onChange={(e) => {onTaxToggleChange(e, 'no_price_subtask');}}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="8">
                            <div
                                style={{ backgroundColor: `${colorCode[selectedColor?.value - 1]}`, padding: '10px' }}>
                                <table className="table m-0">
                                    <tbody>
                                        <tr>
                                            <td className="t-w50">
                                                {props?.invoiceSettingInformation?.company_logo
                                                    && 
                                                    <div className='invoice-setting-logo'>
                                                    <img  src={props?.invoiceSettingInformation?.company_logo} />
                                                    </div>}
                                                {/* <strong>HHS </strong> */}
                                                <br />
                                                <strong>{props.companyName}</strong>
                                                <br />
                                                {!props?.invoiceSettingInformation?.company_logo
                                                    && props.basicAddressInformation.address}
                                            </td>
                                            <td className="t-w50 t-text-right">
                                                <h5>Invoice Id</h5>
                                                <div>Invoice Number - 123456</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="t-w50">
                                                <strong>From:</strong> Jan 1 <strong>To:</strong> Jan 31
                                            </td>
                                            <td className="t-w50 t-text-right">
                                                <strong>Invoice Date:</strong> Feb 1
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="t-w50">
                                                <strong>Client:</strong>
                                                <br /><strong>Any Client</strong>
                                                <br />123 Any Street
                                                <br />City ST, 123456
                                                <br /><strong>Customer Id:</strong> xxx45
                                                <br /><strong>Project Id:</strong> xxx89
                                                <br /> Phone: (123) 456 -7890
                                            </td>
                                            <td className="t-w50">
                                                {status === 1 && <div>
                                                    <strong>Notice:</strong>
                                                    <div className="notice_area"> <p dangerouslySetInnerHTML={{ __html: props?.invoiceSettingInformation?.notice }}></p> </div>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <table className="table my-3">
                                                    <tbody>
                                                        <tr style={{ backgroundColor: `${colorCode[selectedColor?.value - 1]}` }}>
                                                            <th>Sub Total</th>
                                                            <th>Adjustment</th>
                                                            <th>Amount Paid</th>
                                                            <th>Total Amount</th>
                                                            <th>Number of Appts</th>
                                                        </tr>
                                                        <tr>
                                                            <td>$100.00</td>
                                                            <td>
                                                                $0.00
                                                            </td>
                                                            <td>
                                                                $0.00
                                                            </td>
                                                            <td>
                                                                $0.00
                                                            </td>
                                                            <td>
                                                                1
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <table className="table mb-3">
                                                    <tbody>
                                                        <tr style={{ backgroundColor: `${colorCode[selectedColor?.value - 1]}` }}>
                                                            <th>Property name</th>
                                                            <th>Prop Abbr</th>
                                                            <th>B/B</th>
                                                            <th>Appt #</th>
                                                            <th>Res #</th>
                                                            <th>Apt Date</th>
                                                            <th>Service</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Test Property</td>
                                                            <td>
                                                                TP
                                                            </td>
                                                            <td>
                                                                3/2
                                                            </td>
                                                            <td>
                                                                12345689
                                                            </td>
                                                            <td>
                                                                RES-123456
                                                            </td>
                                                            <td>
                                                                01/01/2022
                                                            </td>
                                                            <td>
                                                                Departure Clean
                                                            </td>
                                                            <td>
                                                                $100.00
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                {status === 2 && <><div id="notice_display_b"><strong>Notice: </strong><p dangerouslySetInnerHTML={{ __html: props?.invoiceSettingInformation?.notice }}></p> </div><br /></>
                                                }
                                                <div><strong>Property Manager Note: </strong> </div>
                                                <br />
                                                <div><strong>Invoice Note: </strong> </div>
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className='t-text-center'>

                                            <strong >{props?.companyName}</strong>
                                                <br />
                                                {props?.basicAddressInformation?.address}<br />
                                               {props?.basicAddressInformation?.city} {(props.basicAddressInformation?.state_abbbreaviation) && ','} {props?.basicAddressInformation?.state_abbbreaviation} {(props.basicAddressInformation.postal_code) && ','} {props?.basicAddressInformation?.postal_code}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="t-text-center">
                                                <div>
                                                    <div className="btn button-icon btn-outline-primary"><FiPrinter /></div>
                                                    &nbsp;
                                                    <div className="btn button-icon btn-outline-primary mx-2"><FiDownload /></div>
                                                    &nbsp;
                                                    <div className="btn btn-primary">Pay Now</div>
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
        <ConfirmModal message={taxMsg.msg} onSubmit={notify} buttontext={localised.yes} showconfirm={showConfirmModal} handlecloseconfirm={toggleConfirmModal} hidePlainTextMessages={true} />
        </>

    )

});

export default InvoiceSettings;