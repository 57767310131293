import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form, Modal, Button, Spinner } from "react-bootstrap";
import { LanguageContext } from "../../../containers/Language";
import CustomSelect from "../../../atoms/CustomSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Buttons from "../../../atoms/Buttons";
import CustomModal from "../../CustomModal";
import { apptsColumns } from "./columns";
import AppointmentTable from "./AppointmentTable";
import BaselineRoles from "./BaselineRoles";
import SupplementalHours from "./SupplementalHours";
import { getDropdownDcpPm, getDcpOverdueNotCompletedAppointments, 
  getDcpTodayNotCompletedAppointments, getDcpDndOverdueNotCompletedAppointments, getDcpDndTodayNotCompletedAppointments, createDcpReport
} from "../../../../redux/DailyCloseout/action";
import moment from "moment";
import { toast } from "react-toastify";
import { completeBulkAppointment, reconcileBulkAppointment } from "../../../../redux/appointment/action";
import ContractPositions from "./ContractPositions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isLoader } from "../../../../redux/global/action";
import { supportedDateFormatOptions } from "../../../../utils/constUtil";



let completedOverdueAppts = [];
let completedTodayAppts = [];
let completedDndOverdueAppts = [];
let completedDndTodayAppts = [];

let refreshGridNo = 0;

const DailyCloseoutModal = ({
  showDcpModal,
  toggleDcpModal,
}) => {

  const { localised } = useContext(LanguageContext);

  const history = useHistory();
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.authentication?.profile) || '';
  const isLoading = useSelector((state) => state.global.loading);
  const pmDropdownOptions = useSelector((state) => state?.DailyCloseout?.pmDropdown);
  const dcpOverdueNotCompletedAppts = useSelector((state) => state?.DailyCloseout?.dcpOverdueNotCompletedAppts);
  const dcpTodayNotCompletedAppts = useSelector((state) => state?.DailyCloseout?.dcpTodayNotCompletedAppts);
  const dcpDndOverdueNotCompletedAppts = useSelector((state) => state?.DailyCloseout?.dcpDndOverdueNotCompletedAppts);
  const dcpDndTodayNotCompletedAppts = useSelector((state) => state?.DailyCloseout?.dcpDndTodayNotCompletedAppts);
  const alert = useSelector((state) => state.global.alert);
	const dateFormat = useSelector((state) => state.authentication.profile.date_format);
  
  const [showLists, setShowLists] = useState(false);
  const [reportDate, setReportDate] = useState(new Date());
  const [propertyManagerOptions, setPropertyManagerOptions] = useState([]);
  const [selectedPropertyManager, setSelectedPropertyManager] = useState();

  const [overdueNotCompletedAppts, setOverdueNotCompletedAppts] = useState([]);
  const [todayNotCompletedAppts, setTodayNotCompletedAppts] = useState([]);
  const [dndOverdueNotCompletedAppts, setDndOverdueNotCompletedAppts] = useState([]);
  const [dndTodayNotCompletedAppts, setDndTodayNotCompletedAppts] = useState([]);

  const [payload, setPayload] = useState({});

  let hasHHSFeaturesAccess = loggedInUser?.has_hhs_features_access || false;

  useEffect(() => {
    if(reportDate) {
      getPropertyManagerDropdown();
    }
  }, [reportDate]);

  useEffect(() => {
    setPropertyManagerOptions(pmDropdownOptions);
  }, [pmDropdownOptions]);

  useEffect(() => {
    if(selectedPropertyManager) {
      getData();
    } else {
      clearData();
    }
  }, [selectedPropertyManager]);

  useEffect(() => {
    let _data = dcpOverdueNotCompletedAppts?.map((row, index) => {
      return {...row, isChecked: false, isCompleted: false};
    });
    setOverdueNotCompletedAppts(_data);
  }, [dcpOverdueNotCompletedAppts]);

  useEffect(() => {
    let _data = dcpTodayNotCompletedAppts?.map((row, index) => {
      return {...row, isChecked: false, isCompleted: false};
    });
    setTodayNotCompletedAppts(_data);
  }, [dcpTodayNotCompletedAppts]);

  useEffect(() => {
    let _data = dcpDndOverdueNotCompletedAppts?.map((row, index) => {
      return {...row, isChecked: false, isCompleted: false};
    });
    setDndOverdueNotCompletedAppts(_data);
  }, [dcpDndOverdueNotCompletedAppts]);

  useEffect(() => {
    let _data = dcpDndTodayNotCompletedAppts?.map((row, index) => {
      return {...row, isChecked: false, isCompleted: false};
    });
    setDndTodayNotCompletedAppts(_data);
  }, [dcpDndTodayNotCompletedAppts]);

  useEffect(() => {
    if (alert && alert !== null) {
      if (alert.variant === "success") {
        switch (alert.componentName) {
          case 'CreateDcpReport': {
            toggleDcpModal();
            history.push('/cleaner-daily-closeout-report');
            break;
          }
        }
      } else {
          toast.error(alert.message);
      }

      setTimeout(() => {
          dispatch({ type: "CLEAR_ALERT" });
      }, 1000);
    }
  }, [alert]);

  function handleReportDateChange (selectedReportDate) {
    clearData();
    setSelectedPropertyManager(null);
    setPropertyManagerOptions([]);
    setReportDate(selectedReportDate);
  };

  function handlePropertyManagerChange(selectedOption) {
    setSelectedPropertyManager(selectedOption);
  }

  function getPropertyManagerDropdown() {
    dispatch(getDropdownDcpPm({ reportDate: moment(reportDate).format('YYYY/MM/DD') }));
  }

  function getData() {
    setPayload({});
    dispatch(getDcpOverdueNotCompletedAppointments({manager_id: selectedPropertyManager.value, report_date: moment(reportDate).format('YYYY/MM/DD')}));
    dispatch(getDcpTodayNotCompletedAppointments({manager_id: selectedPropertyManager.value, report_date: moment(reportDate).format('YYYY/MM/DD')}));
    dispatch(getDcpDndOverdueNotCompletedAppointments({manager_id: selectedPropertyManager.value, report_date: moment(reportDate).format('YYYY/MM/DD')}));
    dispatch(getDcpDndTodayNotCompletedAppointments({manager_id: selectedPropertyManager.value, report_date: moment(reportDate).format('YYYY/MM/DD')}));
    setShowLists(true);
  }

  function clearData() {
    setShowLists(false);
    dispatch({ type: "CLEAR_DCP_OVERDUE_NOT_COMPLETED_APPOINTMENTS" });
    dispatch({ type: "CLEAR_DCP_TODAY_NOT_COMPLETED_APPOINTMENTS" });
    dispatch({ type: "CLEAR_DCP_DND_OVERDUE_NOT_COMPLETED_APPOINTMENTS" });
    dispatch({ type: "CLEAR_DCP_DND_TODAY_NOT_COMPLETED_APPOINTMENTS" });
    setPayload({});
  }

  function columns() { 
    return apptsColumns(localised) ;
  }

  function completeAppts(event, listingArr, gridNo) {
    let appointmentIds = [];
    for (const i in listingArr) {
      if (listingArr[i]?.isChecked) {
        appointmentIds.push({'appointment_id': Number(listingArr[i].appointment_id)});
      }
    }
    if (appointmentIds?.length) {
      refreshGridNo = gridNo;
      switch(gridNo) {
        case 1: {
          let _data = overdueNotCompletedAppts?.map((row, index) => {
            return markComplete(row);
          });
          setOverdueNotCompletedAppts(_data);
          break;
        }
        case 2: {
          let _data = todayNotCompletedAppts?.map((row, index) => {
            return markComplete(row);
          });
          setTodayNotCompletedAppts(_data);
          break;
        }
        case 3: {
          let _data = dndOverdueNotCompletedAppts?.map((row, index) => {
            return markComplete(row);
          });
          setDndOverdueNotCompletedAppts(_data);
          break;
        }
        case 4: {
          let _data = dndTodayNotCompletedAppts?.map((row, index) => {
            return markComplete(row);
          });
          setDndTodayNotCompletedAppts(_data);
          break;
        }
      }
    } else {
      toast.error(localised.selectOneRecord);
    }
  }

  function markComplete(row) {
    if(row?.isChecked && row?.isCompleted == false) {
      return {...row, isCompleted: true};
    } else {
      return {...row};
    }
  }

  function save(event, saveAndSend=false) {
    dispatch(isLoader(true));
    if(selectedPropertyManager) {
      let formData = JSON.parse(JSON.stringify(payload));
      formData = prepareApptsPayload(formData);
      if(formData?.supplemental?.list) {
        formData  = preparedSupplementalPayload(formData)
      }
      formData.report_date = moment(reportDate).format('YYYY-MM-DD');
      formData.manager_id = selectedPropertyManager.value;
      formData.send_email = saveAndSend;
      dispatch(createDcpReport({
        "data": formData,
        "componentName": "CreateDcpReport",
        "sucessMessage": localised.reportCreatedSuccessfully,
        "errorMessage": "Unable to create report!"
      }));
      history.push('/cleaner-daily-closeout-report');
    } else {
      dispatch(isLoader(false));
      toast.error('Please select Property manager');
    }
  }

  function prepareApptsPayload(formData) {
    formData.completed_overdue_appts = overdueNotCompletedAppts?.filter((el) => el?.isCompleted == true)?.map((el) => {
      return {'appointment_id':el.appointment_id}
    });
    formData.completed_today_appts = todayNotCompletedAppts?.filter((el) => el?.isCompleted == true)?.map((el) => {
      return {'appointment_id':el.appointment_id}
    });
    formData.completed_dnd_overdue_appts = dndOverdueNotCompletedAppts?.filter((el) => el?.isCompleted == true)?.map((el) => {
      return {'appointment_id':el.appointment_id}
    });
    formData.completed_dnd_today_appts = dndTodayNotCompletedAppts?.filter((el) => el?.isCompleted == true)?.map((el) => {
      return {'appointment_id':el.appointment_id}
    });
    return formData;
  }

  function preparedSupplementalPayload(formData) {
    let _data = JSON.parse(JSON.stringify(formData?.supplemental?.list));
    let _filteredData = _data?.filter((row, index) => 
      (row.title && Number(row?.hours) > 0) && (row?.dcp_email_supplemental_hrs_id || row?.removed != 1)
    );
    if(_filteredData?.length) {
      formData.supplemental.list = _filteredData;
      formData.supplemental.total_supplemental_hours = _filteredData?.reduce((a, b) => (+a) + (+b.hours), 0);
    } else {
      formData.supplemental.total_hours = 0.00;
      delete formData.supplemental.list;
    }
    return formData;
  }

  return (
    <>
      <CustomModal
        title = {localised.dailyCloseoutProcess}
        show = {showDcpModal}
        handleShow = {toggleDcpModal}
        handleClose = {toggleDcpModal}
        className = "max-width-modal"
        photoPoup = {false}
        saveAndSend = {true}
        handleSave = {(e) => save(e, false)}
        handleSaveAndSend = {(e) => save(e, true)}
      >

        {/* Dropdowns */}
        <div className="modal-content-card pb-4">
          <Row>
            <Col md="4" lg="4">
              <Form.Group className="form-group">
                <Form.Label>
                  {localised.appointment} {localised.date}
                </Form.Label>
                <DatePicker
                  dateFormat={supportedDateFormatOptions[dateFormat]}
                  selected={reportDate}
                  onChange={handleReportDateChange}
                />
              </Form.Group>
            </Col>
            <Col md="4" lg="4">
              <Form.Group className="form-group">
                <Form.Label>{localised.propertyManager}</Form.Label>
                <CustomSelect
                  id={"propertyManager"}
                  name={"propertyManager"}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={propertyManagerOptions}
                  menuPortalTarget={document.querySelector("body")}
                  onChange={handlePropertyManagerChange}
                  value={selectedPropertyManager}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        {showLists && (
          <div>
            {/* Grid 1: overdue not completed */}
            <div className="modal-content-card pb-2">
              <p>{localised.dailyCloseoutProcessNote}</p>
              <AppointmentTable
                key = {'overdue_not_completed'}
                type = {'overdue_not_completed'}
                columns = {columns()}
                data = {overdueNotCompletedAppts}
                setData = {setOverdueNotCompletedAppts}
              />
              <Buttons
                variant = "primary"
                className = "mt-2"
                title = {localised.complete}
                disabled = {isLoading}
                onClick = {(e) => completeAppts(e, overdueNotCompletedAppts, 1)}
              />
            </div>

            {/* Grid 2: not completed */}
            <div className="modal-content-card pb-2">
              <p>{localised.dailyCloseoutProcessNote2}</p>
              <AppointmentTable
                key = {'today_not_completed'}
                type = {'today_not_completed'}
                columns = {columns()}
                data = {todayNotCompletedAppts}
                setData = {setTodayNotCompletedAppts}
              />
              <Buttons
                variant="primary"
                className="mt-2"
                title={localised.complete}
                disabled = {isLoading}
                onClick = {(e) => completeAppts(e, todayNotCompletedAppts, 2)}
              />
            </div>

            {/* Grid 3: DND overdue not completed */}
            <div className="modal-content-card pb-2">
              <p>{localised.dailyCloseoutProcessNote3}</p>
              <AppointmentTable
                key = {'dnd_overdue_not_completed'}
                type = {'dnd_overdue_not_completed'}
                columns = {columns()}
                data = {dndOverdueNotCompletedAppts}
                setData = {setDndOverdueNotCompletedAppts}
              />
              <Buttons
                variant="primary"
                className="mt-2"
                title={localised.reconcile}
                disabled = {isLoading}
                onClick = {(e) => completeAppts(e, dndOverdueNotCompletedAppts, 3)}
              />
            </div>

            {/* Grid 4: DND not completed */}
            <div className="modal-content-card pb-2">
              <p>{localised.dailyCloseoutProcessNote4}</p>
              <AppointmentTable
                key = {'dnd_today_not_completed'}
                type = {'dnd_today_not_completed'}
                columns = {columns()}
                data = {dndTodayNotCompletedAppts}
                setData = {setDndTodayNotCompletedAppts}
              />
              <Buttons
                variant="primary"
                className="mt-2"
                title={localised.reconcile}
                disabled = {isLoading}
                onClick = {(e) => completeAppts(e, dndTodayNotCompletedAppts, 4)}
              />
            </div>

            {/* Baseline roles */}
            <div className="modal-content-card pb-2">
              <p>{localised.inputTheBaselineFTEHourseForTheDay}</p>
              <BaselineRoles 
                key = {'baseline_roles'}
                selectedPropertyManagerId = {selectedPropertyManager?.value}
                payload = {payload}
                setPayload = {setPayload}
              />
            </div>

            {/* Supplemental hours */}
            <div className="modal-content-card pb-2">
              <p>{localised.dailyCloseoutProcessNote5}</p>
              <SupplementalHours 
                key = {'supplemental_hours'}
                payload = {payload}
                setPayload = {setPayload}
              />
            </div>

            {/* Contract Positions */}
            {hasHHSFeaturesAccess && (
              <div className="modal-content-card pb-2">
                <p>{localised.contractPositions}</p>
                <ContractPositions 
                  key = {'contract_positions'}
                  selectedPropertyManagerId = {selectedPropertyManager?.value}
                  payload = {payload}
                  setPayload = {setPayload}
                />
              </div>            
            )}

          </div>
        )}

      </CustomModal>
    </>
  );
};


export default DailyCloseoutModal;
