import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from "../CustomModal";
import { LanguageContext } from "../../containers/Language";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getInvoiceSetting } from "../../../redux/company/action";
import { getCompanyWallet } from "../../../redux/Billing/action";
import { isEmptyNullUndefined } from "../../../utils/utils";

const InValidPaymentDetails = (props) => {

  const dispatch = useDispatch();
  const { localised } = useContext(LanguageContext);
  const loggedInUser = useSelector((state) => state.authentication?.profile) || "";
  const hasShown = useSelector((state) => state.billing.showInvalidPaymentDetails) || false;
  const [showInvalidPaymentDetails, setShowInvalidPaymentDetails] = useState(false);
  const invoiceSetting = useSelector((state) => state.company.invoiceSetting) || [];
  const companyWalletData = useSelector((state) => state.billing.companyWallet) || null;
  const compSubscription = useSelector((state) => state.company.compSubscrption) || "";
  const [user,setUser] = useState(null);
  const [compWalletData,setCompWalletData] = useState(null);
  const [compSub,setCompSub] = useState(null);

  const profileRole = useSelector((state) => state.authentication.profile.role);
  const staffProfileRole = useSelector((state) => state.authentication.profile.companyData.name);
  const isCleaner = (profileRole === "CL" || profileRole === "ST" && staffProfileRole ==='Cleaner');
  
  const toggleInvalidPaymentDetails = () => {
    dispatch({"type": "TOGGLE_INVALID_PAYMENT_DETAILS"});
    setShowInvalidPaymentDetails(!showInvalidPaymentDetails);
  };
  const history = useHistory();

  useEffect(() => {
    if(loggedInUser) {
      setUser(loggedInUser);
      let companyId = loggedInUser.company_id;
      dispatch(getInvoiceSetting({company_id:companyId}));
    }
  }, [loggedInUser]);

  useEffect(() => {
    if(invoiceSetting) {
      let paymentGateway = (invoiceSetting?.payment_gateway?.id == 1) ? 'tilled' : 'cardpointe';
      dispatch(getCompanyWallet({paymentGateway:paymentGateway}));
    }
  }, [invoiceSetting]);

  useEffect(() => {
    if(companyWalletData) {
      setCompWalletData(companyWalletData?.data);
    }
  }, [companyWalletData]);

  useEffect(() => {
    if(compSubscription) {
      setCompSub(compSubscription);
    }
  },[compSubscription])

  useEffect(() => {
    if(compWalletData) {
      if(!isEmptyNullUndefined(compWalletData?.company_wallet_id) && user && compSub) {
        if(!hasShown && (!user?.companyData?.is_wallet_configured || user?.companyData?.is_tilled_wallet_enabled) && !compSub.has_dnb) {
          setShowInvalidPaymentDetails(true);
        }
      }
    }
  }, [compWalletData,compSub,user]);

  const handleAddPaymentDetail = () => {
    dispatch({"type": "TOGGLE_INVALID_PAYMENT_DETAILS"});
    setShowInvalidPaymentDetails(false);
    history.push(isCleaner ? '/cleaner-wallet' : '/manager-wallet'); 
  }

  useEffect(() => {
    return () => {
      setCompSub(null);
      setCompWalletData(null);
      setUser(null);  
    }
  },[]);

  return (
    <>
    {showInvalidPaymentDetails  && (
        <CustomModal
          size={"sm"}
          title={localised.invalidPaymentDetails}
          show={true}
          handleSave={handleAddPaymentDetail}
          handleClose={toggleInvalidPaymentDetails}
          photoPoup={false}
          invalidPaymentDetailsBtns={true}
          className="center-modal"
          backdrop="static"
          keyboard={false}
          removeCloseBtn={false}
        >
          <p>
            {localised.paymentDetailsInvalid}{" "}
            <ul>
              <li>{localised.yourCardMayHaveExpired}</li>
              <li>{localised.invalidBankAccountOrCardNumber}</li>
            </ul>
          </p>
        </CustomModal>
      )}
    </>
  );
};

export default InValidPaymentDetails;
