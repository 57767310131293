import React from "react";
import CleanerTemplate from '../../../templates/CleanerTemplate';
import JobsAndRevenue from "../../../organisms/Cleaner/JobsAndRevenue";

const CleanerJobsAndRevenueReport = () => {
    return (
        <>
            <CleanerTemplate>
              <JobsAndRevenue/>
            </CleanerTemplate>
        </>
    )
}

export default CleanerJobsAndRevenueReport;
