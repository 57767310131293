export const apptsColumns = (localised) => {
    return [
        { id: 'ids', hidden: true,},
        { id: 'select_all', name: "",},
        { id: 'appointment_id', name: localised.appt + " " + "#", sort: 'appointment_id'},
        { id: 'property_name', name: localised.unit, sort: 'property_name'},
        { id: 'service', name: localised.service, sort: 'service'},
        { id: 'departure_date', name: localised.apptDate, sort: 'departure_date'},
    ];
}
