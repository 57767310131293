import React, { Children, Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import keycloak from '../../keycloak';
import { useKeycloak } from '@react-keycloak/web';
import { getRedirectURI } from '../../utils/utils';
import { wizardStatusDisplayIds } from '../../utils/constUtil';

const RedirectHandler = ({children}) => {
  const auth = useSelector(state => state.authentication) || '';
  const history = useHistory();
  const dispatch = useDispatch();
  const {keycloak, initialized} = useKeycloak();
  const currentURL = window.location.href;
  const dashboardAccess = (auth?.profile?.resource_access?.dashboard?.roles?.includes('manage_dashboard'));
  const loggedInUser = useSelector((state) => state.authentication?.profile) || "";
  
  let redirectUri = getRedirectURI();
  if (initialized && !keycloak.authenticated && !auth?.authenticated && !currentURL.includes('impersonate')) {
    //const redirectUri = window.location.origin;
    keycloak.login({ redirectUri });
  }
  
  // if(initialized && !keycloak.authenticated && !auth?.authenticated && !currentURL.includes('impersonate')){
  //   keycloak.init({onLoad: 'login-required', redirectUri: redirectUri});
  // }

  useEffect(() => {
    if(auth?.profile?.role == 'SA' || auth?.profile?.role == 'AD'){
      history.push('/admin-dashboard');
    }
    if(((auth?.profile?.role == 'MN' && (auth.isProxy || auth?.profile?.status_id !== 4))|| (auth?.profile?.role == 'ST' && auth?.profile?.companyData?.company_type_id == 2 && (auth.isProxy || auth?.profile?.owner_status_id !== 4)))){
      if(dashboardAccess) history.push('/manager-dashboard');
      else history.push('/manager-appointments');
    } else if(((auth?.profile?.role == 'MN' && auth?.profile?.status_id === 4) || (auth?.profile?.role == 'ST' && auth?.profile?.companyData?.company_type_id == 2 && auth?.profile?.owner_status_id === 4))) {
      history.push('/manager-rc-invoices');
    }
    if(((auth?.profile?.role == 'CL' && (auth.isProxy || auth?.profile?.status_id !== 4))|| (auth?.profile?.role == 'ST' && auth?.profile?.companyData?.company_type_id == 3 && (auth.isProxy || auth?.profile?.owner_status_id !== 4)))){
      if((wizardStatusDisplayIds.includes(loggedInUser.companyData.wizard_progress))) history.push('/wizard');
      else if(dashboardAccess) history.push('/cleaner-dashboard');
      else history.push('/cleaner-appointments');
    } else if(((auth?.profile?.role == 'CL' && auth?.profile?.status_id === 4) || (auth?.profile?.role == 'ST' && auth?.profile?.companyData?.company_type_id == 3 && auth?.profile?.owner_status_id === 4))) {
      history.push('/cleaner-rc-invoices');
    }
    
    if(auth?.profile == null && auth?.authenticated && !keycloak.authenticated && !currentURL.includes('impersonate')){
      history.push('/session-error');
    }
  },[auth]);

  return (
    <Fragment>{children}</Fragment>
  );
};

export default RedirectHandler;

