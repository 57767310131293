import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import React, {useRef} from "react";

export const LoadingButton = ({loading = false, ...props}) => {
  const buttonRef = useRef(null);
  const buttonStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <Button
      ref={buttonRef}
      disabled={loading}
      style={buttonStyles}
      {...props}
    >
      {loading && <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{position: 'absolute'}}
      />}
      <span style={{color: loading ? 'transparent' : 'inherit'}}>{props.children}</span>
    </Button>
  );
}