export const createRegion = (data) => {
    return {
        type: 'CREATE_REGION',
        payload: data
    };
}

export const createArea = (data) => {
    return {
        type: 'CREATE_AREA',
        payload: data
    };
}

export const getDropdownRegionlist = (data) => {
    return {
        type: 'DROPDOWN_REGION_LIST',
        payload : data
    }
}

export const getDropdownTimezoneslist = () => {
    return {
        type: 'DROPDOWN_TIMEZONES_LIST'
    }
}

export const getDropdownPropertyTypeslist = () => {
    return {
        type: 'DROPDOWN_PROPERTYTYPES_LIST'
    }
}

export const getDropdownRegionwiseArealist= (data) =>{
    return {
        type: 'DROPDOWN_REGION_WISE_AREA_LIST',
        payload:data
    }
}
export const getRegion = (data) => async (dispatch) =>{
    dispatch({ 
      type: 'FETCH_REGION',
      payload:data
    });
}

export const getArea = (data) => async (dispatch) =>{
    dispatch({ 
      type: 'FETCH_AREA',
      payload:data
    });
}

export const updateRegion = (data) => {
    return {
        type: 'UPDATE_REGION',
        payload: data
    };
}

export const updateArea= (data) => {
    return {
        type: 'UPDATE_AREA',
        payload: data
    };
}

export const activeInactiveRegion = (data) =>{
    return {
        type: 'ACTIVE_INACTIVE_REGION',
        payload: data
    };
}

export const activeInactiveArea = (data) =>{
    return {
        type: 'ACTIVE_INACTIVE_AREA',
        payload: data
    };
}

export const getReagionAreaAccess =(data) => {
    return {
        type: 'REGION_AREA_ACCESS',
        payload: data,
        endpoint:'/v1/company/regional-area/'+data.moduleName,
    }; 
}

export const getDropdownPropertyEntryMethodlist = () => {
    return {
        type: 'DROPDOWN_PROPERTY_ENTRY_METHOD_LIST'
    }
}

export const getDropdownPropertySquareFeetlist = () => {
    return {
        type: 'DROPDOWN_PROPERTY_SQUARE_FEET_LIST'
    }
}

export const getPropertiesDropdown= (data) =>{
    return {
        type: 'PROPERTIES_DROPDOWN',
        payload:data
    }
}

export const createProperty = (data) => {
    return {
        type: 'CREATE_PROPERTY',
        payload: data,
        endpoint:'/v1/property/',
        componentName:'AddProperty',
        userType : data.userType,
    };
}
export const getProperties = (model) => async (dispatch, getState) =>{
    dispatch({ 
      type: 'FETCH_PROPERTIES',
      userType : model.userType,
      payload:model
    });
  }
export const getInternalProperties = (model) => async (dispatch) =>{
    dispatch({ 
      type: 'FETCH_INTERNAL_PROPERTIES',
      payload:model
    });
  }
export const getEditReagionAreaAccess =(data) => {
    return {
        type: 'EDIT_REGION_AREA_ACCESS',
        payload: data,
        endpoint:'/v1/company/regional-area/'+data.moduleName+'/',
    };
}

export const updateProperty = (data) => {
    return {
        type: 'UPDATE_PROPERTY',
        payload: data,
        endpoint:'/v1/property/',
        componentName:'EditProperty',
        userType : data.userType,
        message: 'Property Updated successfully!',
    };
}
export const updatePropertyConfig = (data) => {
    return {
        type: 'UPDATE_PROPERTY',
        payload: data,
        endpoint:'/v1/property/',
        componentName:'EditPropertyConfig',
        userType : data.userType,
        message: 'Property Updated successfully!',
    };
}

export const createPropertyNotes = (data) => {
    return {
        type: 'CREATE_PROPERTY_NOTES',
        payload: data
    };
}

export const activeInactiveProperties = (data) =>{
    return {
        type: 'ACTIVE_INACTIVE_PROPERTIES',
        payload: data,
    };
}
export const deletePropertyNote = (data) => {
    return {
        type: 'DELETE_PROPERTY_NOTE',
        payload: data
    };
}

export const sharePropertyNote = (data) => {
    return {
        type: 'SHARE_PROPERTY_NOTE',
        payload: data
    }
}
export const getPropertyNotes = (model) => async (dispatch, getState) =>{
    dispatch({ 
      type: 'FETCH_PROPERTY_NOTES',
      payload:model
    });
}

export const getSinglePropertyNote = (data) => async (dispatch) =>{
    dispatch({ 
      type: 'FETCH_SINGLE_PROPERTY_NOTE',
      payload:data
    });
}

export const updatePropertyNote = (data) => {
    return {
        type: 'UPDATE_PROPERTY_NOTE',
        payload: data
    };
}

export const assignUnassignCleanerToProperty = (data) =>{
    return {
        type: 'ASSIGN_UNASSIGN_CLEANER_TO_PROPERTIES',
        payload: data,
        componentName:'AssignUnassignCleanerToProperty',
        userAction : data.userAction,
    };
}

export const cloneProperty = (data) => {
    return {
        type: 'CLONE_PROPERTY',
        payload: data,
        endpoint:'/v1/property/',
        componentName:'CloneProperty',
        message: 'Property Cloned successfully!',
    };
}

export const getPropertyPhotos = (model) => async (dispatch, getState) =>{
    dispatch({ 
      type: 'FETCH_PROPERTY_PHOTO',
      payload:model
    });
}
export const getPropertyConfiglist = (data) => {
    return {
        type: 'PROPERTY_CONFIG_LIST',
        payload : data
    }
}
export const sharePropertyPhoto = (data) => {
    return {
        type: 'SHARE_PROPERTY_PHOTO',
        payload: data
    }
}

export const shareAllPropertyPhoto = (data) => {
    return {
        type: 'SHARE_ALL_PROPERTY_PHOTO',
        payload: data
    }
}

export const rotatePropertyPhotos = (data) => {
    return {
        type: 'ROTATE_PROPERTY_PHOTO',
        payload: data
    }
}

export const deletePropertyPhoto = (data) => {
    return {
        type: 'DELETE_PROPERTY_PHOTO',
        payload: data
    }
}
export const initializePropertyPhotos = (data) => {
    return {
        type: 'INITIALIZE_PROPERTY_PHOTOS',
        payload: data
    };
}

export const updatePropertyService = (data) => {
    return {
        type: 'UPDATE_PROPERTY_SERVICE',
        payload: data
    };
  }

  export const createPropertyService = (data) => {
    return {
        type: 'CREATE_PROPERTY_SERVICE',
        payload: data
    };
  }

  export const deletePropertyService = (data) => async (dispatch, getState) => {
    dispatch({ 
      type: 'DELETE_PROPERTY_SERVICE',
      payload:data
    });
  }

  export const createPropertyServiceAutomation = (data) => {
    return {
        type: 'CREATE_PROPERTY_SERVICE_AUTOMATION',
        payload: data
    };
  }
  
  


export const initializeTempProperty = (data) =>{
    return {
        type: 'INITIALIZE_UPLOAD_TEMP_PROPERTY',
        payload: data,
    }
}

export const getTempProperty = (data) =>{
    return {
        type: 'FETCH_TEMP_PROPERTY',
        payload: data,
    }
}

export const getPropertyServicesPriceConfig = (model) => async (dispatch, getState) =>{
    dispatch({ 
      type: 'FETCH_PROPERTY_SERVICE_PRICE_CONFIG',
      payload:model
    });
}


export const uploadActualProperty = (data) =>{
    return {
        type: 'UPLOAD_ACTUAL_PROPERTY',
        payload: data,
    }
}

export const fetchInspectionBasedOnRegionArea = (data) =>{
    return {
        type: 'FETCH_INSPECTION_TEMPLATE',
        payload: data,
    }
}

export const getPmPropertyServicesPriceConfig = (model) => async (dispatch, getState) =>{
    dispatch({ 
      type: 'FETCH_PM_PROPERTY_SERVICE_PRICE_CONFIG',
      payload:model
    });
}

export const getPropertiesInspectionDropdown= (data) =>{
    return {
        type: 'PROPERTIES_INSPECTION_TEMPLATE_DROPDOWN',
        payload:data
    }
}

export const declineUploadedProperty= (data) =>{
    return {
        type: 'DECLINE_UPLOAD_PROPERTY',
        payload:data
    }
}

export const getPropertyOwnerListDropdown= (data) =>{
    return {
        type: 'DROPDOWN_PROPERTY_OWNER_LIST',
        payload: data
    }
}

export const addExistingPropertyOwner = (data) =>{
    return {
        type: 'ADD_EXISTING_PROPERTY_OWNER',
        payload: data,
        componentName:'AddExistingPropertyOwner',
        message: 'Property owner added successfully!',
    }
}

export const createPropertyOwner = (data) =>{
    return {
        type: 'CREATE_PROPERTY_OWNER',
        payload: data,
        componentName:'CreatePropertyOwner',
        message: 'Property owner added successfully!',
    }
}

export const fetchPropertyOwnerDetails = (data) => {
    return {
        type: 'FETCH_PROPERTY_OWNER_DETAILS',
        payload: data,
        componentName:'PropertyOwnerDetails',
    }
}

export const updatePropertyOwnerDetails = (data) => {
    return {
        type: 'UPDATE_PROPERTY_OWNER_DETAILS',
        payload: data,
        componentName:'EditPropertyOwnerDetails',
        message: 'Property owner details updated successfully!'
    }
}

export const updateDefaultCleanerService = (data) => {
    return {
        type: 'UPDATE_DEFAULT_PROPERTY_CLEANER',
        payload: data
    };
}

export const updatePropertyIntegration = (data) => {
    return {
        type: 'UPDATE_PROPERTY_INTEGRATION',
        payload: data,
    };
}

export const updateClPropertyIntegration = (data) => {
    return {
        type: 'UPDATE_CL_PROPERTY_INTEGRATION',
        payload: data,
    };
}

export const getAllPropertyPriceConfig = (model) => async (dispatch, getState) =>{
    dispatch({ 
      type: 'GET_ALL_PROPERTY_PRICE_CONFIG',
      payload:model
    });
}
export const updateCheckinCheckoutPropertyConfig = (data) => {
    return {
        type: 'UPDATE_PROPERTY',
        payload: data,
        endpoint:'/v1/property/',
        componentName:'EditPropertyConfigCheckinCheckout',
        userType : data.userType,
        message: 'Property Updated successfully!',
    };
}

export const getDropdownPropertyCleaners = (data) =>{
    return {
        type: 'DROPDOWN_PROPERTY_CLEANERS',
        payload: data
    }
}

export const exportProperty= (data) =>{
    return {
        type: 'EXPORT_PROPERTY',
        payload: data
    }
}

export const printProperty= (data) =>{
    return {
        type: 'PRINT_PROPERTY',
        payload: data
    }
}

export const getInventorySmartTemplate = (model) => async (dispatch, getState) =>{
    dispatch({ 
      type: 'FETCH_INVENTORY_SMART_TEMPLATE',
      payload:model
    });
}

export const updateInventorySmartDeplete= (data) => {
    return {
        type: 'UPDATE_INVENTORY_SMART_DEPLETE',
        payload: data
    };
}

export const getPropertyIntegrations = (data) => {
    return {
        type: 'FETCH_PROPERTY_INTEGRATIONS',
        payload: data
    }
}

export const updatePropertyIntegrations = (data) => {
    return {
        type: 'UPDATE_PROPERTY_INTEGRATIONS',
        payload: data
    };
}

export const updatePropertyGuestyIntegrations = (data) => {
    return {
        type: 'UPDATE_PROPERTY_GUESTY_INTEGRATIONS',
        payload: data
    };
}

export const movePropertyToManager = (data) => {
    return {
        type: 'MOVE_PROPERTY_TO_MANAGER',
        payload: data
    }
}

export const updatePropertyInspectionToggle = (data) => {
    return {
        type: 'UPDATE_PROPERTY_INSPECTION_TOGGLE',
        payload: data
    };
}

export const fetchPropertiesDropdown= (data) =>{
    return {
        type: 'FETCH_PROPERTIES_DROPDOWN',
        payload:data
    }
}

export const updatePropertyAttribute = (data) => {
    return {
      type: "UPDATE_PROPERTY_ATTRIBUTES",
      payload: data,
    };
};
export const initializeTempPropertyService = (data) =>{
    return {
        type: 'INITIALIZE_UPLOAD_TEMP_PROPERTY_SERVICE',
        payload: data,
    }
}

export const getTempPropertyService = (data) =>{
    return {
        type: 'FETCH_TEMP_PROPERTY_SERVICE',
        payload: data,
    }
}

export const deleteTempPropertyService = (data)=>{
    return {
        type :'DELETE_TEMP_PROPERTY_SERVICE',
        payload: data,
    }
}
export const saveTempPropertyService = (data)=>{
    return {
        type :'SAVE_TEMP_PROPERTY_SERVICE',
        payload: data,
    }
}
export const createSaleforceProperty = (data) => {
    return {
      type: "CREATE_SALESFORCE_PROPERTY",
      payload: data,
    };
};

export const deleteSaleforceProperty = (data) => {
    return {
      type: "DELETE_SALESFORCE_PROPERTY",
      payload: data,
    };
};

export const getPropertyBillingAmount = (data) => {
    return {
      type: "GET_PROPERTY_BILLING_AMOUNT",
      payload: data,
    };
};
export const getParentChildPropertyDropdown= (data) =>{
    return {
        type: 'FETCH_PARENT_CHILD_PROPERTIES_DROPDOWN',
        payload:data
    }
}

export const allowClToUpdateOwnerDetails = (data) => {
    return {
      type: "ALLOW_CL_TO_UPDATE_OWNER_DETAILS",
      payload: data,
    };
};

export const fetchWorkorderPropertiesDropdown= (data) =>{
    return {
        type: 'FETCH_WORKORDER_PROPERTIES_DROPDOWN',
        payload:data
    }
}
export const getPropertyDefaultTime = (data) => {
    return {
        type: 'FETCH_PROPERTY_DEFAULT_TIME',
        payload: data
    }
}

export const updatePropertyDefaultTime = (data) => {
    return {
        type: 'UPDATE_PROPERTY_DEFAULT_TIME',
        payload: data
    };
}

export const deleteAllPropertyPhoto = (data) => {
    return {
        type: 'DELETE_ALL_PROPERTY_PHOTO',
        payload: data
    }
}
export const assignApptInspectionTemplate = (data) => {
    return {
        type: 'ASSIGN_APPT_INSPECTION_TEMPLATE',
        payload: data
    };
  }

  export const assignPropertyInventoryTemplate = (data) => {
    return {
        type: 'ASSIGN_PROPERTY_INVENTORY_TEMPLATE',
        payload: data,
        componentName:'EditPropertyConfig',
        message: 'Property Updated successfully!',
    };
}

export const fetchAssignedPropertyInventries = (data) => {
    return {
        type: 'FETCH_ASSIGN_PROPERTY_INVENTORY_LIST',
        payload: data
    };
}

export const fetchManagerAssignedPropertyInventries = (data) => {
    return {
        type: 'FETCH_PROPERTY_MANAGER_ASSIGN_INVENTORY_LIST',
        payload: data
    };
} 

