import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import CleanerHeader from '../../organisms/Cleaner/Header';
import CleanerSidebar from '../../organisms/Cleaner/Sidebar';
import Footer from '../../organisms/Footer';
import useWindowDimensions from '../../core/Dimension';
import 'react-toastify/dist/ReactToastify.css';
import CustomModal from '../../molecules/CustomModal';
import { toast } from "react-toastify";
import { LanguageContext } from "../../containers/Language";
import ConfirmModal from '../../atoms/ConfirmModal';
import SystemLocked from '../../molecules/SystemLocked';
import { useSelector } from 'react-redux';
import InValidPaymentDetails from '../../molecules/InValidPaymentDetails';

const CleanerTemplate = ({ children, ...props }) => {
  const { width } = useWindowDimensions();

  const [headerDeviceMenu, setHeaderDeviceMenu] = useState(false);
  const toggelHeaderDiviceMenu = () => { setHeaderDeviceMenu(!headerDeviceMenu);}

  const [showRegionFilterModal, setShowRegionFilterModal] = useState(false);
  const toggleRegionFilterModal = () => setShowRegionFilterModal(!showRegionFilterModal);

  const [hovered, setHovered] = useState(false);

  const isProxy = useSelector((state) => state.authentication?.isProxy) || false;

  const [isBoolean, setBoolean] = useState(true);
  const hideComponent = () => {
    setBoolean(!isBoolean);
    setHeaderDeviceMenu(true);
    setShowRegionFilterModal(false);
  }
  // for boody class using setfuction

  React.useEffect(() => {
    if (isBoolean === true ) {
      document.body.className = (width > 441) ? "sidebar-toggled" : '';
    } 
    else {
      document.body.className = (width < 441) ? "sidebar-toggled" : '';
    }

  }, [isBoolean]);


  // newly added code for prototype

  const { localised } = useContext(LanguageContext);


  return (
    <>
      <Navbar className="main-header d-flex" onMouseEnter={() => {setHovered(false)}}>
        <CleanerHeader 
        isBoolean={isBoolean} 
        hideComponent={hideComponent} 
        hideSidebarComponent={() => {(width < 441) && setBoolean(true) }}
        headerDeviceMenu={headerDeviceMenu}
        toggelHeaderDiviceMenu={toggelHeaderDiviceMenu}
        showRegionFilterModal={showRegionFilterModal}
        toggleRegionFilterModal={toggleRegionFilterModal}
        />
      </Navbar>
      <div
        className={hovered && isBoolean ? 'sidebar-wrapper sidebar-hovered' : 'sidebar-wrapper'}
        // onMouseEnter={() => {setHovered(true)}}
        >
        <CleanerSidebar role={props.role} isBoolean={props.isBoolean} setHoveredMenuIcon={() => {setHovered(true)}}/>
      </div>
      <div onMouseEnter={() => {setHovered(false)}} onClick={() => {(width < 441) && setBoolean(true) }}>
      {children}
      </div>
      <div className='footer'>
        <Footer />
      </div>

      {!isProxy && <SystemLocked 
        isBoolean={isBoolean} 
      >

      </SystemLocked>}

      {!isProxy &&
        <InValidPaymentDetails
           isBoolean={isBoolean}
          ></InValidPaymentDetails>
      }

    </>

  );
};

CleanerTemplate.propTypes = {
  children: PropTypes.any.isRequired,
};
export default CleanerTemplate;
