import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Tab, Nav, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AchAutopayForm, PAYMENT_TILLED_ACH, PAYMENT_TILLED_CC, WALLET_TILLED_ACH, WALLET_TILLED_CC } from "../../../../utils/paymentConstUtil";
import { getUser } from "../../../../redux/user/action";
import { tilledTypeAch,tilledTypeCard } from "../../../../utils/paymentConstUtil";
import { USA_COUNTRY, getTilled, BANKTRANSFER, buildForm, CREDITCARD,createPaymentMethod, saveWalletDetials, TILLEDACCID,  } from "../../../../utils/tilledUtil";
import { countryWiseStateDropdown } from "../../../../utils/utils"; 
import CustomSelect from "../../../atoms/CustomSelect";
import InputText from "../../../atoms/InputText";

import AchAccountFileds from "../../TilledForms/achAccountFileds";
import CreditCardFileds from "../../TilledForms/creditCardFileds";
import { LanguageContext } from "../../../containers/Language";
import { handleValidations } from "../../../../utils/cardValidation";


const TilledWallet = React.forwardRef((props, ref) => {


    const { localised } = useContext(LanguageContext);
    const countries = useSelector((state) => state.global.countries) || [];
    const [stateOptions, setStatesOptions] = useState([]);

    const [accountType, selectedAccountType] = useState([]);
    const [tilledError, setTilledError] = useState('');
    const userInformation = useSelector((state) => state.user.user) || [];
    const [userProfile, setUserProfile] = useState([]);
    const userProfileDetails = useSelector((state) => state.authentication.profile);
    const dispatch = useDispatch();
    const error = useSelector(state => state.form.error);

    const handleAchCardChange = (e) => {
        const { name, value } = e.target;
        let tilledOb = {
            ...props.tilledPayloadObj,
            ach_debit: {
                ...props.tilledPayloadObj.ach_debit,
                [name]: value
            }
        };
        let cardErr = handleValidations(name, value);

        props.setFormError({ ...props.formError, ...cardErr });
        props.setTilledPayloadObj(tilledOb);
    }

    const handleAccountTypeChange = (selectedValue) => {
        selectedAccountType(selectedValue);
        let tilledOb = {
            ...props.tilledPayloadObj,
            ach_debit: {
                ...props.tilledPayloadObj.ach_debit,
                account_type: selectedValue.value
            }

        };
        let typErr =handleValidations('account_type', selectedValue?.value)

        props.setFormError({ ...props.formError, ...typErr });
        props.setTilledPayloadObj(tilledOb);
    }

    const handleCcChange = (e) => {
        const { name, value } = e.target;

        let tilledOb = {
            ...props.tilledPayloadObj,
            billing_details: {
                ...props.tilledPayloadObj.billing_details,
                [name]: value
            }

        };
        let ccErrr = handleValidations(name, value);
        props.setFormError({ ...props.formError, ...ccErrr });
        props.setTilledPayloadObj(tilledOb);
    }

    const tilledModifyObj = (name, value, isStateClear = false) => {
        let tilledOb = {
            ...props.tilledPayloadObj,
            billing_details: {
                ...props.tilledPayloadObj.billing_details,
                address: {
                    ...props.tilledPayloadObj.billing_details.address,
                    [name]: value
                }
            }
        };
        if (isStateClear) {
            tilledOb = {
                ...tilledOb,
                billing_details: {
                    ...tilledOb.billing_details,
                    address: {
                        ...tilledOb.billing_details.address,
                        state: '',
                    }
                }
            }
        }

        return tilledOb;
    }
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        props.setTilledPayloadObj(tilledModifyObj(name, value));
        let addressErr = handleValidations(name, value);
        props.setFormError({ ...props.formError, ...addressErr });
    }

    const handleCountryChange = (selectedValue) => {
        let selectedValues = (selectedValue) ? selectedValue : [];

        props.selectedCountry(selectedValues);

        setStatesOptions([]);
        props.selectedState('');
        props.setTilledPayloadObj(tilledModifyObj('country', selectedValue?.value, true));
        props.getCountryWiseStates(selectedValues);
        let countryErr = handleValidations('country', selectedValue?.value)
        props.setFormError({ ...props.formError, ...countryErr });

    };


    const handleStateChange = (selectedValue) => {
        props.selectedState(selectedValue);
        props.setTilledPayloadObj(tilledModifyObj('state', selectedValue?.short_name));
        let stateErr =handleValidations('state', selectedValue?.value)

        props.setFormError({ ...props.formError, ...stateErr });
    };


    const handleAgreedTerms = (e) => {
        props.setAgreedTerms(!props.agreedTerms);
        props.setIsSubmitted(false);
    };

    useEffect(() => {
        if (props.countryWiseStates) {
            let states = countryWiseStateDropdown(props.countryWiseStates);
            setStatesOptions(states);
        }
    }, [props.countryWiseStates]);

    const clearTiledForm = () => {
        initTilledForm(userInformation);
    }

    const initTilledForm = (userInformation) => {
        let countryObj1 = (userInformation?.Address?.Country?.country_code) ? { value: userInformation?.Address?.Country?.country_code, label: userInformation?.Address?.Country?.country } : USA_COUNTRY
        let tilledObjNew = {
            ...props.tilledPayloadObj,
            billing_details: {
                ...props.tilledPayloadObj.billing_details,
                address: {
                    ...props.tilledPayloadObj.billing_details.address,
                    street: (userInformation?.Address?.address) ? userInformation?.Address?.address : '',
                    state: (userInformation?.state_abbbreaviation) ? userInformation?.state_abbbreaviation : '',
                    city: (userInformation?.Address?.city) ? userInformation?.Address?.city : '',
                    zip: (userInformation?.Address?.postal_code) ? userInformation?.Address?.postal_code : '',
                    country: (userInformation?.Address?.Country?.country_code) ? userInformation?.Address?.Country?.country_code : USA_COUNTRY.value,
                },
                name: (userInformation.name) ? (userInformation.name) : '',
                email: (userInformation.email) ? userInformation.email : '',
                phone: (userInformation.phone) ? userInformation.phone : '',
            }
        }

        if (props.tilledPayloadObj.type == 'card') {
            tilledObjNew = { ...tilledObjNew, type:'card' }
        } else if (props.tilledPayloadObj.type == 'ach_debit') {
            tilledObjNew = { ...tilledObjNew, type:'ach_debit',...tilledTypeAch  }
        }
        props.setTilledPayloadObj(tilledObjNew)
        props.selectedCountry(countryObj1);
        props.selectedState((userInformation?.state_abbbreaviation) ? { value: userInformation?.state_abbbreaviation, label: userInformation?.Address?.State?.state_name } : '');
      
        props.getCountryWiseStates(countryObj1);
    }

    useEffect(() => {
        if (userProfileDetails) {
            setUserProfile(userProfileDetails);
            let id = userProfileDetails.user_id;

            if (id) {
                dispatch(getUser({ id }));
            }
        }
    }, [userProfileDetails]);

    useEffect(() => {
        if (Object.keys(userInformation).length > 0) {
            initTilledForm(userInformation);
        }
    }, [userInformation]);

    useEffect(() => {
        if (error) {
          if (error.statusCode === 422) {
            let errors = error?.data;
    
            let errorsServer = [];
            let obj = {};
    
            if (Object.keys(errors).length > 0 && typeof errors === 'object' && errors.length > 0) {
              errors.map((err) => {
                let objName = err.substring(0, err.indexOf('.'))

                let objKey = err.substring(err.indexOf('.') + 1);
               
                let keyName = objKey.split(' ')[0]
               
                let value = objKey.slice(keyName.length)
             
                let errorKey = keyName + '_error';
    
                obj = {
                  ...obj,
                  [errorKey]: value
                };
    
              })
              props.setFormError(obj);
            }
          }
    
        }
      }, [error]);

      
  useEffect(()=>{
    const script = document.createElement('script');
      script.src = "https://js.tilled.com/v2";
      script.async = true;
      document.body.appendChild(script);
      return () => {
          document.body.removeChild(script);
          if (props?.setIsSubmitted && typeof props.setIsSubmitted === 'function') {
              props.setIsSubmitted(false);
          }
      };
  },[])
  
    return (
        <>
        {
                props.autoPayEdit && Object.keys(props.autoPayData).length >0 &&

                <div className='modal-content-card pt-2'>
                    <div className="modal-heading">
                        <h3>{localised.paymentDetails}</h3>
                        <div>
                            <Row>
                                {

                                    props.autoPayData?.data?.bank?.type == 'card' &&
                                    <Col md="10">
                                        <p>{localised.cardNo}  : {'******************' + props.autoPayData?.data?.bank?.card?.last4} </p>
                                        <p>{localised.expDate} :{props.autoPayData?.data?.bank?.card?.exp_month + '/' + props.autoPayData?.data?.bank?.card?.exp_year} </p>
                                        <p>{localised.cardHolderName} :{props.autoPayData?.data?.bank?.card?.holder_name} </p>
                                        <p>{localised.bilingEmail} :{props.autoPayData?.data?.bank?.billing_details?.email} </p>

                                    </Col>
                                }
                                {
                                    props.autoPayData?.data?.bank.type == 'ach_debit' &&
                                    <Col md="10">

                                        <p>{localised.accountNo} :{"********************" + props.autoPayData?.data?.bank?.ach_debit?.last2} </p>
                                        <p>{localised.routingNo} :{props.autoPayData?.data?.bank?.ach_debit?.routing_number} </p>
                                        <p>{localised.accountType} :{props.autoPayData?.data?.bank?.ach_debit?.account_type} </p>
                                        {/* <p>{localised.bilingEmail} :{props.autoPayData?.data?.bank?.billing_details?.email} </p> */}
                                    </Col>
                                }
                            </Row>

                        </div>

                    </div>
                </div>
            }
            {props.isAutoPayRequest &&
                <div className='modal-content-card pt-2'>
                    <div className="modal-heading">
                        <h3>{localised.paymentMethod}</h3>
                    </div>

                    <Row>
                        <Col md="6">
                            <Form.Group className="form-group">
                                <div className='d-flex align-items-center flex-wrap'>
                                    <div>
                                        <Form.Check
                                            type="radio" name='radio'
                                            id='inline-radio-1' label={localised.cc}
                                            onClick={(e) => props.radioHandlerTilledCc()}
                                            checked={props.tilledPayloadObj.type == 'card'}
                                        />
                                    </div>
                                    <div className="ms-md-4">
                                        <Form.Check
                                            type="radio" name='radio'
                                            id='inline-radio-2' label={localised.ach}
                                            onClick={(e) => props.radioHandlerTilledAch()}
                                            checked={props.tilledPayloadObj.type == 'ach_debit'}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>

            }
            <div className='modal-content-card pb-2'>
                <div className="modal-heading d-flex">
                    <h3>{localised.paymentDetails} </h3>
                </div>

                <Row>

                    {(props.active === props.tilledCC) ?
                        <CreditCardFileds
                            {...props}
                            tilledError={tilledError}
                            handleCcChange={handleCcChange}
                            handleAchCardChange={handleAchCardChange}
                            handleAgreedTerms={handleAgreedTerms}
                        /> : <AchAccountFileds
                            {...props}
                            tilledError={tilledError}
                            handleAccountTypeChange={handleAccountTypeChange}
                            handleAchCardChange={handleAchCardChange}
                            handleAgreedTerms={handleAgreedTerms}
                        />
                    }

                </Row>
            </div>
            <div className='modal-content-card pb-2'>
                <div className="modal-heading d-flex">
                    <h3>{localised.billingAddressInformation} </h3>
                </div>
                <Row>
                    <Col>
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.streetAddress}</Form.Label>
                            <Form.Control
                                as="textarea"
                                name={'street'}
                                rows={2}
                                value={props.tilledPayloadObj?.billing_details?.address?.street}
                                onChange={(e) => handleAddressChange(e)}
                                isInvalid={(props.formError?.street_error) ? !!props.formError.street_error : false}
                                onKeyPress={!(props.isAutoPayRequest) ? props.handleEnterPressOnTilled : (e) => { return }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.formError && props.formError.street_error && props.formError.street_error.length > 0 && (
                                    props.formError.street_error
                                )}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.country}</Form.Label>
                            <CustomSelect
                                id={'country'}
                                name={'country'}
                                value={props.country}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                options={countries}
                                onChange={handleCountryChange}
                                isClearable={true}
                                isInvalid={(props.formError?.country_error) ? !!props.formError.country_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid"  style={{ display: (props.formError && props.formError.country_error && props.formError.country_error.length > 0) ? "block": "none"}}>
                                {
                                    props.formError.country_error
                                }</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.state}</Form.Label>
                            <CustomSelect
                                id={'state'}
                                name={'state'}
                                value={props.state}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                options={stateOptions}
                                onChange={handleStateChange}
                                isClearable={true}
                                isInvalid={(props.formError?.state_error) ? !!props.formError.state_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid" style={{ display: (props.formError && props.formError.state_error && props.formError.state_error.length > 0) ? "block": "none"}}>
                                {props.formError && props.formError.state_error && props.formError.state_error.length > 0 && (
                                    props.formError.state_error
                                )}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.city}</Form.Label>
                            <InputText
                                id={'city'}
                                name={'city'}
                                type={'text'}
                                value={props.tilledPayloadObj?.billing_details?.address?.city}
                                placeholder={localised.city}
                                onChange={(e) => handleAddressChange(e)}
                                required={'required'}
                                isInvalid={(props.formError?.city_error) ? !!props.formError.city_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.formError && props.formError.city_error && props.formError.city_error.length > 0 && (
                                    props.formError.city_error
                                )}</Form.Control.Feedback>

                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.zipCode}</Form.Label>
                            <InputText
                                id={'zipCode'}
                                name={'zip'}
                                type={'text'}
                                value={props.tilledPayloadObj?.billing_details?.address?.zip}
                                placeholder={localised.zipCode}
                                onChange={(e) => handleAddressChange(e)}
                                required={'required'}
                                isInvalid={(props.formError?.zip_error) ? !!props.formError.zip_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.formError && props.formError.zip_error && props.formError.zip_error.length > 0 && (
                                    props.formError.zip_error
                                )}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        
            {props?.isagreeRequiredOnLocal && props?.isAgreeRequired && 
            <div className='modal-content-card pb-2'>
                <div className="modal-heading d-flex">
                    <h3>{localised.autopayAgreementPayment} </h3>
                </div>
                <Row>
                    <Col>
                        <Form.Group className="form-group only-checkbox">
                            <Form.Check 
                                type="checkbox"
                                name="radio"
                                key="radio1"
                                id="inline-radio-2"
                                checked={props.agreedTerms}
                                onChange={(event) => handleAgreedTerms(event)}
                                label=  {<>{localised.autopayAgreement} <span style={{ color: 'red' }}>*</span></>}
                                className={props.isSubmitted && !props.agreedTerms ? "checkbox-warning" : ""}
                            />
                        </Form.Group>
                    </Col>
                </Row>            
            </div>
        }
        </>
    )

})
export default TilledWallet;
