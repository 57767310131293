export const deleteDailyCloseoutReport = (model) => async (dispatch, getState) => {
  dispatch({ 
    type: 'DELETE_DAILY_CLOSEOUT_REPORT',
    payload:model
  });
}

export const getDropdownDcpPm = (data) => {
    return {
      type: "DROPDOWN_DCP_PM",
      payload: data
    }
}

export const getDropdownDcpPmOptions = (data) =>{
  return {
    type: "DROPDOWN_DCP_PM_OPTIONS",
    payload: data
  }
}
export const getDcpOverdueNotCompletedAppointments = (data) => {
  return {
    type: "DCP_OVERDUE_NOT_COMPLETED_APPOINTMENTS",
    payload: data
  }
}

export const getDcpTodayNotCompletedAppointments = (data) => {
  return {
    type: "DCP_TODAY_NOT_COMPLETED_APPOINTMENTS",
    payload: data
  }
}

export const getDcpDndOverdueNotCompletedAppointments = (data) => {
  return {
    type: "DCP_DND_OVERDUE_NOT_COMPLETED_APPOINTMENTS",
    payload: data
  }
}

export const getDcpDndTodayNotCompletedAppointments = (data) => {
  return {
    type: "DCP_DND_TODAY_NOT_COMPLETED_APPOINTMENTS",
    payload: data
  }
}

export const getDcpBaselineRoles = (data) => {
  return {
    type: "GET_DCP_BASELINE_ROLES",
    payload: data
  }
}

export const getDcpContractPositions = (data) => {
  return {
    type: "GET_DCP_CONTRACT_POSITIONS",
    payload: data
  }
}

export const createDcpReport = (data) => {
  return {
    type: "CREATE_DCP_REPORT",
    payload: data
  }
}

export const sendDailyCloseoutReportEmail = (data) => {
  return {
      type: 'SEND_DAILY_CLOSEOUT_REPORT_EMAIL',
      payload: data,
  };
}
export const downloadExcelReport = (data) => {
  return {
      type: 'EXPORT_DAILY_CLOSEOUT_REPORT',
      payload: data
  }
}

export const getDcpCompletedAppointments = (data) => {
  return {
    type: "DCP_COMPLETED_APPOINTMENTS",
    payload: data
  }
}

export const getDcpDndCompletedAppointments = (data) => {
  return {
    type: "DCP_DND_COMPLETED_APPOINTMENTS",
    payload: data
  }
}

export const getDcpOverdueCompletedAppointments = (data) => {
  return {
    type: "DCP_OVERDUE_COMPLETED_APPOINTMENTS",
    payload: data
  }
}

export const getDcpDndOverdueCompletedAppointments = (data) => {
  return {
    type: "DCP_DND_OVERDUE_COMPLETED_APPOINTMENTS",
    payload: data
  }
}

export const downloadPDFReport = (data) => {
  return {
      type: 'DOWNLOAD_DAILY_CLOSEOUT_REPORT_PDF',
      payload: data
  }
}

export const getDcpReportBaselineRoles = (data) => {
  return {
    type: "GET_DCP_REPORT_BASELINE_ROLES",
    payload: data
  }
}

export const getDcpReportSupplementalHrs = (data) => {
  return {
    type: "GET_DCP_REPORT_SUPPLEMENTAL_HRS",
    payload: data
  }
}

export const getDcpReportContractPositions = (data) => {
  return {
    type: "GET_DCP_REPORT_CONTRACT_POSITIONS",
    payload: data
  }
}

export const editDcpReport = (data) => {
  return {
    type: "EDIT_DCP_REPORT",
    payload: data
  }
}