import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../../containers/Language";
import {Form, Modal} from "react-bootstrap";
import {supportedDateFormatOptions} from "../../../../utils/constUtil";
import moment from "moment";
import DatePicker from 'react-datepicker'
import {PropTypes} from "prop-types";
import {updateUserSetting} from "../../../../redux/company/action";
import {convertDate} from "../../../../utils/utils";
import {getUser} from "../../../../redux/user/action";
import Alert from "react-bootstrap/Alert";
import {FiInfo} from "react-icons/fi";
import {LoadingButton} from "../../../atoms/LoadingButton";

export const PauseSmsNotificationsModal = (
  {
    show,
    handleHide,
  }
) => {
  const dispatch = useDispatch();
  const {localised} = useContext(LanguageContext);
  const dateFormat = useSelector((state) => state.authentication.profile.date_format);
  const userDetails = useSelector((state) => state.user.user);
  const isPaused = userDetails?.sms_paused_until && new Date(userDetails?.sms_paused_until) > new Date();
  const initialPauseSmsDate = isPaused ? new Date(userDetails.sms_paused_until) : null;
  const [pauseSmsDate, setPauseSmsDate] = useState(initialPauseSmsDate);
  const today = moment().add(1, 'days').toDate();
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [isLoadingResume, setLoadingResume] = useState(false);
  
  const [hasDateChanged, setHasDateChanged] = useState(false);
  
  useEffect(() => {
    setHasDateChanged(initialPauseSmsDate?.toISOString() !== pauseSmsDate?.toISOString())
  }, [pauseSmsDate])

  const handleSubmit = () => {
    setLoadingSubmit(true);
    dispatch(
      updateUserSetting(
        {
          sms_paused_until: pauseSmsDate ? convertDate(pauseSmsDate) : null,
          user_id: userDetails.user_id,
        },
        () => {
          dispatch(getUser({id: userDetails.user_id}));
          handleHide();
          setLoadingSubmit(false);
        }
      )
    );
  };

  const handleResume = () => {
    setLoadingResume(true);
    dispatch(
      updateUserSetting(
        {
          sms_paused_until: null,
          user_id: userDetails.user_id,
        },
        () => {
          dispatch(getUser({id: userDetails.user_id}));
          handleHide();
          setLoadingResume(false);
        }
      )
    );
  };

  return (
    <Modal
      show={show}
      size="md"
      backdrop={true}
      keyboard={false}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{localised.pauseSmsNotifications}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-content-card">
          <Alert variant="info"><FiInfo className="mr-10"/>{localised.pauseSmsExplanation}</Alert>
          <Form>
            <Form.Group className="form-group">
              <Form.Label>{localised.pauseUntil}</Form.Label>
              <DatePicker
                minDate={today}
                dateFormat={supportedDateFormatOptions[dateFormat]}
                selected={pauseSmsDate}
                onChange={setPauseSmsDate}
                placeholderText={localised.date}
                showYearDropdown={false}
                scrollableYearDropdown={false}
              />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center">
        <LoadingButton 
          onClick={handleHide} disabled={isLoadingSubmit || isLoadingResume}
          variant="light" 
          style={{marginRight: 'auto'}}
        >
          {localised.close}
        </LoadingButton>
        <LoadingButton variant="primary" onClick={handleSubmit} disabled={!hasDateChanged || isLoadingSubmit || isLoadingResume}
                       loading={isLoadingSubmit}>
          {isPaused ? localised.update : localised.pauseSmsNotifications}
        </LoadingButton>
        {
          isPaused &&
          <LoadingButton variant="success" onClick={handleResume} disabled={isLoadingSubmit || isLoadingResume}
                         loading={isLoadingResume}>
            {localised.resumeSmsNotifications}
          </LoadingButton>
        }
      </Modal.Footer>
    </Modal>
  );
}

PauseSmsNotificationsModal.propTypes = {
  show: PropTypes.bool,
  handleHide: PropTypes.func,
};

