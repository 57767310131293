import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getInitials } from '../../../utils/utils';

const ProfileIcon = (props) => {
  const [profileInitials, setProfileIntials] = useState('');
  
  useEffect(()=>{
    if(props.profile && Object.keys(props.profile).length > 0){
      setProfileIntials(getInitials(props.profile));
    }
  },[props.profile]);

  return (
    <>
              {/* <img src={Avatar} alt="avatar"/> */}
              {profileInitials}
    </>
  );
};
export default ProfileIcon;
