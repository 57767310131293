import React from 'react';
import { PropTypes } from 'prop-types';
import { FiChevronsRight } from "react-icons/fi";
import { NavLink, Link } from 'react-router-dom';
import Style from './styles.scss';

const Breadcrumbs = React.forwardRef((props, ref) => {

    return (
      <div className="justify-content-center mt-2">
        <ol className="breadcrumb">
          {props?.mainpage && (
            <>
              <li className="breadcrumb-item">
                <NavLink to="/">{props.mainpage}</NavLink>
              </li>
              <li className="breadcrumb-item icon">
                <FiChevronsRight />
              </li>
            </>
          )}

          {props?.childPage?.map((page) => {
            return (
              <>
                <NavLink
                  className="no-underline-link"
                  to={"/" + page.routeName}
                >{page.name}
                </NavLink>
                <li className="breadcrumb-item icon" style={{ paddingLeft: '1em' }}>
                  <FiChevronsRight />
                </li>
              </>
            );
          })}

          <li className="breadcrumb-item active" aria-current="page">
            {props.currentpage}
          </li>
        </ol>
      </div>
    );
    });
Breadcrumbs.propTypes = {
    mainpage: PropTypes.string,
    currentpage: PropTypes.string,
    childPage: PropTypes.object,
};
export default Breadcrumbs;
