import React, { useState, useContext } from "react";
import { Col, Row } from 'react-bootstrap';
import Style from './styles.scss';
import { Text, LanguageContext } from '../../containers/Language';

const Footer = () => {
  const { localised } = useContext(LanguageContext);
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div className="container-fluid">
      <Row className="mx-0"> 
        <Col>
            <p>{localised.Copyright} © {year} ResortCleaning Inc.</p>
        </Col>
      </Row>
    </div>
  );
};
export default Footer;
