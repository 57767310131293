import { useSelector } from "react-redux";

export const useProfile = () => {
  const profile = useSelector((state) => state.authentication.profile);
  const role = profile?.role;

  const isManager =
    role === "MN" ||
    (role === "ST" && profile?.companyData?.name === "Manager");
  const isCleaner =
    role === "CL" ||
    (role === "ST" && profile?.companyData?.name === "Cleaner");

  const additionalFeatures = profile?.additional_feature_access ?? [];

  const hasAccessToFeature = (featureName) => {
    return additionalFeatures.some(
      (item) => item.features_name === featureName
    );
  };

  return { isCleaner, isManager, role, additionalFeatures, hasAccessToFeature };
};
