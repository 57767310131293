import moment from "moment";
import { func } from "prop-types";
import { filterPropertyOptions } from "./propertyUtil";
import { microServices } from "../config/global";
import apiService from "../redux/apiService";
import VisaCard from "../assets/img/visacard.png";
import MasterCard from "../assets/img/mastercard.png";
import AmexCard from "../assets/img/amexcard.png";
import JcbCard from "../assets/img/jcbcard.png";
import DiscoverCard from "../assets/img/discovercard.png";
import DinersClubCard from "../assets/img/dinerclubcard.png";

export const DAYS_INDEX = {'Sunday': 0, 'Monday': 1, 'Tuesday': 2, 'Wednesday': 3, 'Thursday': 4, 'Friday': 5, 'Saturday': 6};

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const getKeycloakClient = () => {
  let client = '';
  const currentURL = window.location.href;
  if (currentURL.includes(':3001') || currentURL.includes('rcadmin') ||  currentURL.includes('admin')) {
    client = process.env.REACT_APP_AUTH_ADMIN_CLIENT;
  } else {
    client = process.env.REACT_APP_AUTH_USER_CLIENT;
  }

  return client;
}

export const getRedirectURI = () => {
  let redirectUri = '';
  const currentURL = window.location.href;
  if (currentURL.includes(':3001') || currentURL.includes('rcadmin') ||  currentURL.includes('admin')) {
    redirectUri = process.env.REACT_APP_ADMIN_URL;
  } else {
    redirectUri = process.env.REACT_APP_WEB_URL;
  }

  return redirectUri;
}

export const getInitials = (profileData) => {
  let name = profileData.name;

  let initials = name.split(' ');

  if (initials.length > 1) {
    initials = initials.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 2);
  }

  return initials.toUpperCase();
}

export const getRole = (userRole, roles) => {
  return roles[userRole];
}

export const whiteSpaceHandler = (event) => {
  if (event.which === 32) {
    event.preventDefault();
  }
}

export const checkDecimalInput = (e) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode === 190) { return }
  else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    e.preventDefault();
  }
}

export const checkNumericInput = (event, prevState) => {
  if (event.target.value !== '' && !Number(event.target.value)) {
    return {
      ...prevState,
      [event.target.name]: 'Please enter numeric value'
    }
  }
  else {
    return {
      ...prevState,
      [event.target.name]: ''
    }
  }
}

export const setValidationError = (error) => {
  const validationError = [];
  if(Object.keys(error).length > 0){
    Object.entries(error?.data).map(([key, value]) => (
      validationError[key] = value
    ));
  }
  return validationError;
}

export const filterArrayByKeyValue = (key, values, collection = [], valueKey='value') => {
  //  if values is obj then pass values compare key in valueKey
  let filteredArray = [];
  if (Array.isArray(values)) {
    let filterArrayData = [];
    if (values.length) {
      values.forEach((val) => {
         filterArrayData.push(getFilterCollection(key, val[valueKey], collection)) ;
      })
    }
    if(filterArrayData){
      filterArrayData.map(filters => {
        if(Array.isArray(filters)){
          filters.map(filter => {
            filteredArray.push(filter);
          })
        }
      })
    }
   
  } else {
    filteredArray = getFilterCollection(key, values, collection);
  }
  return filteredArray;
}

export const currencyFormat = (number, fractionDigits = 2, locale='en-US', currencyCode = 'USD') => {
  let formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: fractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: fractionDigits, // (causes 2500.99 to be printed as $2,501)
  });

  let formatted = number ? formatter.format(number) : formatter.format(0);

  // Replace 'CHF' with 'Fr' manually
  if (currencyCode === 'CHF') {
    formatted = formatted.replace('CHF', 'Fr');
  }

  return formatted;
}

export function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

export function phoneMasking(e) {
  var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
}

export const countryWiseStateDropdown = (countryWiseStates) => {
  let states = [];

  if (countryWiseStates) {
    countryWiseStates.forEach((state) => {
      states.push({
        value: state.state_id,
        label: state.name,
        short_name:state.short_name,
      });
    });
  }
  return states;
}

export const getObjectFromArrayByKeyValue = (key, value, collection = []) => {
  let collectionObj = {};
  if (collection.length) {
    collection.forEach((coll) => {
      if (coll[key] === value) {
        collectionObj = coll;
      }
    })
  }
  return collectionObj;
}

export const accountManagerDropdown = (accountManagers) => {
  let accountManagerList = [];
  if (accountManagers) {
    accountManagers.data.forEach((state) => {
      accountManagerList.push({
        value: state.user_id,
        label: state.first_name + ' ' + state.last_name
      });
    });
  }
  return accountManagerList;
}

export const getSelectedLableRecords = (data,toRemove) => {
  const dataList = data?.filter((label) => {
    return toRemove.includes(label.label);
  });
  return dataList;
}

export const getActivePropertyRegion = (propertyRegion) => {
  let toRemove = "Active Region";
  const activePropertyRegionList = propertyRegion.filter((label) => {
    return toRemove.includes(label.label);
  });
  return activePropertyRegionList;
}

const getFilterCollection = (key, value, collection = []) => {
  let filteredData = [];
  if (collection.length) {
    collection.forEach((coll) => {
      if (coll[key] === value) {
        filteredData.push(coll);
      }
    })
  }
  return filteredData;
}

export const getSelectedMultiDropdownValue =(data, collection, key) =>{
  let selectedData = [];
  if(data){
      for(let i=0; i<data.length; i++){
          let memberObj = getObjectFromArrayByKeyValue('value', data[i][key],collection);
          if(!isObjEmpty(memberObj)){
            selectedData.push(memberObj);
          }
      }
  }
  return selectedData;
}

export const getlistToTree = (arr = []) => {
  let map = {}, node, res = [], i;
  for (i = 0; i < arr.length; i += 1) {
     map[arr[i].id] = i;
     arr[i].ischeck = false;
     arr[i].children = [];
  };
  for (i = 0; i < arr.length; i += 1) {
     node = arr[i];
     node.ischeck = false;
     if (node.parent_id !== 0) {
      if(arr[map[node.parent_id]]!==undefined){
        arr[map[node.parent_id]].children.push(node);
      } 
     }
     else {
        res.push(node);
     };
  };
  return res;
};

export const emailValidation=(event, prevState)=>{
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if(event.target.value !== '' && regex.test(event.target.value) === false){
    return {
      ...prevState,
      [event.target.name]: 'Please enter valid Email Address'
    }
  }else {
    return {
      ...prevState,
      [event.target.name]: ''
    }
  }
}

export const fileValidation=(size, expectedSize, fieldname, prevState)=>{
  if( size > expectedSize)
  {
    let errorMessage = `File should not be greater than ${(expectedSize/1000000).toFixed(2)} MB`;
    return {
      ...prevState,
      [fieldname]: errorMessage
    }
  }
  else {
    return {
      ...prevState,
      [fieldname]: ''
    }
  }
}

export const getCLEditlistToTree = (arr = [],$usetype) => {
  let map = {}, node, res = [], i;
  for (i = 0; i < arr.length; i += 1) {
     map[arr[i].id] = i;
     arr[i].ischeck = false;
     arr[i].children = [];
  };
  for (i = 0; i < arr.length; i += 1) {
     node = arr[i];
     if($usetype === 'staff' && node.staff_region_area_id!==null){
      node.ischeck = true;
     }
     if($usetype === 'group' && node.groups_region_area_id!==null){
      node.ischeck = true;
     }
     if($usetype === 'client' && node.manager_region_area_id!==null){
      node.ischeck = true;
     }
     if($usetype === 'template' && node.template_region_area_id!==null){
      node.ischeck = true;
     }
     if($usetype === 'service-checklist-template' && node.checklist_templates_region_area_id!==null){
      node.ischeck = true;
     }
     if ($usetype === "region-area-config" && node.isChecked !== null) {
      node.ischeck = true;
    }
     if (node.parent_id !== 0) {
       if(arr[map[node.parent_id]]!==undefined){
           arr[map[node.parent_id]].children.push(node);
       }
     }else{
        res.push(node);
     };
  };
  return res;
};

export const getStaffEditlistToTree = (arr = [],$useType,$type) => {
  let node, region = [], regionT=[], i;
  for (i = 0; i < arr.length; i += 1) {
     let regionName = arr[i].path.split('>>');
     arr[i].regionName = regionName[0];
     arr[i].ischeck = false;
  };
  for (i = 0; i < arr.length; i += 1) {
     node = arr[i];
    
     const isInArray = region.includes(node.region_id);
     if(isInArray === false && node.region_id !== null){
       if(node.staff_region_area_id!==null){
        node.ischeck = true;
       }
       region.push(node.region_id);
       let data =  getEachRegionResult(arr,node.region_id,$useType,$type);
       if (data[0].parent_id !== 0) {
         regionT.push({ "id": node.region_id, "name": node.regionName, "is_check": true, "ischeck": node.ischeck, "children": data });
       } else {
         regionT.push(...data);
       }
     }
  };
  return regionT;
};

function getEachRegionResult(arr = [],regionId,usetype,pagetype){
  let map = {}, node, region = [], i;
  for (i = 0; i < arr.length; i += 1) {
     map[arr[i].id] = i;
     arr[i].children = [];
     arr[i].level = arr[i].path.split('>>').length;
     arr[i].ischeck = false;
  };
  for (i = 0; i < arr.length; i += 1) {
    node = arr[i];
    if(usetype === 'staff' && pagetype ==='edit' && node.staff_region_area_id!==null){
      node.ischeck = true;
    }
    if(usetype === 'group' && pagetype ==='edit' && node.groups_region_area_id!==null){
      node.ischeck = true;
    }
    if(usetype === 'client' && pagetype ==='edit' && node.manager_region_area_id!==null){
      node.ischeck = true;
    }
    if(usetype === 'template' && pagetype ==='edit' && node.template_region_area_id!==null){
      node.ischeck = true;
    }
    if(usetype === 'service-checklist-template' && pagetype ==='edit' && node.checklist_templates_region_area_id!==null){
      node.ischeck = true;
    }
    if (usetype === "region-area-config" && pagetype === "edit" && node.isChecked !== null) {
      node.ischeck = true;
    }
    if (arr[map[node.parent_id]]!==undefined && node.parent_id !== 0) {
        arr[map[node.parent_id]].children.push(node);
    }else if(regionId === node.region_id && arr[i].level > 0){
      // if(arr[i].id === regionId){ continue; }
          region.push(node);
    };  
};
  return region;
}
  
export const getBetweenDate = (from,to) => {
  return from+'-'+to;
}

export const convertDate = (selectedValue) => {
  return moment(selectedValue).format('YYYY/MM/DD');
}

export const filterInspByRegionAreas = (inspList, regionId, areaId) => {
  let finalInspList = [];
  if (inspList) {
    inspList.map((insp, index) => {
      if (insp.regionAreas) {
        if (isRegionExists(insp.regionAreas, regionId) && isAreaExists(insp.regionAreas, areaId)) {
          finalInspList.push({ 'value': insp.user_id, 'label': insp.name });
        } else if (isRegionExists(insp.regionAreas, regionId) && !isValue(areaId)) {
          finalInspList.push({ 'value': insp.user_id, 'label': insp.name });
        } else if (isAreaExists(insp.regionAreas, areaId) && !isValue(regionId)) {
          finalInspList.push({ 'value': insp.user_id, 'label': insp.name });
        }

      }

    })
  }
  return finalInspList;
}


export const filterInspTempByRegionAreas = (inspTempList, regionId, areaId) => {
  let finalInspTempList = [];
  if (inspTempList) {
    inspTempList.map((insp, index) => {
      if (insp.regionAreas) {
        if (isRegionExists(insp.regionAreas, regionId) && isAreaExists(insp.regionAreas, areaId)) {
          finalInspTempList.push({ 'value': insp.template_id, 'label': insp.title });
        } else if ((isRegionExists(insp.regionAreas, regionId) && !isValue(areaId)) || (isAreaExists(insp.regionAreas, areaId) && !isValue(regionId))) {
          finalInspTempList.push({ 'value': insp.template_id, 'label': insp.title });
        } 
      } 
    })
  }
  return finalInspTempList;
}

export const filterChecklistByRegionAreas = (Checklist, regionId, areaId) => {
  let finalChecklist = [];
  if (Checklist) {
    Checklist.map((insp, index) => {
      if (insp.regionAreas) {
        if (isRegionExists(insp.regionAreas, regionId) && isAreaExists(insp.regionAreas, areaId)) {
          finalChecklist.push({ 'value': insp.checklist_template_id, 'label': insp.template_title });
        } else if ((isRegionExists(insp.regionAreas, regionId) && !isValue(areaId)) || (isAreaExists(insp.regionAreas, areaId) && !isValue(regionId))) {
          finalChecklist.push({ 'value': insp.checklist_template_id, 'label': insp.template_title });
        } 
      } 
    })
  }
  return finalChecklist;
}


export const isRegionExists = (obj, value) => {
  let isvalue = false;
  obj?.forEach(function (arrayItem) {
    if (arrayItem.region_id === value) {
      isvalue = true;
    }
  });
  return isvalue
}

export const isAreaExists = (obj, value) => {
  let isval = false;
  obj?.forEach(function (arrayItem) {
    if (arrayItem.area_id === value) {
      isval = true;
    }
  });
  return isval
}

export function isValue(obj) {
  return obj && obj !== 'null' && obj !== 'undefined';
}

export const convertToDollar = (value) => {
  return "$"+ parseFloat(value).toFixed(2);
}

export const convertTime = (apiTime,sendDefault = true) => {
  if(apiTime){
  var chunked = apiTime?.split(":");
  var date = new Date();
  date?.setHours(chunked[0]);
  date?.setMinutes(chunked[1]);
  return moment(date);
  }else{
    return (sendDefault) ? moment() : null;
  }
}

export const prepareHouseKeeperList = (response,staffType) => {
  let hkGroupList = [];
  let hkList = [];

  let GroupList = {
    label: "Groups",
    options: hkGroupList,
  }
  
  let HousekeeperList = {
    label: "Housekeepers",
    options: hkList,
  }

  response.data.data.map((group) => {
    if(staffType==="hkGroup") {
      hkGroupList.push({
        value: group.group_id,
        label: group.group_name,
        key:'group',
      });
    }
    else if (staffType==="hk") {
      hkList.push({
        value: group.user_id,
        label: group.name,
        key:'hk'
      });
    }
  });

  if(staffType==="hkGroup") return GroupList;
  if(staffType==="hk") return HousekeeperList;
  
}


export const prepareFilterUrl = (filters) => {
  let searchUrl = '';
  if (filters) {
    for (let [key, value] of Object.entries(filters)) {
      if (value) {
        searchUrl += `filter[${key}]=${value}&`
      }
    }
  }
  return searchUrl;
}

export const checkFilterFields = (filter) => {
  let filterUrl = prepareFilterUrl(filter);
  return filterUrl ? `?${filterUrl}` : '';
}


export const prepareInspectorList = (response) => {
  let ActiveInspectorList = [];
  let InactiveInspectorList = [];
  let ActiveSupervisorList = [];
  let InactiveSupervisorList = [];

  let GroupList = [{
    label: "Active Inspector",
    options: ActiveInspectorList,
  },
  {
    label: "Inactive Inspector",
    options: InactiveInspectorList,
  },
  {
    label: "Active Supervisor",
    options: ActiveSupervisorList,
  },
  {
    label: "Inactive Supervisor",
    options: InactiveSupervisorList,
  }
  ];
  response.data.data.map((group) => {
        group.professions.forEach((prof) => {
          if(prof.profession_type_id === 2) {
            if (group.Status.status_id == '1') {
              ActiveInspectorList.push({
                value: group.user_id,
                label: group.name
              })
            }
            if (group.Status.status_id == '2') {
              InactiveInspectorList.push({
                value: group.user_id,
                label: group.name
              })
            }
          } 

          if(prof.profession_type_id === 3) {
            if (group.Status.status_id == '1') {
              ActiveSupervisorList.push({
                value: group.user_id,
                label: group.name
              })
            }
            if (group.Status.status_id == '2') {
              InactiveSupervisorList.push({
                value: group.user_id,
                label: group.name
              })
            }
          }
        })
  });

  return GroupList;
}

export const preparePropertiesList = (response,userType) => {
  let ActivePropertiesList = [];
  let InactivePropertiesList = [];
  let PropertiesList = [{
    label: "Active Properties",
    options: ActivePropertiesList,
  },
  {
    label: "Inactive Properties",
    options: InactivePropertiesList,
  },
  ];
  response.data?.data.map((properties) => {
      let unit_no ='';
        if(properties.properties){
          if(properties.properties?.unit_no){
            unit_no = (properties.properties?.unit_no !='0') ? ' - '+properties.properties?.unit_no :'';
          }
        }
      const property_name = properties.properties ? properties.properties.property_name 
      : properties.property_name;

      const properties_id = properties.properties ? properties.properties.properties_id 
      : properties.properties_id;
      if(userType === 'cleaner') {
        if (properties.Status.status_id == 1 && properties?.properties?.status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`
          });
        } else if (properties.Status.status_id == 2 || properties.properties?.status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`
          });
        }

      } else if (userType === 'manager') {
        if (properties.Status.status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`
          });
        } else if (properties.Status.status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`
          });
        }
      }
  });
  return PropertiesList;
}

export const preparePropertyDropdown = (response,userType) => {
  let ActivePropertiesList = [];
  let InactivePropertiesList = [];
  let PropertiesList = [{
    label: "Active Properties",
    options: ActivePropertiesList,
  },
  {
    label: "Inactive Properties",
    options: InactivePropertiesList,
  },
  ];
  response.data?.data.map((properties) => {

    let unit_no ='';
    if(properties?.unit_no){
      unit_no = (properties?.unit_no !='0') ? ' - '+properties?.unit_no :'';
    }

      const property_name = properties.property_name;
      const properties_id = properties.properties_id;
      if(userType === 'cleaner') {
        if (properties.status_id == 1 && properties?.property_status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`
          });
        } else if (properties?.status_id == 2 || properties?.property_status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`
          });
        }

      } else if (userType === 'manager') {
        if (properties.status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`
          });
        } else if (properties.status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`
          });
        }
      }
  });
  return PropertiesList;
}

export const preparePropertyGroup = (response) => {
  let ActiveGroupList = [];
  let InactiveGroupList = [];

  let GroupList = [{
    label: "Active",
    options: ActiveGroupList,
  },
  {
    label: "Inactive",
    options: InactiveGroupList,
  },
  ]; 
  let status;
  
  response.data.data.map((group) => {
    
      status = group.status.status_id;
    
    if (status === 1) {
      ActiveGroupList.push({
            value: group?.id,
            label: group?.property_name
          });

    } else if (status === 2) {
      InactiveGroupList.push({
            value: group?.id,
            label: group?.property_name
          });
    }
  });
  return GroupList;
}

export const prepareAssociateList = (response) => {
  let ActiveAssociates = [];
  let InActiveAssociates = [];

  let AssociateList = [{
    label: "Active",
    options: ActiveAssociates,
  },
  {
    label: "Inactive",
    options: InActiveAssociates,
  },
  ];
  response.data.data.map((data) => {

    if(data.manager) {
      if (data.manager.status.status_id == '1') {
        ActiveAssociates.push({
          value: data.company.id,
          label: data.company.company_name
        })
      }
      if (data.manager.status.status_id == '2') {
        InActiveAssociates.push({
          value: data.company.id,
          label: data.company.company_name
        })
      }
    }

    if(data.cleaners) {
      if (data.cleaners.status.status_id == '1') {
        ActiveAssociates.push({
          value: data.company.id,
          label: data.company.company_name
        })
      }
      if (data.cleaners.status.status_id == '2') {
        InActiveAssociates.push({
          value: data.company.id,
          label: data.company.company_name
        })
      }
    }

  });
  return AssociateList;
}

export const preventNonNumericalInput = (e) => {
  e = e || window.event;
  let charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  let charStr = String.fromCharCode(charCode);
  if (!charStr.match(/^[0-9]+$/))
    e.preventDefault();
}

export const isEmptyNullUndefined = (obj) => {
  return (obj == '' || obj == 'undefined' || obj == null);
}

export const NumberFractionFmt = (num) => {
  return (num > 0) ? (Math.round(num * 100) / 100).toFixed(2) : '0.00';
}

export const isObjEmpty = (obj) => {
   return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

export const isObjectHaveNoValues = (obj) => {
  
  if(obj && Object.keys(obj).length > 0) {
    for(let prop in obj) {
      if(obj[prop] !== undefined) {
        return false
      }
    }
    return true;
  }

  return false;
}

export const isGroup = (obj, value) => {
  let isval = false;
  obj?.forEach(function (arrayItem) {
      if (arrayItem.key === value) {
          isval = true;
      }
  });
  return isval
}

export function isEmpty(array) {
  return Array.isArray(array) && (array.length == 0 || array.every(isEmpty));
}

export function isArray(array) {
  return Array.isArray(array) && array.length !== 0;
}


export const getPropertyIntegrationCateogry = (pageTitle) => {
  switch (pageTitle) {
    case "Liverez":
    case "Rezkey":
    case "Streamline":
      return "auto_ical";
    
    case "VRBO":
      return "vrbo_ical";
    
    case "Alabama VHR":
    case "Booking.com":
    case "BookingSync":
    case "Direct PMS":
    case "Guesty":
    case "Hospitable":
    case "Host Away":
    case "Hostfully":
    case "Houfy":
    case "iGMS":
    case "iVacation":
    case "Janiis":
    case "Kigo":
    case "LMPM":
    case "Lodgify":
    case "Lodgix":
    case "OneRooftop":
    case "SkyRun":
    case "SmartBnb":
    case "Smoobu":
    case "Tokeet":
    case "Track":
    case "Trip Advisor":
    case "Uplisting":
    case "Vacation Rental Desk":
    case "Vacation RentPro":
    case "WP Hotel Booking":
    case "Guesty for Hosts":
    case "365Villas":
    case "Cirrus":
    case "beds24":
    case "SuperControl":
    case "AlluraDirect":
      return "alt_ical"

    case "Hostify":
      return "hostify_ical";
    
    case "AirBnB":
      return "airbnb_ical";
    
    case "PMBO":
      return "pmbo_ical";
    
    case "My VR":
      return "myvr_ical";
    
    case "VRM":
      return "vrm_property_id";
    
    case "Guesty Direct":
      return "guesty_property_id";

    case "Booking Auto":
      return "ba_property_id";

    case "Liverez Direct":
      return "liverez_property_id";

    case "BrightSide":
      return "brightside_property_id";

    case "iTrip":
      return "itrip_property_id";

    case "Oscar":
      return "oscar_property_id";

    case "WebrezPro":
      return "webrezpro_roomno";

    case "Escapia":
    case "V12":
      return "homeaway_property_id";

    case "OwnerRez":
      return "own_rez_property_id";

    case "Track Direct":
      return "normal_track_property_id";

    case "Avantio":
      return "xwbr_property_id";

    case "Streamline Direct":
      return "streamline_property_id";

    case "Evolve":
      return "evolve_prop_id";

    case "Edit Vacasa Connections":
      return "cl_vacasa_property_id";

    case "Itrip Workorder":
      return "itrip_workorder_property_id";

    case "Barefoot":
      return "barefoot_property_id";

    case "inventorySmart":
        return "inventory_smart_id";

    case "Lmpm Direct":
          return "lmpm_property_id";
    case "Saleforce":
          return "saleforce_property_id"; 
    case "Vacation Rent Pro Direct":
      return "vacation_rent_pro_property_id"; 
    case "Hostfully Direct":
        return "hostfully_property_id";      
    case "Uplisting Direct":
        return "uplisting_property_id";      
    case "Hostaway Direct":
      return "hostaway_property_id";
    case "Hospitable Direct":
      return "hospitable_property_id";
    case "StayCloud":
        return "staycloud_roomno";
    case "Lodgify Direct":
        return "lodgify_property_id";
    case "AlluraDirectNew":
      return "alluradirect_property_id";
          
    default:
      break
  }
}

export const getPropertyCleanerDropdownOption =(data) =>{
  let selectedData = [];
  if(data){
      for(let i=0; i<data.length; i++){
          let memberObj = { value : data[i].cleaner_id , 
                            property_cleaner_id : data[i].property_cleaner_id , 
                            label : data[i].company_name, 
                            checkIn :data[i].default_checkin_time ,  
                            checkOut :data[i].default_checkout_time, 
                            services : data[i].services,
                            is_default_cleaner: data[i].is_default_cleaner,
                            currency : data[i].currency
                          };
          selectedData.push(memberObj);
      }
  }
  return selectedData;
}

export const checkIfCompanyIntegration = (pageTitle) => {
  switch (pageTitle) {
    case "Guesty Direct":
        return true;
        
    default:
      break;
  }

}

export const _autopayIconLabelColor = (data) => {

  let iconColorLabel = { color: '', label: '',iconType:''};

  if (data.enable_autopay == false && data.has_customer == true) {
    iconColorLabel.label = 'Disabled /Profile Saved ';
    iconColorLabel.color = 'green';
    iconColorLabel.iconType='uncheck';
    return iconColorLabel;
  }

  if (data.is_autpay_decline==true && data.enable_autopay == false) {
    iconColorLabel.label = 'Decline/Disabled ';
    iconColorLabel.color = 'red';
    iconColorLabel.iconType='uncheck';
    return iconColorLabel;
  }
  
  if (data.autopay_is_verified==false && data.enable_autopay == true && data.has_customer == true) {
    iconColorLabel.label = 'Enabled/Profile Saved (Unverified) ';
    iconColorLabel.color = 'yellow';
    iconColorLabel.iconType='check';
    return iconColorLabel;
  }
  if (data.autopay_is_verified==true && data.enable_autopay == true && data.has_customer == true) {
    iconColorLabel.label = 'Enabled/Profile Saved (Verified) ';
    iconColorLabel.color = 'green';
    iconColorLabel.iconType='check';
    return iconColorLabel;
  }
  if (data.enable_autopay == true && data.has_customer == false) {
    iconColorLabel.label = 'Enabled/Profile Incomplete ';
    iconColorLabel.color = 'gray';
    iconColorLabel.iconType='check';
    return iconColorLabel;
  }
 
  if (data.enable_autopay == false) {
    iconColorLabel.label = ' Disabled ';
    iconColorLabel.color = 'gray';
    iconColorLabel.iconType='uncheck';
    return iconColorLabel;
  }
  
}

export const getPaymentMode = (modeId) => {
  return modeId===1 ? "Check" : modeId==2 ? "Credit Card" : modeId==8 ? "Cash" : "-"
}

export const getServicesFromPropertyservice =(data) =>{
  let servicesData = [];
  data?.forEach(function (arrayItem) {
    servicesData.push({value : arrayItem.services?.id, 
                  label : arrayItem.services?.name, 
                  property_service_id : arrayItem.property_service_id, 
                  is_date_calc : arrayItem.services?.is_date_calc, 
                  tasks : arrayItem.services?.tasks,
                  default_ha : arrayItem.default_ha_id,
                  default_hk : arrayItem.default_hk_id,
                  default_insp : arrayItem.cl_inspector_id,
                  cl_template : arrayItem.cl_template,
                  pm_template : arrayItem.pm_template,
                  is_default: arrayItem?.is_default,
                  default_pm_insp : arrayItem?.pm_inspector_id,
                  default_tm_id : arrayItem?.default_tm_id,
                  default_price : arrayItem?.price,
                  is_hk_group : arrayItem?.is_hk_group,
                  projected_hk_time : arrayItem?.projected_hk_time
                });
  });
  return servicesData;
} 

export const getServicesFromCleaner =(data) =>{
  let servicesData = [];
  data?.services?.forEach(function (arrayItem) {
    if(arrayItem.is_default) {
      servicesData.push({value : arrayItem.services?.id, 
                  label : arrayItem.services?.name, 
                  property_service_id : arrayItem.property_service_id, 
                  is_date_calc : arrayItem.services?.is_date_calc, 
                  tasks : arrayItem.services?.tasks,
                  default_ha : arrayItem.default_ha_id,
                  default_hk : arrayItem.default_hk_id,
                  default_insp : arrayItem.cl_inspector_id,
                  cl_template : arrayItem.cl_template,
                  pm_template : arrayItem.pm_template
                });
    }
    
  });
  return servicesData;
} 

export const getObjectFromGroupDropdown = (key, value, collection = []) => {
  let collectionObj = {};
  if (collection.length) {
    collection.forEach((coll) => {
      coll.options.forEach((coll2) => {
        if (coll2[key] === value) {
          collectionObj = coll2;
        }
      })
    })
  }
  return collectionObj;
}

export function timeConvert(n) {
  let hours = Number(n / 60).toFixed(2);
  return hours;
}

export const prepareStaffs = (response) => {
  let staffList = [];
  response.data.data.map((staff) => {
    staffList.push({
      value: staff.user_id,
      label: `${staff.first_name} ${staff.last_name}`,
    });
  });
  return staffList;
}

export function generateTimeSlots(startTime='00:00', endTime='24:00', slotInterval='10', returnValueFormat="HH:mm", returnLabelFormat="hh:mm A") {
  //Format the time
  let _startTime = moment(startTime, "HH:mm");
  //Format the end time and the next day to it 
  let _endTime = moment(endTime, "HH:mm");
  if(_endTime.isBefore(_startTime) ){
    _endTime.add(1, 'day');
  }
  //Times
  let allTimes = [];
  //Loop over the times - only pushes time with slotInterval
  while (_startTime < _endTime) {
    //Push times
    allTimes.push({value: _startTime.format(returnValueFormat), label: _startTime.format(returnLabelFormat)}); 
    //Add slotInterval
    _startTime.add(slotInterval, 'minutes');
  }
  return allTimes;
}

export function  getDataByvalueLable(data) {

  let servicesData = {};
  if(data) {
      servicesData = ({
      property_service_id : data?.property_service_id, 
      value : data['services']?.id, 
      label : data['services']?.name,
      default_tm : data?.default_tm_id,
      default_insp : data?.pm_inspector_id,
      default_price : data?.price,
      is_date_calc  : data['services']?.is_date_calc,
      pm_template  :  data?.pm_template,
    });
  }

  return servicesData;

}

export function  getKeyvalueLable(item) {

  let data = {};
  if(item) {
    if(item?.cleaner_id) {
      data = ({
        value : item?.cleaner_id, 
        label : item?.company_name,
      });
    }
    if(item?.id) {
      data = ({
        value : item?.id, 
        label : item?.name,
      });
    }
    
  }

  return data;

}

export const getSelectedStaffData = (value, staff = []) => {
  let staffData = {};
  if (staff.length) {
    staff.forEach((coll) => {
      if (coll.profession_type_id === value) {
          staffData.value = coll.Basic.user_id;
          staffData.label = coll.Basic.name;
      }
    })
  }
  return staffData;
}
export const getSelectedHK = (value, staff = []) => {
  let staffData = [];
  if (staff.length) {
    staff.forEach((coll) => {
      if (coll.profession_type_id === value) {
        staffData.push({
          value: coll.Basic.user_id,
          label: coll.Basic.name,
        });
      }
    })
  }
  return staffData;
}


export function  getCleanerServicevalueLableData(data) {

  let servicesData = {};
  if(data) {
      servicesData = ({
      value : data?.property_service_id, 
      property_service_id : data?.property_service_id, 
      label : data?.label,
      default_tm : data?.default_tm_id,
      default_insp : data?.default_pm_insp,
      default_price : data?.default_price,
      is_date_calc  : data?.is_date_calc,
      pm_template  :  data?.pm_template,
    });
  }

  return servicesData;

}

export const prepareStaffOptions = (response,labelName,regionArea=false) => {
  let staffList = [];

  let onDutyList = [{
    label: labelName,
    options: staffList,
  }]

  let notAvailable = {label: "Not Available", value: '', disabled: true}
  if(!Array.isArray(response)) return onDutyList;
  if(response.length==0 && labelName=="On Duty") { 
    staffList.push(notAvailable)
    return onDutyList;
  }
  if(labelName=="On Duty") {
    if(!regionArea) { 
    response.map((staff) => {
      staffList.push({
        value: staff.user_id,
        label: `${staff.full_name ?? staff.name}`,
      });
    })
    } else {
      response.map((staff) => {
      staffList.push({
        value: staff.user_id,
        label: `${staff.full_name ?? staff.name}`,
        userRegion: staff.userRegion,
        scheduleDate: staff.schedules_date
      });
    })}
  } else {
    onDutyList[0].options = response
  }
  
  return onDutyList;
}

export const getNextWeekDate = (dayName) => {
  let date = new Date();
  let now = date.getDay();
  let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  let day = days.indexOf(dayName.toLowerCase());
  let diff = day - now;
  diff = diff < 1 ? 7 + diff : diff;
  let nextDayTimestamp = date.getTime() + (1000 * 60 * 60 * 24 * diff);
  return new Date(nextDayTimestamp);
}

export const getWeekNumber = (date) => {
  let d = new Date(date);
  let dateInNum = d.getDate();
  let day = d.getDay();
  let weekNumber = Math.ceil((dateInNum + 6 - day)/7);
  let weekOfMonth = weekNumber - 1;
  return weekOfMonth;           
}

export const getDates = (date, day) => {
  var resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + (7 + day - date.getDay()) % 7);
  return resultDate;
}
export const dayOfWeekAsInteger = (day) => {
  return ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"].indexOf(day);
}

export const getWeekWiseDate = (day,count,from) => {
  let nextDay;
  if(from === 1){
      nextDay = day;
  }
  if(from === 2){
      nextDay = getNextWeekDate(day);
  }
  let currentTime = new Date(nextDay);
  currentTime.setDate(currentTime.getDate()+count * 7);
  return currentTime;
}

export const areObjectValuesEmpty = (object) => {
  return Object.values(object).every(x => x === null || x === '');
}

export const localTimezoneToUtc = (localDateTime, localTimezone) => {
  return moment(moment.tz(localDateTime, localTimezone)).utc();
}

export const utcTolocalTimezone = (utcDateTime, localTimezone) => {
  const utc = moment.tz(utcDateTime, "UTC");
  return utc.tz(localTimezone);
}

export const prepareStaffKeyValue = (collection) => {
  let staffList = [];
  collection.map((staff) => {
    staffList.push({
      value: staff.user_id,
      label: `${staff.name}`,
    });
  });
  return staffList;
}

export const prepareTemplatesKeyValue = (collection) => {
  let templList = [];
  collection.map((templ) => {
    templList.push({
      value: templ.template_id,
      label: `${templ.title}`,
    });
  });
  return templList;
}

export const prepareCheckListKeyValue = (collection) => {
  let templList = [];
  collection.map((templ) => {
    templList.push({
      value: templ.checklist_template_id,
      label: `${templ.template_title}`,
    });
  });
  return templList;
}

function getCommonProperty (selectedProp,allProperty){
  return allProperty.filter(function (item) {
    return selectedProp.some(function (p) {
      return p.value == item.value;
    });
  }).map(function (item) {
    return item;
  });
}

function uniqueProperty(properties) {
  let propertyArray = [];
  if(properties.length >0){
    properties.flat().map((act) => {
      if (!propertyArray.some(finalArr => finalArr['value'] === act.value)) {
        propertyArray.push(act);
      }
    })
  }
  return propertyArray
}


export const filterPropertyByCompany = async (selectedCompany, selectedProp, companyType) => {
  let companies = [];

  if (selectedCompany && Array.isArray(selectedCompany)) {
    selectedCompany?.map((company) => {
      companies.push(company.value);
    })
  } else if (selectedCompany) {
    companies.push(selectedCompany.value);
  }
  return await filterPropertyOptions(companyType, companies).then((properties) => {
    let finalActiveProperty = (properties) ? properties[0]?.options : [];
    let finalInActiveProperty = (properties) ? properties[1]?.options : [];

    if (finalInActiveProperty && finalActiveProperty) {
      let allProperties = [...finalActiveProperty, ...finalInActiveProperty];
      let commonProp = getCommonProperty(selectedProp, allProperties);

      selectedProp = commonProp;
    }


    return {
      "filterOptions": [
        {
          label: 'Active Properties',
          options: finalActiveProperty
        },
        {
          label: 'Inactive Properties',
          options: finalInActiveProperty
        },
      ],
      "selectedProperty": selectedProp,
    }

  })

}
 
export const filterHkByRegionAreas = (hkList, regionId, areaId) => {
  let finalHkList = [];

  if (hkList) {
    hkList.map((hk, index) => {
      if (hk.regionAreas) {
        if (isRegionExists(hk.regionAreas, regionId) && isAreaExists(hk.regionAreas, areaId)) {
          finalHkList.push({ 'value': hk.user_id, 'label': hk.name });
        } else if (isRegionExists(hk.regionAreas, regionId) && !isValue(areaId)) {
          finalHkList.push({ 'value': hk.user_id, 'label': hk.name });
        } else if (isAreaExists(hk.regionAreas, areaId) && !isValue(regionId)) {
          finalHkList.push({ 'value': hk.user_id, 'label': hk.name });
        }

      }

    })
  }
  return finalHkList;
}

export const convertToDecimal = (num) => {
  return Number(num) ? Number(num).toFixed(2) : "0.00";
}

export const convertToHoursMinutes = (time) => {
  return moment(convertTime(time)).format('HH:mm')
}

export const filterPropertyByCompanyID = (selectedCompany, selectedProp, propertyOptions, companyType) => {
  let companies = [];

  if (selectedCompany && Array.isArray(selectedCompany)) {
    selectedCompany?.map((company) => {
      companies.push(companyType === "cleaner" ? company.value : `${company.value}`);
    })
  } else if (selectedCompany) {
    companies.push(companyType === "cleaner" ? selectedCompany.value : `${selectedCompany.value}`);
  }

    let finalActiveProperty = [];
    let finalInActiveProperty = [];

    if(companies.length > 0) {
      propertyOptions[0]?.options.forEach((properties) => {
        if(companyType === "cleaner" && companies.includes(properties.manager_id)) {
          finalActiveProperty.push({
            value: properties.value,
            label: `${properties.label}${properties?.unit_no ? properties?.unit_no : ''}`
          });
        } else if(companyType === "manager" && companies.some(cl => properties.cleaner_ids.includes(cl))) {
          finalActiveProperty.push({
            value: properties.value,
            label: `${properties.label}${properties?.unit_no ? properties?.unit_no : ''}`
          });
        }
      });
      propertyOptions[1]?.options.forEach((properties) => {
        if(companyType === "cleaner" && companies.includes(properties.manager_id)) {
          finalInActiveProperty.push({
            value: properties.value,
            label: `${properties.label}${properties?.unit_no ? properties?.unit_no : ''}`
          });
        } else if(companyType === "manager" && companies.some(cl => properties.cleaner_ids.includes(cl))) {
          finalInActiveProperty.push({
            value: properties.value,
            label: `${properties.label}${properties?.unit_no ? properties?.unit_no : ''}`
          });
        }
      });
    } else {
      propertyOptions[0]?.options.forEach((properties) => {
        finalActiveProperty.push({
          value: properties.value,
          label: `${properties.label}${properties?.unit_no ? properties?.unit_no : ''}`
        });
      });
      propertyOptions[1]?.options.forEach((properties) => {
        finalInActiveProperty.push({
          value: properties.value,
          label: `${properties.label}${properties?.unit_no ? properties?.unit_no : ''}`
        });
      });
    }

    if (finalInActiveProperty && finalActiveProperty) {
      let allProperties = [...finalActiveProperty, ...finalInActiveProperty];
      let commonProp = getCommonProperty(selectedProp, allProperties);

      selectedProp = commonProp;
    }


    return {
      "filterOptions": [
        {
          label: 'Active Properties',
          options: finalActiveProperty
        },
        {
          label: 'Inactive Properties',
          options: finalInActiveProperty
        },
      ],
      "selectedProperty": selectedProp,
    }

  // })
}

export function removeHtmlTags(str) {
  if ((str===null) || (str===''))
      return "";
  else
      str = str.toString();
        
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}

export const CapitalizeFirstLetter = (str) => {

  // Convert your string's first character into upper case  
  // e.g. hello world!
  // after conversion : Hello World!

  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

const HHS_IDS = {
  qa: [24792],
  dev:[24792],
  staging: [24792],
  prodtest: [24792],
  prod: [24792],
  preLive: [24792],
  betatest: [24792],
};
export const getHHSIds = () => {
  return HHS_IDS[process.env.REACT_APP_ENV];
}


const DISABLE_ADDITIONAL_FEATURE_USER_ID = {
  qa: '',
  staging: 23328,
  prod:26602,

};
export const getCleanerID = () => {
  return DISABLE_ADDITIONAL_FEATURE_USER_ID[process.env.REACT_APP_ENV];
}

export const strictFilterByInput = (option, inputValue) => {
  const { label, value } = option;
  const firstWord = inputValue.toLowerCase();
  const formatLabel = label.toLowerCase();
  return inputValue ? formatLabel.startsWith(firstWord) : option;
};

export const onlyFormatNumber = (num) => {
  let formatter = new Intl.NumberFormat('en-US');
    return formatter.format(num);
}

export const labelWithCurrency = (label, currency) => {
  if (currency?.hasOwnProperty('symbol')) {
    return label + ' (' + currency.symbol + ')';
  }
  return label;
}

export const getTimeDifferenceBetweenDates = (dateTime1, dateTime2, format='YYYY-MM-DD HH:mm:ss') => {
  const moment1 = moment(dateTime1, format);
  const moment2 = moment(dateTime2, format);
  const duration = moment.duration(moment2.diff(moment1));
  const hours = duration.hours();
  const minutes = Math.round(duration.asMinutes());
  const seconds = duration.seconds();
  return {hours, minutes, seconds};
}
export const camelize=(str)=> {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toUpperCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export const propertyTypesDropdown = (propertyTypesList,excludedPropertyTypes) => {

  let newPropertyTypes = propertyTypesList
  newPropertyTypes = propertyTypesList.filter((item) => {
    const itemName = item?.label?.toLowerCase();

    return !excludedPropertyTypes.includes(itemName);
});
return newPropertyTypes;
}

export const RESET_CREDENTIALS_TIME =3; 

export const isDateBetween = (dateString) => {
  const tomorrow = moment();
  const sevenDaysFromNow = moment().add(7, 'days');
  const departureDate = moment(dateString);
  return departureDate.isBetween(tomorrow, sevenDaysFromNow, null, '[]');
};

export const getIntegrationName = (integrationId) => {
  switch (integrationId) {
    case 8:
      return "Itrip";
    case 28:
      return "Barefoot";
    case 17:
      return "Streamline";
    default:
      break
  }
}

export const getDynamicWeekdayDate = (date,selectedWeekForDay,selectedDay) => {
  const weekNumber = selectedWeekForDay?.value;
  const weekday = selectedDay?.value;
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  let offset = (weekday - firstDayOfMonth.getDay() + 7) % 7 + (7 * (weekNumber - 1));
  if (offset < 0) {
      offset += 7;
  }
  const dynamicWeekdayDate = new Date(firstDayOfMonth);
  dynamicWeekdayDate.setDate(firstDayOfMonth.getDate() + offset);
  return dynamicWeekdayDate;      
}

export const isSameYearSelected = (firstYear,secondYear) => {
    return (moment(firstYear).year() == moment(secondYear).year());
}      

export const prepareUrlWithFilter = (url, filter) => {
  for (const key in filter) {
    if (filter?.hasOwnProperty(key) && filter[key] && typeof (filter[key]) === "object") {
      var objLength = filter[key].length;
      if (objLength > 0) {
        url += `&filter[${key}]=`
        for (var i = 0; i < objLength; i++) {
          if (i < objLength - 1) {
            url += `${filter[key][i]['value']},`;
          } else {
            url += `${filter[key][i]['value']}`;
          }
        }
        url += `&`
      }
    }
    else if (filter?.hasOwnProperty(key) && ((filter[key] && filter[key] !== '') || filter[key] === 0)) {
      url += `&filter[${key}]=${filter[key]}&`;
    }
  }
  return url;
}

export const formatInventoryValue = (value) => {
  if (typeof value === 'string' && value.includes('/')) {
    const [quantity, resupply] = value.split('/');
    return (
      <>
        <div>{quantity}</div>
        <div>{resupply}</div>
      </>
    );
  }
  return value;
};

export const smsAdditionalFeatureId = ()=> {
  if (process.env.REACT_APP_ENV == 'prod') {
    return 38;
  }
  return 39;
}

export const calculateTimeDifference = (start, end) => {
  if(start && end) { 
    var startTime = start?.split(":");
    var startDate = new Date();
    startDate?.setHours(startTime[0]);
    startDate?.setMinutes(startTime[1]);

    var endTime = end?.split(":");
    var endDate = new Date();
    endDate?.setHours(endTime[0]);
    endDate?.setMinutes(endTime[1]);

    const diff = endDate - startDate;

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}:${minutes.toString().padStart(2, "0")} hrs`;  
  }
  return '0:00 hrs';
 };

 export const getCardIcon = (cardType) => {
  const cardIcons = {
      visa: VisaCard,
      mastercard: MasterCard,
      'american express': AmexCard,
      jcb: JcbCard,
      discover: DiscoverCard,
      'diners club': DinersClubCard,
      'amex' : AmexCard,
  };

  return cardIcons[cardType.toLowerCase()] || null;
};

export const convertToZip = (str) => {
  if (/[a-zA-Z]/.test(str)) {
    const numericPart = str.replace(/[a-zA-Z\s]/g, '');
    return numericPart.padEnd(5, '0');
  }
  
  return str;
};
