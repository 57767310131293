import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { LanguageContext } from "../../../../containers/Language";
import "react-datepicker/dist/react-datepicker.css";
import InputText from "../../../../atoms/InputText";
import { getDcpBaselineRoles } from "../../../../../redux/DailyCloseout/action";
import { NumberFractionFmt, convertToDecimal } from "../../../../../utils/utils";

const BaselineRoles = ({
    selectedPropertyManagerId,
    payload,
    setPayload,
}) => {

    const { localised } = useContext(LanguageContext);

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.global.loading);
    const dcpBaselineRoles = useSelector((state) => state?.DailyCloseout?.dcpBaselineRoles);

    const [baselineRoles, setBaselineRoles] = useState();
    const [totalHours, setTotalHours] = useState(0.00);

    useEffect(() => {
        if (selectedPropertyManagerId) {
            getData();
        } else {
            clearData();
        }
    }, [selectedPropertyManagerId]);

    useEffect(() => {
        if(dcpBaselineRoles) {
            let _data = [...dcpBaselineRoles];
            _data = _data.map((row, index) => {
                return {
                    baseline_role_id: row?.baselineRole?.baseline_role_id,
                    title: row?.baselineRole?.title,
                    rate: Number(row?.rate),
                    hours: convertToDecimal(0.00)
                };
            });
            setBaselineRoles(_data);
        }
    }, [dcpBaselineRoles]);

    useEffect(() => {
        if(baselineRoles) {
            let _totalHours = convertToDecimal(baselineRoles?.reduce((a, b) => (+a) + (+b.hours), 0));
            setTotalHours(_totalHours);
            let _payload = {
                roles: JSON.parse(JSON.stringify(baselineRoles)),
                total_baseline_hours: _totalHours
            };
            setPayload({
                ...payload,
                baseline: {..._payload},
            });
        }

    }, [baselineRoles]);

    function handleHoursChange(event, index) {
        let _data = [...baselineRoles];
        _data[index]['hours'] = event.target.value;
        setBaselineRoles(_data);
    }

    function getData() {
        dispatch(getDcpBaselineRoles({ manager_id: selectedPropertyManagerId }));
    }

    function clearData() {
        dispatch({ type: "CLEAR_DCP_BASELINE_ROLES" });
    }

    return (
        <>
            <Table bordered>
                <thead>
                    <tr>
                        <th>{localised.baselinePosition} </th>
                        <th>{localised.hourse}</th>
                    </tr>
                </thead>
                <tbody>
                    {baselineRoles?.length == 0 && (
                        <tr>
                            <td colSpan="2">
                                <div className="d-flex justify-content-center m-1">
                                    {!isLoading ? localised.noRecordsFound : localised.pleaseWait}
                                </div>
                            </td>
                        </tr>                    
                    )}
                    {baselineRoles?.map((row, index) => {
                        return (
                            <tr key={index}>
                                <td data-title={localised.baselinePosition}>
                                    {row?.title}
                                </td>
                                <td data-title={localised.hourse}>
                                    <InputText
                                        required = {"required"}
                                        id = {`hours-${index}`}
                                        name = {`hours-${index}`}
                                        type = "text"
                                        placeholder = {'0.00'}
                                        value = {row?.hours}
                                        onChange = {(e) => handleHoursChange(e, index)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tbody>
                    <tr>
                        <th data-title={localised.total} className="text-end">
                            {localised.total}
                        </th>
                        <th data-title={localised.hourse}>{NumberFractionFmt(totalHours)}</th>
                    </tr>
                </tbody>
            </Table>
        </>
    );
}

export default BaselineRoles;