const initState = {
  service:[],
  serviceList:[],
  serviceChecklistTemplate:{},
  checklistItem:[]
};

const service = (state = initState, action) => {

  switch (action.type) {

    case "SET_SERVICE_LIST": {
      return {
        ...state,
        serviceList: action.payload,
      }
    }
    case "CLEAR_SERVICE_LIST": {
      return {
        ...state,
        serviceList: null,
      }
    }

    
    case "SET_AUTOMATION_SERVICE_LIST": {
      return {
        ...state,
        serviceAutomationList: action.payload,
      }
    }
    case "CLEAR_AUTOMATION_SERVICE_LIST": {
      return {
        ...state,
        serviceAutomationList: null,
      }
    }
    
    case 'SET_CHECKLIST_DATA': {
      return {
        ...state,
        cheklistTemplate : action.payload,
      };
    }
    case 'CLEAR_CHECKLIST_DATA': {
      return {
        ...state,
        cheklistTemplate : [],
      };
    }

    case "SET_CHECKLIST_TEMPLATE_DETAILS":{
      return{
        ...state,
        serviceChecklistTemplate: action.payload.data
      }
    }

    case "CLEAR_CHECKLIST_TEMPLATE_DETAILS":{
      return{
        ...state,
        serviceChecklistTemplate:{}
      }
    }
    
    case 'SET_SERVICE_CHECKLIST_TEMPLATE_ITEMS':{
      return{
        ...state,
        checklistItem: action.payload.data
      }
    }

    case 'CLEAR_SERVICE_CHECKLIST_TEMPLATE_ITEMS': {
      return {
        ...state,
        checklistItem : [],
      };
    }
       
    case "SET_SERVICE_MANAGER_LIST": {
      return {
        ...state,
        serviceManagerList: action.payload,
      }
    }

    case "SET_AUTOMATION_MANAGER_SERVICE_LIST": {
      return {
        ...state,
        serviceManagerAutoList: action.payload,
      }
    }

    case "SET_HHS_MANAGERS": {
      return {
        ...state,
        hhsManagerList: action.payload,
      }
    }

    case "CLEAR_HHS_MANAGERS": {
      return {
        ...state,
        hhsManagerList: [],
      }
    }

    case "CLEAR_SERVICE_MANAGER_LIST": {
      return {
        ...state,
        serviceManagerList: [],
      }
    }

    case 'SET_CHECKLIST_PHOTOS':{
      return{
        ...state,
        checklistPhotos: action.payload,
      }
    }

    case 'CLEAR_CHECKLIST_PHOTOS': {
      return {
        ...state,
        checklistPhotos: []
      };
    }

      default:
        return state;
}
};

export default service;