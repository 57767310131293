import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { LanguageContext } from "../../../../containers/Language";
import "react-datepicker/dist/react-datepicker.css";
import InputText from "../../../../atoms/InputText";
import Buttons from "../../../../atoms/Buttons";
import { FiMinus, FiPlus } from "react-icons/fi";
import { NumberFractionFmt, convertToDecimal, isEmptyNullUndefined } from "../../../../../utils/utils";


const initSupplementalRow = {
    title: "",
    hours: convertToDecimal(0.00),
};

const initSupplementalList = [initSupplementalRow];

const SupplementalHours = ({
    payload,
    setPayload,
}) => {


    const { localised } = useContext(LanguageContext);

    const isLoading = useSelector((state) => state.global.loading);

    const [supplementalList, setSupplementalList] = useState(initSupplementalList);
    let [totalHours, setTotalHours] = useState(convertToDecimal('0.00'));

    function handleTitleChange(event, index) {
        let _data = JSON.parse(JSON.stringify(supplementalList));
        _data[index]['title'] = event.target.value;
        setPayload({
            ...payload,
            supplemental: {
                ...payload?.supplemental,
                list: _data,
            },
        })
        setSupplementalList(_data);
    }

    function handleHousChange(event, index) {
        let _data = JSON.parse(JSON.stringify(supplementalList));
        _data[index]['hours'] = event.target.value;
        _data[index]['title'] = isEmptyNullUndefined(_data[index]['title']) ? '-' : _data[index]['title'];
        let _totalHours = _data.filter((row, index) => row?.removed != 1)
            .reduce((a, b) => (+a) + (+b.hours), 0)
        ;
        setPayload({
            ...payload,
            supplemental: {
                ...payload?.supplemental,
                list: _data,
                total_supplemental_hours: _totalHours,
            },
        })
        setSupplementalList(_data);
        setTotalHours(_totalHours);
    }

    function addRow() {
        let _data = JSON.parse(JSON.stringify(supplementalList));
        _data.push(initSupplementalRow);
        setSupplementalList(_data);
    }

    function removeRow(event, index) {
        let _data = JSON.parse(JSON.stringify(supplementalList));
        _data[index] = {..._data[index], removed: 1};
        let _totalHours = _data.filter((row, index) => row?.removed != 1)
            .reduce((a, b) => (+a) + (+b.hours), 0)
        ;
        setPayload({
            ...payload,
            supplemental: {
                ...payload?.supplemental,
                list: _data,
                total_supplemental_hours: _totalHours,
            },
        })
        setSupplementalList(_data);
        setTotalHours(_totalHours);
    }

    return (
        <>
            {
                supplementalList?.map((row, index) => {
                    if(row?.removed != 1) {
                        return (
                            <div key = {index} className="daily-mutli-rows d-flex">
                                <div className="input-daily">
                                    <InputText
                                        id={`title-${index}`}
                                        name={`title-${index}`}
                                        type={"text"}
                                        placeholder={""}
                                        required={"required"}
                                        value = {row?.title}
                                        onChange = {(e) => handleTitleChange(e, index)}
                                    />
                                </div>
                                <div className="input-daily">
                                    <InputText
                                        id={`hours-${index}`}
                                        name={`hours-${index}`}
                                        type={"text"}
                                        placeholder={"0.00"}
                                        required={"required"}
                                        value = {row?.hours}
                                        onChange = {(e) => handleHousChange(e, index)}
                                    />
                                </div>
                                <div className="input-daily-btn">
                                    <Buttons 
                                        variant = "outline-primary" 
                                        className = "w-auto"
                                        onClick = {addRow}
                                    >
                                        <FiPlus />
                                    </Buttons>
                                    {index != 0 && (
                                        <Buttons 
                                            variant = "outline-danger"
                                            className = "w-auto ms-2"
                                            onClick = {(e) => removeRow(e, index)}
                                        >
                                            <FiMinus />
                                        </Buttons>
                                    )}
                                </div>
                            </div>
                        )    
                    }
                })
            }
            <div className="daily-mutli-rows d-flex border-top-1 pt-2">
                <div className="input-daily-total text-end">
                    <span className="ms-3">{localised.total}</span> 
                    <span className="ms-3"><strong>{NumberFractionFmt(totalHours)}</strong></span>
                </div>
                <div className="input-daily-btn">
                </div>
            </div>
        </>
    );
}

export default SupplementalHours;
