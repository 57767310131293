import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Table } from "react-bootstrap";
import { LanguageContext } from "../../../../containers/Language";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { format } from "crypto-js";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const ASC = 'asc';
const DESC = 'desc';

const AppointmentTable = ({
    type,
    columns,
    data,
    setData,
}) => {

    const { localised } = useContext(LanguageContext);

    const isLoading = useSelector((state) => state.global.loading);
    const dateFormat = useSelector((state) => state.authentication.profile.date_format);
    const [selectedAll, setSelectedAll] = useState(false);
    const [sortingObj, setSortingObj] = useState({
        column: '',
        order: '',
    });
    
    function handleSelectAll(event) {
        let _data = [];
        if (event.target.checked) {
            setSelectedAll(true);
            _data = data.map((row, index) => {
                return {...row, isChecked: true};
            })
        } else {
            setSelectedAll(false);
            _data = data.map((row, index) => {
                return {...row, isChecked: false};
            })
        }
        setData(_data);
    }

    function handleSingleSelect(event, index) {
        let _data = [...data];
        if (event.target.checked) {
            _data[index]['isChecked'] = true;
            if(_data.find((item, index) => item?.isChecked == false) == undefined) {
                setSelectedAll(true);
            }
        } else {
            _data[index]['isChecked'] = false;
            setSelectedAll(false);
        }
        setData(_data);
    }

    function handleSorting(event, column) {
        if (data?.length) {
            let _data;
            if(sortingObj.column != column || sortingObj.order == '' || sortingObj.order == DESC ) {
                setSortingObj({
                    column,
                    order: ASC,
                })
                _data = sortByAsc(column);
            }
            else if(sortingObj.order == ASC) {
                setSortingObj({
                    column,
                    order: DESC,
                })
                _data = sortByDesc(column);
            }
            setData(_data);
        }
    }

    function sortByAsc(column) {
        let _data = [...data];
        return _data?.sort((a, b) => {
            switch (column) {
                case 'property_name':
                case 'service': {
                    let x = a[column].trim().toLowerCase();
                    let y = b[column].trim().toLowerCase();
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                }
                default: {
                    let x = a[column]; 
                    let y = b[column];
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                }
            }    
        });
    }

    function sortByDesc(column) {
        let _data = [...data];
        return _data?.sort((a, b) => {
            switch (column) {
                case 'property_name':
                case 'service': {
                    let x = a[column].trim().toLowerCase();
                    let y = b[column].trim().toLowerCase();
                    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
                }    
                default: {
                    let x = a[column]; 
                    let y = b[column];
                    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
                }
            }    
        });
    }

    return (
        <div className="gridjs status-center-table content p-0 grid-content no-action-column" id="no-more-tables">
            <div className="custom-table-header d-block">
                <div className=" roll-appts-table">
                    <Table responsive className="mb-0">
                        <thead>
                            <tr key="table-row-headings">
                                {columns?.map((column, index) => {
                                    return (
                                        ((!column.hidden) && (
                                            <>
                                            {column?.id == 'select_all' && (
                                                <th className="f-cl-width">
                                                    <div className="d-flex justify-content-center">
                                                        <Form.Check 
                                                            type="checkbox" 
                                                            name='checkall' 
                                                            id='inline-radio-1' 
                                                            checked={selectedAll}
                                                            onChange={handleSelectAll}
                                                        />
                                                    </div>
                                                </th>
                                            )}
                                            {column?.id != 'select_all' && (
                                                <th>
                                                    <div className='d-flex justify-content-between align-items-center cursor'
                                                        onClick={(e) => handleSorting(e, column.sort)}
                                                    >
                                                        {column.name}
                                                        {
                                                            column?.sort && (
                                                                <div>
                                                                    <span>
                                                                        {sortingObj?.column == column.sort && sortingObj?.order == DESC ? <FiChevronUp /> : <FiChevronDown />}
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </th>
                                            )}
                                            </>
                                        ))
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length == 0 && (
                                <tr>
                                    <td colSpan="9">
                                        <div className="d-flex justify-content-center m-1">
                                            {!isLoading ? localised.noRecordsFound : localised.pleaseWait}
                                        </div>
                                    </td>
                                </tr>
                            )} 
                            {data?.map((row, index) => {
                                if (row?.isCompleted != true) {
                                    return (
                                        <tr key={`${index+1}`}>
                                            <td data-title={localised.select}>
                                                {(
                                                    <div className="s-column d-flex">
                                                        <Form.Check type="checkbox" name={`inline-check-${index+1}`} id={`inline-check-${index+1}`} 
                                                            value={row?.appointment_id}
                                                            checked={row?.isChecked} 
                                                            onChange={(e) => handleSingleSelect(e, index)} 
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                            <td data-title={localised.appt}>
                                                <div>{row?.appointment_id}</div>
                                            </td>
                                            <td data-title={localised.unit}>
                                                <div>{row?.property_name}{(row?.unit_no) && - row?.unit_no}</div>
                                            </td>
                                            <td data-title={localised.service}>
                                                <div>{row?.service}</div>
                                            </td>
                                            <td data-title={localised.apptDate}>
                                                <div>{row?.departure_date ? moment(row.departure_date).format(dateFormat) : ""}</div>
                                            </td>
                                        </tr>
                                    );    
                                }
                            })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default AppointmentTable;