import Keycloak from 'keycloak-js'
import { adminKeyclockConfig, webKeyclockConfig } from './config/global';

let keyclockConfig = null;
const currentURL = window.location.href;
if(currentURL.includes(':3001') || currentURL.includes('rcadmin') ||  currentURL.includes('admin.')) {
    keyclockConfig = adminKeyclockConfig;
} else {
    keyclockConfig = webKeyclockConfig;
}
const keycloak = new Keycloak(keyclockConfig);
export default keycloak;
