import OneSignalReact from "react-onesignal";
import keycloak from "../../keycloak";
import { deleteAllCookies, getRedirectURI, getKeycloakClient } from "../../utils/utils";
import { userImpersonateLogout } from "../user/action";

export const login = (data) => async (dispatch, getState) => {
}


export const logout = (data) => (dispatch, getState) => {
    const state = getState().authentication;
    const loggedInUser = state.profile ? state.profile : "";
    const { clientId, refreshToken } = state.keycloak;
    //Impersonate logout start 29-oct-2024
    if (state.isProxy && clientId && refreshToken) {
        let formData = {
            client_id: clientId,
            refresh_token: refreshToken,
            user_id: loggedInUser.user_id
        }
        dispatch(userImpersonateLogout({ formData}));
    }
    //Impersonate logout end 29-oct-2024
    let redirectUri = getRedirectURI();
    dispatch({type:'LOG_OUT'});
    OneSignalReact.removeExternalUserId();
    localStorage.clear();
    sessionStorage.clear();
    console.log("Keycloak Authenticated:", keycloak.authenticated);
    keycloak.logout({ redirectUri });
    // keycloak.logout({redirectUri: redirectUri});
}
