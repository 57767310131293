import { useState } from 'react';

export const useVisibility  = (initialVisible= false) => {
  const [isVisible, setIsVisible] = useState(initialVisible);

  const toggle = () => {
    setIsVisible(!isVisible);
  }
  
  const show = () => {
    setIsVisible(true);
  }
  
  const hide = () => {
    setIsVisible(false);
  }
  
  const set = (value) => {
    setIsVisible(value);
  }

  return {
    isVisible,
    set,
    show,
    hide,
    toggle,
  }
}