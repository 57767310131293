import React, {useContext, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Dropdown, Modal } from 'react-bootstrap';
import {
  FiAlignLeft,
  FiX,
  FiUsers,
  FiUser,
  FiCreditCard,
  FiLogOut,
  FiHelpCircle,
  FiAlignJustify,
  FiFilter,
  FiMail,
  FiLock,
  FiPhone
} from "react-icons/fi";
import { MdOutlineAssignmentReturn } from 'react-icons/md';
import { BsClipboardCheck, BsCardChecklist } from "react-icons/bs";
import { HiLanguage} from "react-icons/hi2";
import Logo from './../../../../assets/img/brand.png';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../redux/authentication/action';
import { getRole } from '../../../../utils/utils';
import ProfileIcon from '../../../atoms/ProfileIcon';
import { roles } from '../../../../config/global';
import RegionFilter from '../../../molecules/RegionFilter';
import { LanguageContext } from '../../../containers/Language';
import LanguageSelector from '../../../core/LanguageSelector';
import SearchSupportModal from '../../../molecules/SearchSupportModal';
import CustomModal from '../../../molecules/CustomModal';
import InventorySmartLogo from './../../../../assets/img/is-icon.png';
import Buttons from '../../../atoms/Buttons';
import DailyCloseoutModal from '../../../molecules/Cleaner/DailyCloseoutModal';
import {useVisibility} from "../../../../hooks/useVisibility";
import {PauseSmsNotificationsModal} from "../../../molecules/Notifications/PauseSmsNotificationsModal";
import {AiOutlineMobile} from "react-icons/ai";
import {useProfile} from "../../../../hooks/useProfile";
import {AdditionalFeatureNames} from "../../../../utils/additionalFeatureUtil";
import moment from "moment";

const CleanerHeader = ({ 
  hideComponent, 
  isBoolean, 
  hideSidebarComponent, 
  headerDeviceMenu, 
  toggelHeaderDiviceMenu,
  showRegionFilterModal,
  toggleRegionFilterModal }) => {

  const { localised } = useContext(LanguageContext);
  
  const {hasAccessToFeature} = useProfile();
  const isAdditionalFeatureSmsEnabled = hasAccessToFeature(AdditionalFeatureNames.SMS);
  const isSmsMessagingAdditionalFeatureEnabled = hasAccessToFeature(AdditionalFeatureNames.SMSMessaging);
  const userDetails = useSelector((state) => state.user.user);
  const isUserSmsEnabled = userDetails?.is_sms_enabled === true;
  const isUserSmsPaused = userDetails?.sms_paused_until && new Date(userDetails?.sms_paused_until) > new Date();
  const profile = useSelector((state) => state.authentication.profile) || '';
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };
  const loggedInUser = useSelector((state) => state.authentication?.profile) || [];
  const isProxy = useSelector((state) => state.authentication?.isProxy) || false;
  const isLocked = (loggedInUser?.role == 'CL' && loggedInUser?.status_id === 4) || (loggedInUser?.role == 'ST' && loggedInUser?.companyData?.company_type_id == 3 && loggedInUser?.owner_status_id === 4);
  const sidebarCount = useSelector((state) => state.company.sidebarCount) || '';
  const headerCount = useSelector((state) => state.company.headerCount) || '';
  const callbackCount = useSelector((state) => state.inspection.callbackCount) || 0;
  const inventorySmartCredential = useSelector((state) => state.InventorySmart.inventorySmartCredential) || '';
  const [showSearchSupportModal, setShowSearchSupportModal] = useState(false);
  const toggleSearchSupportModal = () => setShowSearchSupportModal(!showSearchSupportModal);

  let supportTaskAdditionalFeature = profile.additional_feature_access.some(item => item.additional_features_id === 3);
  let subTasksAdditionalFeature = profile.additional_feature_access.some(item => item.additional_features_id === 11);
  let inspectionAdditionalFeature = profile.additional_feature_access.some((item) => item.additional_features_id === 1); 
  let dcpAdditionalFeature = profile.additional_feature_access.some((item) => item.additional_features_id === 14); 
  let inventorySmartAdditionalFeature = loggedInUser?.additional_feature_access?.some((item) => item.additional_features_id === 17);

  const handleSubmit = () => {}

  const [showDcpModal, setShowDcpModal] = useState(false);
  const toggleDcpModal = () => { setShowDcpModal(!showDcpModal); }

  const {isVisible: showPauseSmsModal, show: openPauseSmsModal, hide: hidePauseSmsModal} = useVisibility();

  return (

    <><div className="container-fluid">
      <div className='header-icon'>

        {!isBoolean ? (
          <FiX onClick={hideComponent} />
        ) : (
          <FiAlignLeft onClick={hideComponent} />
        )}
      </div>
      <div className='mobile-center-logo text-center d-md-none'>
          <img src={Logo} />
        </div>

      {/* <ul
        className={headerDeviceMenu ? 'navbar-nav-icons ms-auto flex-row align-items-center navbar-nav' : 'navbar-nav-icons ms-auto flex-row align-items-center navbar-nav show-header-menu'}
      > */}

      <ul className='navbar-nav-icons ms-auto flex-row align-items-center navbar-nav show-header-menu'>
 {/* <li className='language-select'>
          <LanguageSelector />
        </li> */}
        {(isProxy || !isLocked) && dcpAdditionalFeature && (
          <li className='nav-item daily-closeout-li'>
            <Buttons variant="primary" title={localised.dailyCloseoutProcess} onClick={toggleDcpModal} className="btn-sm mx-2"/>
          </li>
        )}
        {(isProxy || !isLocked) && <li className='nav-item'>
          <NavLink to="#" title={localised.regionFilter} onClick={toggleRegionFilterModal}>
            <FiFilter />
          </NavLink>
        </li>}

        {(loggedInUser?.resource_access?.inspection?.roles?.includes('manage_inspection') && inspectionAdditionalFeature && (isProxy || !isLocked) ) && <li className='nav-item billing'>
          <NavLink to="/cleaner-inspections?callback=1" title={localised.callback}>
            <MdOutlineAssignmentReturn />
            {callbackCount > 99 ? <span className="badge bg-danger header-badge">99+</span> :  <span className="badge bg-danger header-badge">{callbackCount}</span>}

            {/* <span className="badge bg-danger header-badge">{callbackCount}</span> */}
          </NavLink>
        </li>}
       {((loggedInUser?.resource_access?.appointments?.roles?.includes('manage_service_request')) && supportTaskAdditionalFeature && (isProxy || !isLocked)) && <li className='nav-item billing'>
          <NavLink to="/cleaner-service-request" title={localised.serviceRequests}>
            <BsClipboardCheck />
            {sidebarCount?.serviceRequest > 99 ? <span className="badge bg-danger header-badge">99+</span> :  <span className="badge bg-danger header-badge">{sidebarCount?.serviceRequest}</span>}

            {/* <span className="badge bg-danger header-badge">{sidebarCount?.serviceRequest}</span> */}
          </NavLink>
        </li>}
        {(loggedInUser?.resource_access?.appointments?.roles?.includes('manage_subtask') && subTasksAdditionalFeature && (isProxy || !isLocked)) && <li className='nav-item billing'>
          <NavLink to="/cleaner-subtasks?status=pending&pm_status=approved" title={localised.subtasks}>
            <BsCardChecklist />
            {sidebarCount?.headerSubtask > 99 ? <span className="badge bg-danger header-badge">99+</span> :  <span className="badge bg-danger header-badge">{sidebarCount?.headerSubtask}</span>}

            {/* <span className="badge bg-danger header-badge">{sidebarCount?.headerSubtask}</span> */}
          </NavLink>
        </li>}
        {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_rc_invoice') && (isProxy || !isLocked)) && <li className='nav-item billing'>
          <NavLink to="/cleaner-rc-invoices" title={localised.rcInvoices}>
          <FiCreditCard />
          {sidebarCount?.rcInvoice > 99 ? <span className="badge bg-danger header-badge">99+</span> :  <span className="badge bg-danger header-badge">{sidebarCount?.rcInvoice}</span>}

            {/* <span className="badge bg-danger header-badge">{sidebarCount?.rcInvoice}</span> */}
          </NavLink>
        </li>}

        {(isProxy || !isLocked) && <li className='nav-item'>
          <NavLink to="/cleaner-messages" title={localised.message}>
            <FiMail />
            {headerCount?.unreadCount > 99 ? <span className="badge bg-danger header-badge">99+</span> :  <span className="badge bg-danger header-badge">{headerCount?.unreadCount}</span>}
            {/* <span className="badge bg-danger header-badge">{headerCount?.unreadCount}</span> */}
          </NavLink>
        </li>}

        {isSmsMessagingAdditionalFeatureEnabled && <li className='nav-item'>
          <NavLink to="/cleaner-sms-inbox" title={localised.inbox}>
            <AiOutlineMobile />
          </NavLink>
        </li>}
       
        <li className='nav-item profile-menus'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <ProfileIcon profile={profile}></ProfileIcon>
            </Dropdown.Toggle>

            <Dropdown.Menu className="animation slideDown">
              <div className='d-user-wrap'>
                <div className='main-img-user'>
                  <ProfileIcon profile={profile}></ProfileIcon>
                </div>
                <div className="ms-3 my-auto">
                  <h6 className="tx-15 font-weight-semibold mb-0">{profile.name}</h6>
                  <span className="dropdown-title-text subtext op-6 tx-12">{profile.companyData.company_name}</span>
                  {
                    (inventorySmartCredential?.active && inventorySmartAdditionalFeature)
                      && (<div className="d-inline-block ms-2 appointment-list-icons">
                        <span className="is-icon">
                          <img src={InventorySmartLogo} />
                        </span>
                      </div>)
                  }
                </div>
              </div>

              {(isProxy || !isLocked) && 
                <>
                  {
                    (profile.is_owner == 1) && 
                    <NavLink to="/cleaner-my-account">
                      <span className='menu-icon'><FiUser /></span> <span className='menu-icon-label'>{localised.myAccount}</span>
                    </NavLink>
                  }
                  {
                    (profile.is_owner == 0) && 
                    <NavLink to="/cleaner-staff-account">
                      <span className='menu-icon'><FiUsers /></span> <span className='menu-icon-label'>{localised.staff}- {localised.myAccount}</span>
                    </NavLink>
                  }
                </>
              }

              {
                <NavLink to="#" className="d-flex align-items-center" onClick={() => isAdditionalFeatureSmsEnabled && isUserSmsEnabled && openPauseSmsModal()}>
                  <div className='menu-icon'><AiOutlineMobile/></div>
                  <div className='d-flex flex-column  menu-icon-label'>
                      <div className='d-flex flex-row  menu-icon-label'>
                        <div className="mr-10">SMS</div>
                        <div>
                        {
                          isAdditionalFeatureSmsEnabled
                          ? ( isUserSmsEnabled ? <div>{isUserSmsPaused ? localised.paused : localised.oN}</div> : <div>{localised.oFF}</div>)
                          : <div>{localised.disabled}</div>
                        }
                        </div>
                      </div>
                      {isSmsMessagingAdditionalFeatureEnabled && isProxy  && (
                        <div className="mr-10 text-header-icon-color">
                          {localised.smsRemaining}: {sidebarCount?.monthly_sms_limit}
                        </div>
                      )}
                  </div>
                </NavLink>
              }
              
              {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_rc_invoice')) &&
                <NavLink to="/cleaner-wallet">
                  <span className='menu-icon'><FiCreditCard /></span> <span className='menu-icon-label'>{localised.rcBillingSettings}</span>
                </NavLink>
              }
              {
                (isProxy || !isLocked) && 
                <>
                  <NavLink to="/cleaner-change-password">
                    <span className='menu-icon'><FiLock /></span>  <span className='menu-icon-label'>{localised.changePassword}</span>
                  </NavLink>
                  
                  <li className='language-select'>
                    <span className='menu-icon'><HiLanguage /></span> <LanguageSelector />
                  </li>
                </>
              }
        
              <Link to="#" onClick={handleLogout}>
                <span className='menu-icon'><FiLogOut /></span>  {localised.signOut}
              </Link>
            </Dropdown.Menu>
          </Dropdown>

        </li>
         {(isProxy || !isLocked) && <li className='nav-item help-header-icon'>
         <FiHelpCircle className='cursor' title={localised.help} onClick={toggleSearchSupportModal}/>
        </li>}
      </ul>
      <div className='mobile-all-menu d-md-none' onClick={toggelHeaderDiviceMenu}>
        {/* <FiAlignJustify onClick={hideSidebarComponent}/> */}
      </div>
    </div>
    <RegionFilter
        title={localised.regionFilter}
        size='md'
        show={showRegionFilterModal}
        handleShow={toggleRegionFilterModal}
        handleClose={toggleRegionFilterModal}
        className="region-filter-modal" />
      {showSearchSupportModal && 
      
      
      <Modal backdrop={false} show={showSearchSupportModal} onHide={toggleSearchSupportModal} className="region-filter-modal search-support-modal without-footer">
      <Modal.Header closeButton>
      <span className='modal-close-btn' onClick={toggleSearchSupportModal}><FiX /></span>
          </Modal.Header>
      <Modal.Body>
      <SearchSupportModal />
      </Modal.Body>
    </Modal>
      }

      {
        showPauseSmsModal &&
        <PauseSmsNotificationsModal
          show={showPauseSmsModal}
          handleHide={hidePauseSmsModal}/>
      }

      {/* Daily Closeout Process */}
      {showDcpModal && (
        <DailyCloseoutModal 
          showDcpModal = {showDcpModal}
          toggleDcpModal = {toggleDcpModal}
        />
      )}

    </>
  );
};
export default CleanerHeader;
