import React, { useState, useEffect, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { filterArrayByKeyValue, getRedirectURI } from "../../../utils/utils";
import {
  PAYMENT_GATEWAY,
  PAYMENT_TILLED_ACH,
  PAYMENT_TILLED_CC,
  RC_INVOICE_TILLED_GATEWAY,
  requestFrm,
  TILLED_ACH_LABEL,
  TILLED_CC_LABEL,
  tilledObj,
  tilledTypeAch,
  tilledTypeCard,
  WALLET_TILLED_ACH,
  WALLET_TILLED_CC,
} from "../../../utils/paymentConstUtil";
import { getCompany, getUser } from "../../../redux/user/action";
import { LanguageContext } from "../../containers/Language";
import CustomModal from "../CustomModal";
import {
  handleValidations,
  tilledFormValidation,
} from "../../../utils/cardValidation";
import {
  BANKTRANSFER,
  CREDITCARD,
  TILLEDACCID,
  USA_COUNTRY,
  createPaymentMethod,
  saveWalletDetials,
} from "../../../utils/tilledUtil";
import TilledWallet from "../Wallet/TilledWallet";
import { getCompanyWallet, saveCardpointeRcPaymentRequest } from "../../../redux/Billing/action";
import { isLoader } from "../../../redux/global/action";
import useCardpointeModalUtils from "../CardpointeModelHandler";
import CardpointeWallet from "../CardpointeWallet";
import { cardpointeFormValidation, getPaymentToken, setTokenPayload } from "../../../utils/cardpointeUtil";
import { useLocation } from "react-router-dom";

const AutopayNewPayment = React.forwardRef((props, ref) => {
  const { localised } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication) || "";
  const alert = useSelector((state) => state.global.alert);
  const [companyBillingAddress, setCompanyBillingAddress] = useState("");
  const companyInformation = useSelector((state) => state.user?.company) || [];
  const userProfileDetails = useSelector(
    (state) => state.authentication.profile
  );
  const [tilledPayloadObj, setTilledPayloadObj] = useState(tilledObj);
  const userInformation = useSelector((state) => state.user.user) || [];
  const [tilledType, setTilledType] = useState("");
  const [userProfile, setUserProfile] = useState([]);
  const [country, selectedCountry] = useState({
    value: "",
    label: "Select Country",
  });
  const location = useLocation();
  const [state, selectedState] = useState([]);
  const states = useSelector((state) => state.global.states);
  const [countryWiseStates, setCountryWiseStates] = useState([]);
  const [showTilledPayModal, setShowTilledPayModal] = useState(false);
  const toggleTilledPayModal = () => setShowTilledPayModal(!showTilledPayModal);
  const [formError, setFormError] = useState({
    exp_month_year_error: "",
    number_error: "",
  });
  // const [isLoader, setIsLoader] = useState(false);
  const [expiryDate, setExpiryDate] = useState('')
  const companyInvoiceSetting = useSelector((state) => state.company.invoiceSetting) || [];
  const { 
    radioHandlerCardpointeAch, 
    radioHandlerCardpointeCc,
    cardpointePayloadObj,
    setCardpointePayloadObj,
    setCardpointeFormError,
    cardpointeFormError,
    payMethod,
    setCardpointeSelectedState,
    setCardpointeSelectedCoutry,
    cardpointeSelectedCoutry,
    cardpointeSelectedState,
    clearCardpoint,
    setIsInitialized,
    cardpointeCountryWiseStates,
    getCardpointeCountryWiseStates
   } = useCardpointeModalUtils();
  const history = useHistory();
  const [active, setActive] = useState(WALLET_TILLED_CC);
  const [paymentType, setPaymentType] = useState('');
  const isOnWalletPage = (location.pathname === '/manager-wallet'|| location.pathname === '/cleaner-wallet');
  const loggedInUser = useSelector((state) => state.authentication?.profile) || "";
  let isagreeRequiredOnLocal =  loggedInUser?.has_autopay_enable;
  const [agreedTerms, setAgreedTerms] = useState((isagreeRequiredOnLocal) ? false : true);

  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    if (userProfileDetails) {
      setUserProfile(userProfileDetails);
      let id = userProfileDetails.user_id;
      let company_id = userProfileDetails?.company_id;
      if (company_id) {
        dispatch(getCompany({ company_id }));
      }
      if (id) {
        dispatch(getUser({ id }));
      }
    }
  }, [userProfileDetails]);

  const getCountryWiseStates = (selectedValue) => {
    let state_arr = filterArrayByKeyValue(
      "country_code",
      selectedValue.value,
      states
    );
    setCountryWiseStates(state_arr);
  };

  useEffect(() => {
    if (Object.keys(companyInformation).length > 0) {
      setTilledPayloadObj({
        ...tilledPayloadObj,
        billing_details: {
          ...tilledPayloadObj.billing_details,
          email: companyInformation.billingInfo?.billing_email
            ? companyInformation.billingInfo?.billing_email
            : "",
        },
      });
      setCompanyBillingAddress(companyInformation.billingInfo?.billing_email);
    }
  }, [companyInformation]);

  const clearTiledForm = () => {
    let countryObj1 = userInformation?.Address?.Country?.country_code
      ? {
          value: userInformation?.Address?.Country?.country_code,
          label: userInformation?.Address?.Country?.country,
        }
      : USA_COUNTRY;

    let tilledObjNew = {
      ...tilledPayloadObj,
      billing_details: {
        ...tilledPayloadObj.billing_details,
        address: {
          ...tilledPayloadObj.billing_details.address,
          street: userInformation?.Address?.address
            ? userInformation?.Address?.address
            : "",
          state: userInformation?.state_abbbreaviation
            ? userInformation?.state_abbbreaviation
            : "",
          city: userInformation?.Address?.city
            ? userInformation?.Address?.city
            : "",
          zip: userInformation?.Address?.postal_code
            ? userInformation?.Address?.postal_code
            : "",
          country: userInformation?.Address?.Country?.country_code
            ? userInformation?.Address?.Country?.country_code
            : USA_COUNTRY.value,
        },
        name: userInformation.name ? userInformation.name : "",
        email: userInformation.email ? userInformation.email : "",
        phone: userInformation.phone ? userInformation.phone : "",
      },
    };
    if (tilledPayloadObj.type == "card") {
      tilledObjNew = { ...tilledObjNew, type: "card" };
    } else if (tilledPayloadObj.type == "ach_debit") {
      tilledObjNew = { ...tilledObjNew, type: "ach_debit", ...tilledTypeAch };
    }
    setTilledPayloadObj(tilledObjNew);
    selectedCountry(countryObj1);
    selectedState(
      userInformation?.state_abbbreaviation
        ? {
            value: userInformation?.state_abbbreaviation,
            label: userInformation?.Address?.State?.state_name,
          }
        : ""
    );
    getCountryWiseStates(countryObj1);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let isError = false;
    const thisType = active === WALLET_TILLED_CC ? CREDITCARD : BANKTRANSFER;
    let formError = tilledFormValidation(tilledPayloadObj, true);
    for (const key in formError) {
      if (formError[key]) {
        isError = true;
      }
    }

    setFormError(formError);
    setIsSubmitted(true);

    if (isError) {
      return;
    }

    if(!agreedTerms){
      return;
    }
    const { tilled, form } = thisType;
    if (form.validate()) {
      addWalletDetails(thisType, tilledPayloadObj);
    }
  }

  const addWalletDetails = (tiledForm, tilledPayloadObj) => {
    dispatch(isLoader(true));
    createPaymentMethod(tiledForm, tilledPayloadObj).then(
      (payment) => {
        let formData = {
          id: payment.id,
          type: active,
          is_system_lock : true
        };
        saveWalletDetials(formData).then((res) => {
          toast.success(localised.walletConfigure);
          dispatch(getCompanyWallet({paymentGateway : "tilled"}));
          dispatch({type: 'GET_LOGGED_USER_DETAILS'})
          setTimeout(() => {
            clearTiledForm();
            dispatch(isLoader(false));
            props?.showAddPaymentDetailModal(!props?.addPaymentDetail)
            props.setShowAutopayPayment(false)
          }, 1000);
        });
      },
      (errors) => {
        let obj = {};
        console.log(errors);
        errors.map((err) => {
          let objName = err.substring(0, err.indexOf("."));

          let objKey = err.substring(err.indexOf(".") + 1);

          let keyName = objKey.split(" ")[0];

          let value = objKey.slice(keyName.length);

          let errorKey = keyName + "_error";

          obj = {
            ...obj,
            [errorKey]: value,
          };
        });
        setFormError(obj);
      }
    );
  };
  const handleEnterPressOnTilled = (event) => {
    if (event.keyCode == 13 || event.which == 13) {
      handleSubmit();
    }
  };
  const radioHandlerTilledAch = () => {
    let tilledFrm = { ...tilledPayloadObj };
    setTilledPayloadObj({ ...tilledFrm,type:'ach_debit',...tilledTypeAch});
    setActive(WALLET_TILLED_ACH)
    setPaymentType('ACH')
  };

  const radioHandlerTilledCc = () => {
    let tilledFrm = { ...tilledPayloadObj };
    delete tilledFrm['ach_debit'];
    setTilledPayloadObj({ ...tilledFrm,type:'card' })
    setActive(WALLET_TILLED_CC)
    setPaymentType('CC')
  };

  async function handleSubmitPropelr(e) {
    e.preventDefault();
    e.stopPropagation();
    
    let isError = false;

    let cardpointeFormErrors = cardpointeFormValidation(
      cardpointePayloadObj,
      true
    );
    for (const key in cardpointeFormErrors) {
      if (cardpointeFormErrors[key]) {
        isError = true;
      }
    }
    setCardpointeFormError(cardpointeFormErrors);
    setIsSubmitted(true);

    if (isError) {
      return;
    }

    if(!agreedTerms){
      return;
    }
    dispatch(isLoader(true));
    let paymentTokenPayload = setTokenPayload(cardpointePayloadObj);
   
    getPaymentToken(paymentTokenPayload).then((res) => {
      if (res?.data?.errorcode == 0) {
        let formData = {
          account: res?.data?.token,
          is_system_lock : true,
          type:payMethod == WALLET_TILLED_ACH ? TILLED_ACH_LABEL : TILLED_CC_LABEL,
          address: cardpointePayloadObj?.billing_details?.address?.street,
          city: cardpointePayloadObj?.billing_details?.address?.city,
          state: cardpointePayloadObj?.billing_details?.address?.state,
          country: cardpointePayloadObj?.billing_details?.address?.country,
          postal: cardpointePayloadObj?.billing_details?.address?.zip,
          name: cardpointePayloadObj?.billing_details?.name,
          email: cardpointePayloadObj?.billing_details?.email,
        };
        if (payMethod == WALLET_TILLED_ACH) {
          formData = {
            ...formData,
            accttype: cardpointePayloadObj.ach_debit.account_type,
          };
        } else if (payMethod == WALLET_TILLED_CC) {
          formData = {
            ...formData,
            expiry:
              cardpointePayloadObj.card.exp_month+''+cardpointePayloadObj.card.exp_year,
          };
        }
          dispatch(
            saveCardpointeRcPaymentRequest({
              data: formData,
              successMsg: localised.walletConfigure,
              failedMsg: localised?.unableToSavePropelr,
            })
          );

      }
    },
    (error)=>{
      dispatch(isLoader(false));
    }
    );
  }
  useEffect(() => {
    if (alert && alert !== null) {
      if (alert.variant === "danger" && alert?.componentName === "propelrPaymentGateway") {
        toast.error(alert.message);
      } else {
        switch (alert.componentName) {
         
          case 'propelrPaymentGateway' :
            if(!isOnWalletPage){
              toast.success(alert.message);
            }
            dispatch(getCompanyWallet({paymentGateway : "cardpointe"}));
            dispatch({type: 'GET_LOGGED_USER_DETAILS'})
            props.setAddPaymentDetail(false)
            props.setShowAutopayPayment(false)
          break;
          default:
            break;
        }
      }
      setTimeout(() => {
        dispatch({ type: "CLEAR_ALERT" });
      }, 1200);
    }
  }, [alert]);

  return (
    <>
      {props.addPaymentDetail &&  (
        <>
          {companyInvoiceSetting.payment_gateway?.id == RC_INVOICE_TILLED_GATEWAY ? (
            <CustomModal
              size={"md"}
              title={localised.pleaseFillYourTilledDetails}
              show={props.addPaymentDetail}
              handleShow={props.showAddPaymentDetailModal}
              handleClose={props.showAddPaymentDetailModal}
              btnOnClick={true}
              errorReset={clearTiledForm}
              handleSave={handleSubmit}
              backdrop="static"
              className="center-modal"
              photoPoup={false}
            >
              <TilledWallet
                radioHandlerTilledAch={radioHandlerTilledAch}
                radioHandlerTilledCc={radioHandlerTilledCc}
                isAdminIvoice={false}
                saveCardShow={false}
                active={active}
                isAutoPayRequest={true}
                setActive={setActive}
                tilledPayloadObj={tilledPayloadObj}
                setTilledPayloadObj={setTilledPayloadObj}
                selectedCountry={selectedCountry}
                state={state}
                country={country}
                selectedState={selectedState}
                getCountryWiseStates={getCountryWiseStates}
                setFormError={setFormError}
                formError={formError}
                countryWiseStates={countryWiseStates}
                setCountryWiseStates={setCountryWiseStates}
                tilledCC={WALLET_TILLED_CC}
                accountId={TILLEDACCID}
                agreedTerms={agreedTerms}
                setAgreedTerms={setAgreedTerms}
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
                isAgreeRequired={true}
                isagreeRequiredOnLocal = {isagreeRequiredOnLocal}
              ></TilledWallet>
            </CustomModal>
          ) : (
            <CustomModal
              size={"md"}
              title={localised.paymentInfo}
              show={props.addPaymentDetail}
              handleShow={props.showAddPaymentDetailModal}
              handleClose={props.showAddPaymentDetailModal}
              useHandleSave={false}
              errorReset={clearCardpoint}
              btnOnClick={true}
              handleSave={handleSubmitPropelr}
              backdrop="static"
              className="center-modal"
              photoPoup={false}
            >
              <CardpointeWallet
                saveCardShow={false}
                active={payMethod}
                isAutoPayRequest={true}
                cardpointePayloadObj={cardpointePayloadObj}
                setCardpointePayloadObj={setCardpointePayloadObj}
                cardpointeSelectedState={cardpointeSelectedState}
                cardpointeSelectedCountry={cardpointeSelectedCoutry}
                setCardpointeSelectedCountry={setCardpointeSelectedCoutry}
                setCardpointeSelectedState={setCardpointeSelectedState}
                getCountryWiseStates={getCardpointeCountryWiseStates}
                countryWiseStates={cardpointeCountryWiseStates}
                setCountryWiseStates={setCountryWiseStates}
                useHandleSave={false}
                radioHandlerCardpointeCc={radioHandlerCardpointeCc}
                radioHandlerCardpointeAch={radioHandlerCardpointeAch}
                tilledCC={WALLET_TILLED_CC}
                accountId={TILLEDACCID}
                cardpointeFormError={cardpointeFormError}
                setCardpointeFormError={setCardpointeFormError}
                setExpiryDate={setExpiryDate}
                expiryDate={expiryDate}
                agreedTerms={agreedTerms}
                setAgreedTerms={setAgreedTerms} 
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}      
                isAgreeRequired={true}
                isagreeRequiredOnLocal = {isagreeRequiredOnLocal}
              />
            </CustomModal>
          )}
        </>
      )}
    </>
  );
});

export default AutopayNewPayment;
