import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Row,
  Form,
} from "react-bootstrap";
import CustomSelect from "../../../../atoms/CustomSelect";
import Buttons from "../../../../atoms/Buttons";
import { LanguageContext } from "../../../../containers/Language";
import ToggleSwitch from "../../../../atoms/ToggleSwitch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, filterArrayByKeyValue } from "../../../../../utils/utils";
import moment from "moment";
import { monthOptions } from "../../../../../utils/constUtil";
import { useSelector } from "react-redux";

let initialValues = {
  "fromDate": moment().startOf('month').format("YYYY-MM-DD"),
  "toDate": moment().endOf('month').format("YYYY-MM-DD"), 
  "is_compare": false,
  "year1": moment(new Date()).subtract(1, 'y').format("YYYY"),
  "year2": moment(new Date()).format("YYYY"),
  "month1": (new Date()).getMonth() + 1,
  "month2": (new Date()).getMonth() + 1,
  "groupBy": "week",
}
let firstLoad = true;
const JobsRevenueSearch = React.forwardRef(
  ({
    props,
    ref,
    tableColumn,
    setTableColumn,
    searchJobsAndRevenue, resetSearchFilter, setShowWarningModal
  }) => {
    const { localised } = useContext(LanguageContext);
    const groupbyOptions = [
      { value: "week", label: localised.weeks },
      { value: "quarter", label: localised.quarter },
    ];
    const [groupby, setGroupBy] = useState(groupbyOptions[0]);
    const [searchValues, setSearchValues] = useState(initialValues);
    const [fromDate, setFromDate] = React.useState(new Date(initialValues?.fromDate));
    const [toDate, setToDate] = React.useState(new Date(initialValues?.toDate));
    const [convFromDate, setconvFromDate] = useState(null);
    const [convtoDate, setconvtoDate] = useState(null);
    const [fromMonth, setFromMonth] = useState((filterArrayByKeyValue('value', (new Date()).getMonth() + 1, monthOptions)[0]));
    const [toMonth, setToMonth] = useState((filterArrayByKeyValue('value', (new Date()).getMonth() + 1, monthOptions)[0]));
    const dateFormat = useSelector((state) => state.authentication.profile.date_format);

    const onToggleChange1 = (checked) => {
      setTableColumn(checked);
      setSearchValues({
        ...searchValues,
        is_compare: checked,
      });
    };

    const [showStartDate, setStartDate] = useState(new Date(initialValues.year1));
    const [showEndDate, setEndDate] = useState(new Date(initialValues.year2));
    const selectFromDateHandler = (selectedDate) => {
      let converted = '', finalbtndate = '', date = '';
      let new_date;
      converted = convertDate(selectedDate);
      setFromDate(selectedDate);
      if (convtoDate?.length === 0) {
        date = converted;
      } else {
        date = convtoDate
      }
      setconvFromDate(converted);
      if (toDate === null || selectedDate > toDate) {
        new_date = moment(selectedDate, "DD-MM-YYYY").add(1, 'days').format('YYYY/MM/DD');
        setToDate(new Date(new_date));
        setconvtoDate(new Date(new_date));
        date = new_date;
        setSearchValues({
          ...searchValues,
          fromDate: selectedDate ? moment(selectedDate).format('YYYY/MM/DD') : '',
          toDate: date ? moment(date).format('YYYY/MM/DD') : '',
        });
        return;
      }

      setSearchValues({
        ...searchValues,
        fromDate: selectedDate ? moment(selectedDate).format('YYYY/MM/DD') : '',
      });
    }

    const selectToDateHandler = (selectedDate) => {
      let converted = '', finalbtndate = '', date = '';
      let new_date;
      setToDate(selectedDate);
      converted = convertDate(selectedDate);
      if (convFromDate?.length === 0) {
        date = converted;
      } else {
        date = convFromDate
      }
      if (fromDate === null) {
        new_date = moment(selectedDate, "DD-MM-YYYY").subtract(1, 'days').format('YYYY/MM/DD');
        setFromDate(new Date(new_date));
        setconvFromDate(new Date(new_date));
        date = new_date;
      }
      setconvtoDate(converted);

      setSearchValues({
        ...searchValues,
        toDate: selectedDate ? moment(selectedDate).format('YYYY/MM/DD') : '',
      });
    }

    const searchJobsRevenue = (e) => {
      e.preventDefault();
      e.stopPropagation();
      searchJobsAndRevenue({ ...searchValues });
    }

    const resetFilter = (e) => {
      setSearchValues(initialValues);
      setFromDate(new Date(initialValues?.fromDate));
      setToDate(new Date(initialValues?.toDate))
      setconvFromDate(null);
      setconvtoDate(null);
      setTableColumn(false);
      setFromMonth((filterArrayByKeyValue('value', (new Date()).getMonth() + 1, monthOptions)[0]));
      setToMonth((filterArrayByKeyValue('value', (new Date()).getMonth() + 1, monthOptions)[0]));
      setStartDate(new Date(initialValues.year1));
      setEndDate(new Date(initialValues.year2));
      resetSearchFilter();
    }


    const calculateDateDifference = (from, to) => {
      // To calculate the time difference of two dates 
      let Difference_In_Time = to.getTime() - from.getTime();

      // To calculate the no. of days between two dates 
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (!tableColumn && (Difference_In_Days > 30)) {
        setShowWarningModal(true);
      }
    }

    const handleMonth1Change = (selectedValue) => {
      setFromMonth(selectedValue);
      setSearchValues({
        ...searchValues,
        month1: selectedValue ? selectedValue?.value : null,
      });
    }

    const handleMonth2Change = (selectedValue) => {
      setToMonth(selectedValue);
      setSearchValues({
        ...searchValues,
        month2: selectedValue ? selectedValue?.value : null,
      });
    }

    const changeStartYear = (selectedValue) => {
      setStartDate(selectedValue)
      setSearchValues({
        ...searchValues,
        year1: moment(selectedValue).format('YYYY'),
      });
    }

    const changeEndYear = (selectedValue) => {
      setEndDate(selectedValue)
      setSearchValues({
        ...searchValues,
        year2: moment(selectedValue).format('YYYY'),
      });
    }

    const handleGroupByChange = (option) => {
      setGroupBy(option);
      setSearchValues({
        ...searchValues,
        groupBy: option?.value, 
      });
    };

    
    
    useEffect(() => {
      return () => {
        firstLoad = true;
      };
    }, []);


    useEffect(() => {
      if (!firstLoad) {
        calculateDateDifference(fromDate, toDate);
        searchJobsAndRevenue({ ...searchValues });
      }
      firstLoad = false;
    }, [tableColumn]);


    return (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{localised.search}</h3>
        </div>
        <div className="card-body analytics-search">
          <Form onSubmit={searchJobsRevenue} noValidate>
            {!tableColumn && (
              <>
                <Row>
                  <Col md="3" lg="3">
                    <Form.Group className="form-group">
                      <Form.Label>
                        {localised.from} {localised.date}
                      </Form.Label>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        onChange={selectFromDateHandler}
                        placeholderText={localised.fromDate}
                        value={(fromDate) ? moment(fromDate).format(dateFormat) : ''}
                        showYearDropdown
                        scrollableYearDropdown
                      />
                    </Form.Group>
                  </Col>

                  <Col md="3" lg="3">
                    <Form.Group className="form-group">
                      <Form.Label>
                        {localised.to} {localised.date}
                      </Form.Label>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        onChange={selectToDateHandler}
                        minDate={fromDate}
                        placeholderText={localised.toDate}
                        value={(toDate) ? moment(toDate).format(dateFormat) : ''}
                        showYearDropdown
                        scrollableYearDropdown
                      />
                    </Form.Group>
                  </Col>
                  <Col md="3" lg="3">
                    <Form.Group className="form-group">
                      <Form.Label>{localised.groupBy}</Form.Label>
                      <CustomSelect
                        id={"groupby"}
                        name={"groupby"}
                        closeMenuOnSelect={true}
                        isMulti={false}
                        options={groupbyOptions}
                        value={groupby}
                        onChange={handleGroupByChange} 
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col md="3" lg="3">
                <Form.Group className="form-group">
                  <Form.Label>{localised.compare}</Form.Label>
                  <div>
                    <ToggleSwitch
                      id="switchToggle1"
                      checked={tableColumn}
                      onChange={onToggleChange1}
                      optionLabels={["ON", "OFF"]}
                    />
                  </div>
                </Form.Group>
              </Col>

              {!tableColumn && (
                <Col
                  md="12"
                  lg="9"
                  className="d-flex align-items-end justify-content-md-end form-btns mt-3"
                >
                  <Form.Group className="form-group">
                    <div className="search-btn-link">
                      <Buttons variant='secondary' title={localised.reset} className="button-icon" onClick={resetFilter} />
                      <Buttons variant='primary' title={localised.search} className="ms-2" type="submit" />
                    </div>

                  </Form.Group>
                </Col>
              )}
            </Row>
            {tableColumn && (
              <>
                <Row>
                  <Col md="3" lg="3">
                    <Form.Group className="form-group">
                      <Form.Label>
                        {localised.monthYear1}
                      </Form.Label>
                      <div className="month-year-select">
                        <CustomSelect
                          id={"months"}
                          name={"months"}
                          closeMenuOnSelect={true}
                          isMulti={false}
                          options={monthOptions}
                          placeholder="Month"
                          value={fromMonth}
                          onChange={handleMonth1Change}
                          isClearable={true}
                        />
                        <DatePicker
                          selected={showStartDate}
                          onChange={(date) => { changeStartYear(date) }}
                          showYearPicker
                          dateFormat="yyyy"
                          placeholderText="Year"

                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="3" lg="3">
                    <Form.Group className="form-group">
                      <Form.Label>
                        {localised.monthYear2}
                      </Form.Label>
                      <div className="month-year-select">
                        <CustomSelect
                          id={"months"}
                          name={"months"}
                          closeMenuOnSelect={true}
                          isMulti={false}
                          options={monthOptions}
                          placeholder="Month"
                          value={toMonth}
                          onChange={handleMonth2Change}
                          isClearable={true}
                        />
                        <DatePicker
                          selected={showEndDate}
                          onChange={(date) => { changeEndYear(date) }}
                          showYearPicker
                          dateFormat="yyyy"
                          placeholderText="Year"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col
                    md="12"
                    lg="6"
                    className="d-flex align-items-end justify-content-md-end form-btns mt-3"
                  >
                    <Form.Group className="form-group">
                      <div className="search-btn-link">
                        <Buttons variant='secondary' title={localised.reset} className="button-icon" onClick={resetFilter} />
                        <Buttons variant='primary' title={localised.search} className="ms-2" type="submit" />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Form>

        </div>
      </div>
    );
  }
);

export default JobsRevenueSearch;
