import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { FiX } from "react-icons/fi";
import { updateGlobalFilter } from '../../../redux/global/action';
import { getDropdownRegionlist, getDropdownRegionwiseArealist } from '../../../redux/properties/action';
import { getActivePropertyRegion } from '../../../utils/utils';
import CustomSelect from '../../atoms/CustomSelect';
import { LanguageContext } from '../../containers/Language';

const initialfilter = {
    region_id:[],
    area_id:[]
}
const RegionFilter = ({ show, handleClose, title, size, className }) => {
    const dispatch = useDispatch();
    const [globalAreaOptions, setGlobalAreasOptions]  = useState([]);

    const { localised } = useContext(LanguageContext);
    
    const globalPropertyRegion   = useSelector((state) => state.property.regionlist)||[];  
    
    const globalRegionWiseArea   = useSelector((state) => state.property.regionWiseArea); 
    let globalPropertyRegionList = getActivePropertyRegion(globalPropertyRegion);  
    
    
    const [selectedGlobalRegion, setSelectedGlobalRegion]        = useState([]);
    const [selectedGlobalArea, setSelectedGlobalArea]            = useState([]);    
    const [filter, setFilter]   = useState(initialfilter);

    const globalRegionAreaFilter          = useSelector((state) => state.global.globalRegionAreaFilter);

    useEffect(()=>{
        if(globalPropertyRegion){
             globalPropertyRegionList = getActivePropertyRegion(globalPropertyRegion); 
        }
    },[globalPropertyRegion]);


    useEffect(()=>{
            if (globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
                setSelectedGlobalRegion(globalRegionAreaFilter.region_id);
                setSelectedGlobalArea(globalRegionAreaFilter.area_id);
                setFilter({
                ...filter,
                'region_id': globalRegionAreaFilter.region_id,
                'area_id': globalRegionAreaFilter.area_id,
                });
            }
    },[globalRegionAreaFilter]);

    useEffect(()=>{
        if(globalRegionWiseArea)
        {
            if(globalRegionWiseArea.length > 0 && selectedGlobalRegion.length>0){
                setGlobalAreasOptions(globalRegionWiseArea)
            }
        }
    },[globalRegionWiseArea]);

    useEffect(()=>{
        dispatch(getDropdownRegionlist());
    },[]);

    
    const handleGlobalPropertyRegionChange = (selectedValue) =>{
        setSelectedGlobalRegion(selectedValue);
        if(selectedValue.length)
        {
            let value='';
            for(let i=0;i<selectedValue.length;i++){
                if(i<selectedValue.length-1){
                    value+=`${selectedValue[i].value},`
                }
                else{
                    value+=`${selectedValue[i].value}`
                }
            }
            dispatch(getDropdownRegionwiseArealist({region_id:value}));
        }else{
             setGlobalAreasOptions([]);
        }
        setFilter({
            ...filter,
            ['region_id']: selectedValue,
            ['area_id']:[],
        });
        setSelectedGlobalArea([]);
        
    }
    const handleGlobalAreaChange = (selectedValue) => {
        setSelectedGlobalArea(selectedValue);
        setFilter({
            ...filter,
            ['area_id']: selectedValue,
        });
    };
    const handleGlobalRegionAreaFilter=(event)=>{        
            event.preventDefault();
            let formData  = filter;
            dispatch(updateGlobalFilter(formData));
            handleClose();
    }
    const resetSearchFilter = () => {
        setFilter(initialfilter);
        setSelectedGlobalRegion([]);
        setSelectedGlobalArea([]);
        setGlobalAreasOptions([]);
        handleClose();
        dispatch(updateGlobalFilter(initialfilter));  
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop={false}
                keyboard={false}
                scrollable={true}
                size={size}
                className={className}
            >
                <Modal.Header closeButton className='py-2'>
                    <Modal.Title className='mb-0'>{title}</Modal.Title>
                    <span className='modal-close-btn' onClick={handleClose}><FiX/></span>
                </Modal.Header>
                <Modal.Body>
                <Form.Group className="form-group">
                                <Form.Label>{localised.region}</Form.Label>
                                <CustomSelect
                                    id={'global_region'}
                                    name={'global_region'}
                                    closeMenuOnSelect={true}
                                    isMulti={true}
                                    options={globalPropertyRegionList}
                                    onChange={handleGlobalPropertyRegionChange}
                                    value={selectedGlobalRegion}
                                    //menuPortalTarget={document.body}
                                />
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>{localised.area}</Form.Label>
                                <CustomSelect
                                    id={'global_area'}
                                    name={'global_area'}
                                    closeMenuOnSelect={true}
                                    isMulti={true}
                                    options={globalAreaOptions}
                                    onChange={handleGlobalAreaChange}
                                    value={selectedGlobalArea}
                                />
                            </Form.Group>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={resetSearchFilter}>
                        {localised.reset}
                    </Button>
                    <Button variant="primary"  onClick={handleGlobalRegionAreaFilter} >  {localised.regionSearch}</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
};

export default RegionFilter;