import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";

import Breadcrumbs from "../../../atoms/Breadcrumbs";
import { LanguageContext } from "../../../containers/Language";
import Search from "../../../molecules/Cleaner/JobsAndRevenue/Search";
import { Line } from "react-chartjs-2";
import "./style.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import { isArray } from "../../../../utils/utils";
import { Grid } from "gridjs-react";
import apiService from "../../../../redux/apiService";
import { microServices } from "../../../../config/global";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const JobsAndRevenue = () => {
  const { localised } = useContext(LanguageContext);
  const loggedInUser = useSelector((state) => state.authentication.profile);
  const currency = loggedInUser?.currency;
  const [isCompare, setISCompare] = useState(false);
  const [countChart, setCountChart] = useState(null);
  const [revenueChart, setRevenueChart] = useState(null);
  const [timeChart, setTimeChart] = useState(null);
  const currencySymbol = loggedInUser?.currency?.symbol || "$";
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [netChartData, setNetChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [filters, setFilters] = useState({
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
    groupBy: "week",
  });

  async function getJobAndRevenueData(filters) {
    let endpoint = `${microServices.base}/v1/reports/jobs-and-revenue`;
    try {
      if (!filters.is_compare) {
        const jobsAndRevenueData = await apiService.post(endpoint, filters);
        const { tableData } = prepareTableData( jobsAndRevenueData, localised);
        setTableData(tableData);
        const netChart = prepareNetChartData(jobsAndRevenueData);
        setNetChartData(netChart);
        return;
      }else{
        const prepareFromDate = (month, year) => {
          return `${year}-${String(month).padStart(2, '0')}-01`;
        };
        const prepareToDate = (month, year) => {
          const lastDay = new Date(year, month, 0).getDate();
          return `${year}-${String(month).padStart(2, '0')}-${lastDay}`;
        }
        const fromDate = prepareFromDate(filters.month1, filters.year1);
        const toDate = prepareToDate(filters.month1, filters.year1);
        const filters1 = {
          fromDate: fromDate,
          toDate: toDate,
          groupBy: "week",
        };
        const jobsAndRevenueData = await apiService.post(endpoint, filters1);
        const fromDate2 = prepareFromDate(filters.month2, filters.year2);
        const toDate2 = prepareToDate(filters.month2, filters.year2);
        const filters2 = {
          fromDate: fromDate2,
          toDate: toDate2,
          groupBy: "week",
        };
        const jobsAndRevenueData2 = await apiService.post(endpoint, filters2);
        const { tableData } = prepareTableData2(jobsAndRevenueData,jobsAndRevenueData2, localised);
        setTableData(tableData);
        const netChartCompare = prepareCompareNetChartData(jobsAndRevenueData, jobsAndRevenueData2, localised, currencySymbol, filters);
        setNetChartData(netChartCompare);
        return
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    getJobAndRevenueData(filters);
  }, []);

  const prepareTableData = ( jobsAndRevenueData, localised) => {
    const headerData = jobsAndRevenueData.data.Header.data;
    const type = jobsAndRevenueData.data.Header.type;
    const weekKeys = Object.keys(headerData);
  
    const rowWeek = [type=="week" ? localised.week: localised.quarter];
    const rowDates = [localised.dates];
    const rowNumberOfServices = [localised.numberOfServices];
    const rowNumberOfReportedIssues = [localised.numberOfReportedIssues];
    const rowNewServicesScheduled = [localised.newServicesScheduled];
    const rowNumberOfWorkorders = [localised.numberOfWorkorders];
    const rowTotalServicesPerformed = [localised.totalServicesPerformed];
    const rowServiceRevenue = [localised.serviceRevenue];
    const rowServiceHousekeeperExpense = [localised.serviceHousekeeperExpense];
    const rowServiceAttendantExpense = [localised.serviceHouseAttendantExpense];
    const rowServiceInspectorExpense = [localised.serviceInspectorExpense];
    const rowTotalServiceExpense = [localised.totalServiceExpense];
    const rowServiceNet = [localised.serviceNet];
    const rowServiceMargin = [localised.serviceMargin];
    const rowWorkordersRevenue = [localised.workordersRevenue];
    const rowWorkorderContractorExpense = [localised.workorderContractorExpense];
    const rowWorkorderNet = [localised.workorderNet];
    const rowWorkorderMargin = [localised.workorderMargin];
    const rowNet = [localised.net];
    const rowTotalMargin = [localised.totalMargin];
  
    weekKeys.forEach((wk) => {
      const sequence = headerData[wk]?.Sequence || "";
      const label = headerData[wk]?.Label || "";
  
      rowWeek.push(sequence);
      rowDates.push(label);
  
      rowNumberOfServices.push(jobsAndRevenueData.data.no_of_service?.[wk] ?? "0");
      rowNumberOfReportedIssues.push(jobsAndRevenueData.data.no_of_reported_issues?.[wk] ?? "0");
      rowNewServicesScheduled.push(jobsAndRevenueData.data.new_service_scheduled?.[wk] ?? "0");
      rowNumberOfWorkorders.push(jobsAndRevenueData.data.no_of_workorders?.[wk] ?? "0");
      rowTotalServicesPerformed.push(jobsAndRevenueData.data.total_service_performed?.[wk] ?? "0");
      rowServiceRevenue.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_revenue?.[wk] ?? "0").toFixed(2)}`);
      rowServiceHousekeeperExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_hk_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowServiceAttendantExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_ha_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowServiceInspectorExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_insp_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowTotalServiceExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.total_service_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowServiceNet.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.service_net?.[wk] ?? "0").toFixed(2)}`);
      rowServiceMargin.push(`${parseFloat(jobsAndRevenueData.data.service_margin?.[wk] ?? "0").toFixed(2)}%`);
      rowWorkordersRevenue.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.wo_revenue?.[wk] ?? "0").toFixed(2)}`);
      rowWorkorderContractorExpense.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.wo_contract_expenses?.[wk] ?? "0").toFixed(2)}`);
      rowWorkorderNet.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.workorder_net?.[wk] ?? "0").toFixed(2)}`);
      rowWorkorderMargin.push(`${parseFloat(jobsAndRevenueData.data.wo_service_margin?.[wk] ?? "0").toFixed(2)}%`);
      rowNet.push(`${currencySymbol}${parseFloat(jobsAndRevenueData.data.net?.[wk] ?? "0").toFixed(2)}`);
      rowTotalMargin.push(`${parseFloat(jobsAndRevenueData.data.total_margin?.[wk] ?? "0").toFixed(2)}%`);
    });
  
    const tableData = [
      rowWeek,
      rowDates,
      rowNumberOfServices,
      rowNumberOfReportedIssues,
      rowNewServicesScheduled,
      rowNumberOfWorkorders,
      rowTotalServicesPerformed,
      rowServiceRevenue,
      rowServiceHousekeeperExpense,
      rowServiceAttendantExpense,
      rowServiceInspectorExpense,
      rowTotalServiceExpense,
      rowServiceNet,
      rowServiceMargin,
      rowWorkordersRevenue,
      rowWorkorderContractorExpense,
      rowWorkorderNet,
      rowWorkorderMargin,
      rowNet,
      rowTotalMargin,
    ];
  
    return { tableData };
  };

  const prepareTableData2 = (compareData1, compareData2, localised) => {
    const headerDataCurrent = compareData1.data.Header.data;
    const headerDataPrevious = compareData2.data.Header.data;

    const data1 = compareData1.data;
    const data2 = compareData2.data;

    const rowWeek = [localised.week];
    const rowDates = [localised.dates];
    const rowNoOfServices = [localised.numberOfServices];
    const rowReportedIssues = [localised.reportedIssues];
    const rowNewServicesScheduled = [localised.newServicesScheduled];
    const rowNoOfWorkorders = [localised.numberOfWorkorders];
    const rowTotalServicesPerformed = [localised.totalServicesPerformed];
    const rowServiceRevenue = [localised.serviceRevenue];
    const rowServiceHousekeeperExpense = [localised.serviceHousekeeperExpense];
    const rowServiceInspectorExpense = [localised.serviceInspectorExpense];
    const rowServiceAttendantExpense = [localised.serviceHouseAttendantExpense];
    const rowTotalServiceExpense = [localised.totalServiceExpense];
    const rowServiceNet = [localised.serviceNet];
    const rowServiceMargin = [localised.serviceMargin];
    const rowWorkordersRevenue = [localised.workordersRevenue];
    const rowWorkorderContractorExpense = [localised.workorderContractorExpense];
    const rowWorkorderNet = [localised.workorderNet];
    const rowWorkorderMargin = [localised.workorderMargin];
    const rowNet = [localised.net];
    const rowTotalMargin = [localised.totalMargin];

    const currentKeys = Object.keys(headerDataCurrent);
    const previousKeys = Object.keys(headerDataPrevious);

    const maxRows = Math.max(currentKeys.length, previousKeys.length);

    for (let i = 0; i < maxRows; i++) {
        const currentKey = currentKeys[i];
        const previousKey = previousKeys[i];

        const currentWeek = currentKey ? headerDataCurrent[currentKey]?.Sequence || "" : "";
        const previousWeek = previousKey ? headerDataPrevious[previousKey]?.Sequence || "" : "";
        rowWeek.push(`${currentWeek} / ${previousWeek}`);

        const currentDates = currentKey ? headerDataCurrent[currentKey]?.Label || "" : "";
        const previousDates = previousKey ? headerDataPrevious[previousKey]?.Label || "" : "";
        rowDates.push(`${currentDates} / ${previousDates}`);

        const currentServices = currentKey ? data1.no_of_service?.[currentKey] || 0 : 0;
        const previousServices = previousKey ? data2.no_of_service?.[previousKey] || 0 : 0;
        rowNoOfServices.push(`${currentServices} / ${previousServices}`);

        const currentIssues = currentKey ? data1.no_of_reported_issues?.[currentKey] || 0 : 0;
        const previousIssues = previousKey ? data2.no_of_reported_issues?.[previousKey] || 0 : 0;
        rowReportedIssues.push(`${currentIssues} / ${previousIssues}`);

        const currentScheduled = currentKey ? data1.new_service_scheduled?.[currentKey] || 0 : 0;
        const previousScheduled = previousKey ? data2.new_service_scheduled?.[previousKey] || 0 : 0;
        rowNewServicesScheduled.push(`${currentScheduled} / ${previousScheduled}`);

        const currentWorkorders = currentKey ? data1.no_of_workorders?.[currentKey] || 0 : 0;
        const previousWorkorders = previousKey ? data2.no_of_workorders?.[previousKey] || 0 : 0;
        rowNoOfWorkorders.push(`${currentWorkorders} / ${previousWorkorders}`);

        const currentPerformed = currentKey ? data1.total_service_performed?.[currentKey] || 0 : 0;
        const previousPerformed = previousKey ? data2.total_service_performed?.[previousKey] || 0 : 0;
        rowTotalServicesPerformed.push(`${currentPerformed} / ${previousPerformed}`);

        const currentRevenue = currentKey ? data1.service_revenue?.[currentKey] || 0 : 0;
        const previousRevenue = previousKey ? data2.service_revenue?.[previousKey] || 0 : 0;
        rowServiceRevenue.push(`${currencySymbol}${parseFloat(currentRevenue).toFixed(2)} / ${currencySymbol}${parseFloat(previousRevenue).toFixed(2)}`);

        const currentHKExpense = currentKey ? data1.service_hk_expenses?.[currentKey] || 0 : 0;
        const previousHKExpense = previousKey ? data2.service_hk_expenses?.[previousKey] || 0 : 0;
        rowServiceHousekeeperExpense.push(`${currencySymbol}${parseFloat(currentHKExpense).toFixed(2)} / ${currencySymbol}${parseFloat(previousHKExpense).toFixed(2)}`);

        const currentInspExpense = currentKey ? data1.service_insp_expenses?.[currentKey] || 0 : 0;
        const previousInspExpense = previousKey ? data2.service_insp_expenses?.[previousKey] || 0 : 0;
        rowServiceInspectorExpense.push(`${currencySymbol}${parseFloat(currentInspExpense).toFixed(2)} / ${currencySymbol}${parseFloat(previousInspExpense).toFixed(2)}`);

        const currentAttendantExpense = currentKey ? data1.service_ha_expenses?.[currentKey] || 0 : 0;
        const previousAttendantExpense = previousKey ? data2.service_ha_expenses?.[previousKey] || 0 : 0;
        rowServiceAttendantExpense.push(`${currencySymbol}${parseFloat(currentAttendantExpense).toFixed(2)} / ${currencySymbol}${parseFloat(previousAttendantExpense).toFixed(2)}`);

        const currentTotalExpense = currentKey ? data1.total_service_expenses?.[currentKey] || 0 : 0;
        const previousTotalExpense = previousKey ? data2.total_service_expenses?.[previousKey] || 0 : 0;
        rowTotalServiceExpense.push(`${currencySymbol}${parseFloat(currentTotalExpense).toFixed(2)} / ${currencySymbol}${parseFloat(previousTotalExpense).toFixed(2)}`);

        const currentNet = currentKey ? data1.service_net?.[currentKey] || 0 : 0;
        const previousNet = previousKey ? data2.service_net?.[previousKey] || 0 : 0;
        rowServiceNet.push(`${currencySymbol}${parseFloat(currentNet).toFixed(2)} / ${currencySymbol}${parseFloat(previousNet).toFixed(2)}`);

        const currentMargin = currentKey ? data1.service_margin?.[currentKey] || 0 : 0;
        const previousMargin = previousKey ? data2.service_margin?.[previousKey] || 0 : 0;
        rowServiceMargin.push(`${parseFloat(currentMargin).toFixed(2)}% / ${parseFloat(previousMargin).toFixed(2)}%`);

        const currentWORevenue = currentKey ? data1.wo_revenue?.[currentKey] || 0 : 0;
        const previousWORevenue = previousKey ? data2.wo_revenue?.[previousKey] || 0 : 0;
        rowWorkordersRevenue.push(`${currencySymbol}${parseFloat(currentWORevenue).toFixed(2)} / ${currencySymbol}${parseFloat(previousWORevenue).toFixed(2)}`);

        const currentWOContractorExpense = currentKey ? data1.wo_contract_expenses?.[currentKey] || 0 : 0;
        const previousWOContractorExpense = previousKey ? data2.wo_contract_expenses?.[previousKey] || 0 : 0;
        rowWorkorderContractorExpense.push(`${currencySymbol}${parseFloat(currentWOContractorExpense).toFixed(2)} / ${currencySymbol}${parseFloat(previousWOContractorExpense).toFixed(2)}`);

        const currentWONet = currentKey ? data1.workorder_net?.[currentKey] || 0 : 0;
        const previousWONet = previousKey ? data2.workorder_net?.[previousKey] || 0 : 0;
        rowWorkorderNet.push(`${currencySymbol}${parseFloat(currentWONet).toFixed(2)} / ${currencySymbol}${parseFloat(previousWONet).toFixed(2)}`);

        const currentWOMargin = currentKey ? data1.wo_service_margin?.[currentKey] || 0 : 0;
        const previousWOMargin = previousKey ? data2.wo_service_margin?.[previousKey] || 0 : 0;
        rowWorkorderMargin.push(`${parseFloat(currentWOMargin).toFixed(2)}% / ${parseFloat(previousWOMargin).toFixed(2)}%`);

        const currentNetTotal = currentKey ? data1.net?.[currentKey] || 0 : 0;
        const previousNetTotal = previousKey ? data2.net?.[previousKey] || 0 : 0;
        rowNet.push(`${currencySymbol}${parseFloat(currentNetTotal).toFixed(2)} / ${currencySymbol}${parseFloat(previousNetTotal).toFixed(2)}`);

        const currentTotalMargin = currentKey ? data1.total_margin?.[currentKey] || 0 : 0;
        const previousTotalMargin = previousKey ? data2.total_margin?.[previousKey] || 0 : 0;
        rowTotalMargin.push(`${parseFloat(currentTotalMargin).toFixed(2)}% / ${parseFloat(previousTotalMargin).toFixed(2)}%`);
    }

    const tableData = [
        rowWeek,
        rowDates,
        rowNoOfServices,
        rowReportedIssues,
        rowNewServicesScheduled,
        rowNoOfWorkorders,
        rowTotalServicesPerformed,
        rowServiceRevenue,
        rowServiceHousekeeperExpense,
        rowServiceInspectorExpense,
        rowServiceAttendantExpense,
        rowTotalServiceExpense,
        rowServiceNet,
        rowServiceMargin,
        rowWorkordersRevenue,
        rowWorkorderContractorExpense,
        rowWorkorderNet,
        rowWorkorderMargin,
        rowNet,
        rowTotalMargin,
    ];

    return { tableData };
};

  const prepareNetChartData = (jobsAndRevenueData) => {
    const headerData = jobsAndRevenueData.data.Header.data || {};
    const netObj = jobsAndRevenueData.data.net || {};
    
    const labels = [];
    const netValues = [];
    
    const weekKeys = Object.keys(headerData);

    weekKeys.forEach((wk) => {
      const sequenceLabel = headerData[wk]?.Label || wk; 
      labels.push(sequenceLabel);
      const numericNet = parseFloat(netObj[wk] ?? 0);
      netValues.push(numericNet);
    });

    return {
      labels,
      datasets: [
        {
          label: `${localised.net} (${currencySymbol})`,
          data: netValues,
          fill: false,
          borderColor: "#2a72b5",
          tension: 0.1,
        },
      ],
    };
  };

  const prepareCompareNetChartData = (
    compareData1,
    compareData2,
    localised,
    currencySymbol,
    filters
  ) => {
    const headerData1 = compareData1?.data?.Header?.data || {};
    const headerData2 = compareData2?.data?.Header?.data || {};

    const netData1 = compareData1?.data?.net || {};
    const netData2 = compareData2?.data?.net || {};

    const keys1 = Object.keys(headerData1);
    const keys2 = Object.keys(headerData2);

    const maxLength = Math.max(keys1.length, keys2.length);

    const labels = [];
    const netValues1 = [];
    const netValues2 = [];

    for (let i = 0; i < maxLength; i++) {
      const key1 = keys1[i];
      const key2 = keys2[i];

      let label1 = key1 ? headerData1[key1]?.Label : "";
      let label2 = key2 ? headerData2[key2]?.Label : "";

      if (!label1 && label2) {
        label1 = label2;
      }
      // If both label1 and label2 are available, you can merge them or just pick one
      // For demonstration, let's just pick label1 as the primary label
      const finalLabel = label1 || label2 || `Week ${i + 1}`;

      labels.push(finalLabel);

      const val1 = key1 ? parseFloat(netData1[key1] || 0) : 0;
      const val2 = key2 ? parseFloat(netData2[key2] || 0) : 0;

      netValues1.push(val1);
      netValues2.push(val2);
    }

    const label1 = `${filters.year1} ${localised.net} (${currencySymbol})`;
    const label2 = `${filters.year2} ${localised.net} (${currencySymbol})`;

    return {
      labels,
      datasets: [
        {
          label: label1,
          data: netValues1,
          fill: false,
          borderColor: "#2a72b5",
          tension: 0.1,
        },
        {
          label: label2,
          data: netValues2,
          fill: false,
          borderColor: "rgba(75,192,192,1)",
          tension: 0.1,
        },
      ],
    };
  };

  
  const searchJobsAndRevenue = (filters) => {
    getJobAndRevenueData(filters);  
  }

  const resetSearchFilter = () => {
    getJobAndRevenueData(filters);  
  }

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex align-items-center flex-wrap">
          <div className="list-header-left">
            <h1 className="page-title">{localised.jobsandRevenueReport}</h1>
            <Breadcrumbs
              mainpage={localised.home}
              currentpage={localised.jobsandRevenueReport}
            />
          </div>
        </div>
        <div className="search-container mb-3">
          <Search tableColumn={isCompare} setTableColumn={setISCompare}
            searchJobsAndRevenue={searchJobsAndRevenue}
            resetSearchFilter={resetSearchFilter} showWarningModal={showWarningModal}
            setShowWarningModal={setShowWarningModal}
          />
        </div>
        <div className="row justify-content-center bg-white m-0 mb-3">
          <div className="col-md-6">
            <div className="graph">
            <Line 
              data={netChartData} 
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: function (value) {
                        return `${currencySymbol}${value}`;
                      },
                    },
                  },
                },
              }} 
            />
            </div>
          </div>
        </div>
        <div className="jobsrevenuetable">
        <Grid
          data={tableData}
          search={false}
          pagination={false}
          fixedHeader={true}
          Style={{}}
        />
        </div>
      </div>

    </>
  );
};

export default JobsAndRevenue;
